/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Alert } from "@mui/material";
import { memo } from "react";
import { useRecoilValue } from "recoil";
import { currentAlertState } from "../state";
import ExplorerLink from "./ExplorerLink";

export function getStyles() {
  return {
    status: css`
      margin-top: 20px;
      min-height: 30px;
      a {
        color: #208b72;
        text-decoration: none;
        cursor: pointer;
        font-size: 14px;
      }
    `,
  }
}

function StatusAlert() {
  const currentStatus = useRecoilValue(currentAlertState)!;
  const { severity, message, chainId, txHash, celer, celerTxHash } = currentStatus;
  const styles= getStyles();

  return (
    <Alert 
      css={styles.status}
      severity={severity}
    >
      {message}
      {txHash && <span>&nbsp;</span>}
      {txHash && chainId && <ExplorerLink chainId={chainId} txHash={txHash} celer={celer} celerTxHash={celerTxHash}/>}
    </Alert>
  );
}

export default memo(StatusAlert);
