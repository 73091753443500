/** @jsxImportSource @emotion/react */
import { Box, FormControl, Tab, Tabs } from "@mui/material";
import { Chains } from "../config/chains";
import { ChainIds, ENV, Environments } from "../config";
import { useRecoilState } from "recoil";
import { currentChainIdState, selectedTokenState } from "../state";
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from "@web3-react/core";
import { css } from "@emotion/react";
import { memo } from "react";
import { getConnectorType } from "../connectors/utils";
import { ConnectorTypes } from "../connectors";

export function getStyles() {
  return {
    container: css`
      padding:15px 0 15px 0;
    `,
    tabs: css `
      max-height: 35px;
      min-height: auto;
    `,
    tab: css `
      max-height: 35px;
      min-height: auto;
    `
  }
}

function ChainSelector(params: any) {
  const { active } = params;
  const [currentChainId, setCurrentChainId] = useRecoilState(currentChainIdState);
  const [,setSelectedToken] = useRecoilState(selectedTokenState);
  const styles = getStyles();
  const { connector } = useWeb3React<Web3Provider>();
  const connectorType = getConnectorType(connector);  

  const availableChains = Chains.filter(chain => {
    return (
      ((connectorType === ConnectorTypes.Magic)
      ||
      connectorType === ConnectorTypes.Injected)
      //&&
      //(ENV === Environments.TESTNET && chain.chainId !== ChainIds.POLYGON)
      ) && chain;
  });

  const handleChange = (e: any, value: any) => {
    const newChainId = Number(value);
    setCurrentChainId(newChainId);
    setSelectedToken(undefined); // reset selected token when flipping chains
  }

  return <Box css={styles.container} display="flex" justifyContent="center">
    {active && <FormControl component="fieldset">
      <Tabs
        value={currentChainId} 
        onChange={handleChange}
        css={styles.tabs}
      >
        {
          availableChains.map(chain => (
            <Tab 
              key={chain.chainId}
              value={chain.chainId} 
              label={chain.label} 
              css={styles.tab}
            />
          ))
        }
      </Tabs>
    </FormControl>}
  </Box>
}

export default memo(ChainSelector);