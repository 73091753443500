import { useEffect, memo } from "react";
import { useRecoilCallback } from "recoil";
import { Tokens } from "../config/tokens";
import { getBalance } from "../services/balances";
import { balanceState } from "../state/balances";

function BalanceFetcher(params: { account: string }) {
  const { account } = params;
  // console.log("---------- RefreshBalances", account);
  let prevBalances: { [key: string]: string | undefined; } = {};
  const refreshBalances = useRecoilCallback(({ set }) => async () => {
    // console.log("---------- RefreshBalances refreshBalances", account);
    if (account) {
      const balances = await Promise.all(
        Tokens.map(async ({ symbol, chainId }) => (
          await getBalance(chainId, symbol, account)
        ))  
      );
      // console.log("-------- refreshBalances", balances);
      for (let entry of balances) {
        if (entry) {
          const key = `${entry.chainId}:${entry.symbol}:${account}`;
          if (prevBalances[key] !== entry.raw) {
            set(balanceState({ chainId: entry.chainId, symbol: entry.symbol, account }), entry); 
            prevBalances[key] = entry.raw;
          }  
        }
      }         
    }
  }, [account]);

  useEffect(() => {
    // console.log("---------- RefreshBalances useEffect", chainId);
    let intervalId: any;
    if (account) {
      intervalId = setInterval(refreshBalances, 5000);
    }
    return () => {
      // console.log("---------- RefreshBalances useEffect return", chainId);
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
  }, [account, refreshBalances]);

  return null;
}

export default memo(BalanceFetcher);