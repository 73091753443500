import { UnsupportedChainIdError } from "@web3-react/core";
import { 
  NoEthereumProviderError, 
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";
import { ConnectorTypes } from ".";

export function getProviderErrorMessage(error: Error) {
  if (error instanceof NoEthereumProviderError) {
    return 'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.'
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network."
  } else if (
    error instanceof UserRejectedRequestErrorInjected
    //error instanceof UserRejectedRequestErrorWalletConnect ||
    //error instanceof UserRejectedRequestErrorFrame
  ) {
    return "The user rejected the request.";
  } else {
    console.error(error)
    return 'An unknown error occurred. Check the console for more details.'
  }
}

export function isMetaMaskConnector(connector: any) {
  return (
    (connector && !((connector as any).magic)) && (window.ethereum as any).isMetaMask
  );
}

export function isMagicConnector(connector: any) {
  return (
    connector && ((connector as any).magic)
  );
}

export function getConnectorType(connector: any) {
  return (
    connector 
    ? (
      connector.magic
      ? ConnectorTypes.Magic
      : ConnectorTypes.Injected
      ) 
    : undefined
  );
}
