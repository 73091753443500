import { utils } from "ethers";
import { ChainIds, Symbols } from "../config";
import { Tokens } from "../config/tokens";
import { IBalance } from "../state/balances";
import { getContract } from "./contracts";
import { getRpcProvider } from "./providers";
import { sendSentryError } from "../services/sentry";

export async function getBalance(
  chainId: ChainIds, 
  symbol: Symbols,
  account: string
): Promise<IBalance | undefined> {
  try {
    //console.log("------ getBalance params", chainId, symbol, account); 
    const provider = getRpcProvider(chainId);
    const { address, abi, decimals } = Tokens.find(e => e.chainId === chainId && e.symbol === symbol)!;
    let raw;
    if (address) {
      const contract = getContract(address, abi, provider);
      raw = await contract.balanceOf(account);
    } else {
      raw = await provider.getBalance(account);
    }
    return {
      chainId,
      symbol,
      raw,
      balance: utils.formatUnits(raw, decimals)
    };
  } catch (err) {
    // console.log("-------- getBalance error", err);
    // sendSentryError(err as Error);
    // throw err;
  }
}

export async function getBalances(
  chainId: ChainIds, 
  account: string
): Promise<IBalance[] | undefined> {
  try {
    console.log("------ getBalances params", chainId, account); 

    const provider = getRpcProvider(chainId);
    const tokens = Tokens.filter(e => e.chainId === chainId);

    const balances = await Promise.all(
      tokens.map(async ({ symbol, address, abi, decimals }) => {
        let raw;
        if (address) {
          const contract = getContract(address, abi, provider);
          raw = await contract.balanceOf(account);
        } else {
          raw = await provider.getBalance(account);
        }
        return {
          chainId,
          symbol,
          raw,
          balance: Number(utils.formatUnits(raw, decimals))
        };
      })
    );
    return balances;

  } catch (err) {
    console.log("-------- getBalances error", err);
    sendSentryError(err as Error);
    throw err;
  }
}
