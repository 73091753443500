/** @jsxImportSource @emotion/react */
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from '@ethersproject/providers'
import WalletView from "./components/WalletView";
import ErrorBoundary from "./ErrorBoundary";
import { IntercomProvider } from 'react-use-intercom';
import { INTERCOM_APP_ID } from "./config/index";
import { SnackbarProvider } from 'notistack';

function getLibrary(provider: any, connector: any) {
  return new Web3Provider(provider);
}

function App() {

  return (
    <ErrorBoundary>
      <IntercomProvider appId={INTERCOM_APP_ID!}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
            <RecoilRoot>
              <BrowserRouter>
                <WalletView/>
              </BrowserRouter>
            </RecoilRoot> 
          </SnackbarProvider>
        </Web3ReactProvider>
      </IntercomProvider>
    </ErrorBoundary>
  );
}

export default App;
