/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { Box, Chip, Typography, useTheme, useMediaQuery, Button, IconButton, Menu, MenuItem } from "@mui/material";
import ArrowIcon from '@mui/icons-material/ArrowRightAlt';
import LinkIcon from '@mui/icons-material/Link';
import moment from "moment";
import { ITransferHistory } from "../state/transfers";
import { base64, getAddress, hexlify } from "ethers/lib/utils";
import { Chains } from "../config/chains";
import { Symbols, MAINCHAIN_ID, SIDECHAIN_ID, cBridgeBridgeStatus, cBridgeBridgeStatusMap } from "../config";
import getUserMenuStyles from "../styles/UserMenuStyles";
import { cBridgeEstimateWithdrawRequest, cBridgeWithdrawLiquidityRequest, cBridgeGetTransferStatus, cRefundToken } from "../services/tokens";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from '@ethersproject/providers';
import { BigNumber } from "@ethersproject/bignumber";
import { getConnectorType } from "../connectors/utils";

function HistoryItem(params: { transfer: ITransferHistory }) {

  // state
  const [isRequestingRefund, setRequestRefund] = useState(false);
  const [isConfirmingRefund, setConfirmRefund] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  // params
  let { transfer } = params;
  const { id, timestamp, srcChainId, dstChainId, symbol, formattedAmount, icon, transactionHash, status, celer, dstBlockTxLink, srcBlockTxLink, fastBridge } = transfer;
  const dstChain = Chains.find(e => e.chainId === dstChainId)!;
  const srcChain = Chains.find(e => e.chainId === srcChainId)!;
  const formattedDate = moment(timestamp).format("MMM DD, YYYY hh:mm:ss").toUpperCase();

  // hooks
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  const { connector, library, account } = useWeb3React<Web3Provider>();
  const connectorType = getConnectorType(connector); 
  
  // styles
  const styles = getUserMenuStyles({ theme });

  // logic
  let blockExplorer;
  let label = "...";
  let direction;
  let blockExplorerLink;
  if(status === "pending") {
    blockExplorer = (dstChainId === SIDECHAIN_ID || dstChainId === MAINCHAIN_ID) && srcChain.blockExplorer;
  } else if(status === "success") {
    blockExplorer = (dstChainId === SIDECHAIN_ID || dstChainId === MAINCHAIN_ID) && dstChain.blockExplorer;
  }
  blockExplorerLink = `${blockExplorer}/tx/${transactionHash}`;
  if(celer) {
    blockExplorerLink = dstBlockTxLink ? dstBlockTxLink : srcBlockTxLink;
  }

  // Human-friendly labels
  const fromMainToSideChain = dstChainId === SIDECHAIN_ID && srcChainId === MAINCHAIN_ID;
  const fromSideToMainChain = dstChainId === MAINCHAIN_ID && srcChainId === SIDECHAIN_ID;
  if( status === "pending" ) {
    if(fromMainToSideChain) {
      label = "Bridging to Polygon...";
      direction = "polygon";
    } else if(fromSideToMainChain) {
      label = "Bridging to SX Network...";
      direction = "sx";
    }
  } else {
    if(fromMainToSideChain) {
      label = "Recieved on Polygon";
      direction = "polygon";
    } else if(fromSideToMainChain) {
      label = "Recieved on SX Network";
      direction = "sx";
    }
  }

  // clause for cBridge refund
  const refund = [
    cBridgeBridgeStatusMap.TRANSFER_TO_BE_REFUNDED,
    cBridgeBridgeStatusMap.TRANSFER_CONFIRMING_YOUR_REFUND,
    cBridgeBridgeStatusMap.TRANSFER_REFUNDED,
    cBridgeBridgeStatusMap.TRANSFER_REQUESTING_REFUND,
    cBridgeBridgeStatusMap.TRANSFER_REFUND_TO_BE_CONFIRMED,
  ];

  const requestRefund = async () => {
    const estimate = await cBridgeEstimateWithdrawRequest(
      transfer,
      account!
    );
    if (estimate) {
      setRequestRefund(true);
      try {
        await cBridgeWithdrawLiquidityRequest(library!,id,estimate);
      } catch(err) {
        setRequestRefund(false);
      }
    }
  }

  const confirmRefund = async () => {
    const status = await cBridgeGetTransferStatus({transfer_id: id});
    const { wd_onchain, sorted_sigs, signers, powers } = status;
    const wdmsg = base64.decode(wd_onchain);
    const wdsigners = signers.map(item => {
      const decodeSigners = base64.decode(item);
      const hexlifyObj = hexlify(decodeSigners);
      return getAddress(hexlifyObj);
    });
    const wdsigs = sorted_sigs.map(item => {
      return base64.decode(item);
    });
    const wdpowers = powers.map(item => {
      const decodeNum = base64.decode(item);
      return BigNumber.from(decodeNum);
    });
    setConfirmRefund(true);
    try {
      await cRefundToken(
        library!,
        connectorType!,
        wdmsg,
        wdsigs,
        wdsigners,
        wdpowers,
        srcChainId
      );
    } catch(err) {
      setConfirmRefund(false);
    }
  }

  return (
    <Box css={styles.transfer} display="flex">
      <Box>
        <img css={styles.tokenIcon} src={icon} alt={symbol} />
      </Box>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography css={styles.amount}>
            {symbol === Symbols.WETH ? `Ξ${formattedAmount}` : `${formattedAmount} ${symbol}`}
          </Typography>
          {
            !phone && (
              <Box display="flex" alignItems="center" css={styles.direction}>
                <Typography>{srcChain.label}</Typography>
                <ArrowIcon />
                <Typography>{dstChain.label}</Typography>
              </Box>
            )
          }
        </Box>
        <Box display="flex" css={styles.status} justifyContent="space-between">
          <Chip 
            label={ celer ? cBridgeBridgeStatus[status] : label} 
            size="small" 
            css={[styles.chip,direction === "polygon" ? styles.out : styles.in, refund.includes(parseInt(status)) && styles.refundChip]}
          />
          {
            !phone && (
              <Typography>{formattedDate}</Typography>
            )
          }  
        </Box>
      </Box>
      <Box display="flex" css={styles.linkBox} alignItems="center">
        {
          refund.includes(parseInt(status)) && (
            <>
              {
                parseInt(status) === cBridgeBridgeStatusMap.TRANSFER_TO_BE_REFUNDED && (
                  <Button
                    variant="contained"
                    css={styles.refund}
                    size="small"
                    onClick={() => requestRefund()}
                    disabled={isRequestingRefund}
                  >
                    Request
                  </Button>
                )
              }
              {
                parseInt(status) === cBridgeBridgeStatusMap.TRANSFER_REFUND_TO_BE_CONFIRMED && (
                  <Button
                    variant="contained"
                    css={styles.refund}
                    size="small"
                    onClick={() => confirmRefund()}
                    disabled={isConfirmingRefund}
                  >
                    Refund
                  </Button>
                )
              }
            </>
          )
        }
        {
          (celer || fastBridge) ? (
            <>
              {
                srcBlockTxLink && dstBlockTxLink && (
                  <>
                    <IconButton onClick={(e) => { setAnchorEl(e.currentTarget) }}><LinkIcon css={styles.explorerIcon} fontSize="medium" /></IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}
                    >
                      <MenuItem component="a" href={srcBlockTxLink} target="_blank" rel="noreferrer" onClick={() => setAnchorEl(null)}>{srcChain.label}</MenuItem>
                      <MenuItem component="a" href={dstBlockTxLink} target="_blank" onClick={() => setAnchorEl(null)}>{dstChain.label}</MenuItem>
                    </Menu>
                  </>
                )
              }
              {
                (!srcBlockTxLink || !dstBlockTxLink) && (
                  <a css={styles.link} href={srcBlockTxLink || dstBlockTxLink} target="_blank" rel="noreferrer">
                    <IconButton><LinkIcon css={styles.explorerIcon} fontSize="medium" /></IconButton>  
                  </a>
                )
              }
            </>
          ) : (
            <a css={styles.link} href={blockExplorerLink} target="_blank" rel="noreferrer">
              <IconButton><LinkIcon css={styles.explorerIcon} fontSize="medium" /></IconButton>  
            </a>
          )
        }
        
      </Box>
    </Box>
  )
}

export default HistoryItem;
