import { atomFamily } from "recoil";
import { ChainIds, Symbols } from "../config";
import { getBalance } from "../services/balances";

export interface IBalance {
  symbol: Symbols;
  chainId: ChainIds;
  raw: string | undefined;
  balance: number | any;
}

export const balanceState = atomFamily<IBalance | undefined, { chainId?: number, symbol?: Symbols, account?: string }>({
  key: 'BalanceState',
  default: async (params: any) => {
    const { chainId, symbol, account } = params;
    let balance: IBalance | undefined = undefined;
    if (account && chainId && symbol) {
      balance = await getBalance(chainId, symbol, account);
      return balance || {
        symbol,
        chainId,
        raw: undefined,
        balance: undefined
      };
    }
  }
});

