import { Web3Provider, TransactionReceipt, TransactionResponse } from '@ethersproject/providers';
import { BigNumber, utils, ethers, constants } from "ethers";
import { ChainIds, ContractNames, MAINCHAIN_ID, Symbols, CBRIDGE_URL, GetTransferStatusResponseType, CelerBridgeMode, RELAYER_URL, FAST_EXIT_SPENDER, ENV, Environments } from '../config';
import { ContractsMeta } from '../config/contracts';
import { Tokens } from '../config/tokens';
import { ConnectorTypes } from '../connectors';
import { getContract } from './contracts';
import { sendSentryError } from "../services/sentry";
import {
  WithdrawReq, WithdrawType
} 
from "./../ts-proto/sgn/cbridge/v1/tx_pb";
import {
  EstimateAmtRequest,
  EstimateWithdrawAmtRequest,
  WithdrawInfo,
  WithdrawLiquidityRequest,
  WithdrawLiquidityResponse,
  WithdrawMethodType,
  GetTransferStatusRequest
} 
from "./../ts-proto/gateway/gateway_pb";
import { WebClient } from "./../ts-proto/gateway/GatewayServiceClientPb";
import axios from 'axios';
import { getMaticEip712Payload, createERCDepositData, getProvider } from '../utils';
import { JsonRpcSigner } from "@ethersproject/providers";
import { getGasPrice } from './rates';

const SX_GAS =  {
  gasLimit: 200_000,
  gasPrice: 1000000000,
}

export async function transferToken(
  library: Web3Provider,
  symbol: Symbols,
  amount: string,
  sourceChainId: ChainIds,
  connectorType: ConnectorTypes,
  account: string,
): Promise<TransactionReceipt | undefined> {
  try {
    const provider = getProvider(
      connectorType,
      library,
      sourceChainId
    );
    const signer = provider.getSigner();
    const tokenMeta = Tokens.find(e => 
      e.chainId === sourceChainId && e.symbol === symbol
    )!;
    const bridgeMeta = ContractsMeta.find(e => 
      e.chainId === sourceChainId && e.name === ContractNames.BRIDGE
    )!;
    const bridge = getContract(bridgeMeta.address, bridgeMeta.abi, provider);
    const bridgeWithSigner = bridge.connect(signer);
    const destinationChainId = sourceChainId === MAINCHAIN_ID ? 0 : 1;
    const resourceId = tokenMeta.resourceId!;
    const lenRecipientAddress = 40;
    const data = createERCDepositData(
      amount,
      lenRecipientAddress,
      account
    );
    const tx = await bridgeWithSigner.deposit(
      destinationChainId,
      resourceId,
      data,
    );
    const receipt = await provider.waitForTransaction(tx.hash);
    return receipt;
  } catch (err) {
    console.log("-------- transferToken error", err);
    sendSentryError(err as Error);
    throw err;
  }
}

export async function wrapToken(
  library: Web3Provider,
  amount: string,
  dstSymbol: Symbols,
  sourceChainId: ChainIds,
  connectorType: ConnectorTypes,
): Promise<TransactionReceipt | undefined> {
  try {
    const provider = getProvider(
      connectorType,
      library,
      sourceChainId
    );
    const signer = provider.getSigner();
    const tokenMeta = Tokens.find(e => 
      e.chainId === sourceChainId && e.symbol === dstSymbol
    )!;
    const tokenAddress = tokenMeta.address!;
    const token = getContract(tokenAddress, tokenMeta.abi, provider); 
    const tokenWithSigner = token.connect(signer);
    const tx = await tokenWithSigner.deposit({
      value: amount,
    });  
    const receipt = await provider.waitForTransaction(tx.hash);
    return receipt;
  } catch (err) {
    console.log("-------- wrapToken error", err);
    sendSentryError(err as Error);
    throw err;
  }
}

export async function unwrapToken(
  library: Web3Provider,
  amount: string,
  srcSymbol: Symbols,
  sourceChainId: ChainIds,
  connectorType: ConnectorTypes,
): Promise<TransactionReceipt | undefined> {
  try {
    const provider = getProvider(
      connectorType,
      library,
      sourceChainId
    );
    const signer = provider.getSigner();
    const tokenMeta = Tokens.find(e => 
      e.chainId === sourceChainId && e.symbol === srcSymbol
    )!;
    const tokenAddress = tokenMeta.address!;
    const token = getContract(tokenAddress, tokenMeta.abi, provider);
    const tokenWithSigner = token.connect(signer);
    const tx = await tokenWithSigner.withdraw(amount);
    const receipt = await provider.waitForTransaction(tx.hash);
    return receipt;
  } catch (err) {
    console.log("-------- unwrapToken error", err);
    sendSentryError(err as Error);
    throw err;
  }
}

export async function cBridgeToken(
  library: Web3Provider,
  connectorType: ConnectorTypes,
  amount: BigNumber,
  dstChainId: number,
  sourceChainId: number,
  maxSlippage: number,
  nonce: number,
  account: string,
  srcSymbol: Symbols,
):Promise<TransactionResponse | undefined> {
  const provider = getProvider(
    connectorType,
    library,
    sourceChainId
  );
  const signer = provider.getSigner();
  const tokenMeta = Tokens.find(e => 
    e.chainId === sourceChainId && e.symbol === srcSymbol
  )!;
  const findContract = ContractsMeta.find(e => e.name === tokenMeta?.contract && e.chainId === sourceChainId)!;
  const cBridgeContract = new ethers.Contract(findContract.address, findContract.abi, signer);

  console.log("cBridgeContract",cBridgeContract)
  try {
    if(tokenMeta.celerMode === CelerBridgeMode.NATIVE_TOKEN) {
      const response = await cBridgeContract.depositNative(
        amount, 
        dstChainId, 
        account, 
        nonce, 
        { value: amount }
      )
      await response.wait();
      return response;
    } else if(tokenMeta.celerMode === CelerBridgeMode.OTHER_TOKEN) {
      const response = await cBridgeContract!.deposit(
        tokenMeta.address,
        amount,
        dstChainId,
        account,
        nonce,
      );
      await response.wait();
      return response;
    } else if(tokenMeta.celerMode === CelerBridgeMode.SX_NETWORK_TOKEN) {
      
      const SX_GAS = {
        gasLimit: 200_000,
        gasPrice: await getGasPrice(sourceChainId),
      };

      const response = await cBridgeContract!.burn(
        tokenMeta.address,
        amount,
        dstChainId,
        account,
        nonce,
        SX_GAS
      );
      await response.wait();
      return response;
    }
    /*
    const response = await cBridgeContract.send(
      account,
      tokenMeta.address,
      amount,
      BigNumber.from(dstChainId),
      BigNumber.from(nonce),
      BigNumber.from(maxSlippage || 0), // BigNumber.from(100),
    )
    await response.wait();
    return response;
    */
   return undefined;
  } catch(err) {
    console.log("-------- cBridgeToken error", err);
    throw err;
  }
}

export async function cBridgeTransferId(
  amount: BigNumber,
  dstChainId: number,
  sourceChainId: number,
  nonce: number,
  account: string,
  srcSymbol: Symbols,
) {
  const tokenMeta = Tokens.find(e => 
    e.chainId === sourceChainId && e.symbol === srcSymbol
  )!;
  const transferId = (() => {
    return ethers.utils.solidityKeccak256(
      ["address", "address", "address", "uint256", "uint64", "uint64", "uint64"],
      [
        account,
        account,
        tokenMeta.address,
        amount.toString(),
        dstChainId.toString(),
        nonce.toString(),
        sourceChainId.toString(),
      ],
    )
  })();
  return transferId;
}

export async function cBridgeEstimateRequest(
  amount: BigNumber,
  dstChainId: number,
  sourceChainId: number,
  account: string,
  srcSymbol: Symbols,
  isPegged: boolean,
) {
  const estimateRequest = new EstimateAmtRequest();
  estimateRequest.setSrcChainId(sourceChainId);
  estimateRequest.setDstChainId(dstChainId);
  estimateRequest.setTokenSymbol(srcSymbol);
  estimateRequest.setUsrAddr(account!);
  estimateRequest.setSlippageTolerance(Number("1") * 10000);
  estimateRequest.setAmt(amount.toString());
  estimateRequest.setIsPegged(isPegged); // ETH/MATIC/MAI/QI/BIFI - true; everything else - false
  return estimateRequest;
}

export async function checkAllowance(
  library: Web3Provider,
  connectorType: ConnectorTypes,
  sourceChainId: number,
  address: string,
  symbol: Symbols
) {
  const provider = getProvider(
    connectorType,
    library,
    sourceChainId
  );
  const tokenMeta = Tokens.find(e => 
    e.chainId === sourceChainId && e.symbol === symbol
  )!;
  const contractMeta = ContractsMeta.find(e => 
    e.chainId === sourceChainId && e.name === tokenMeta.contract
  )!;
  const tokenAddress = tokenMeta.address!;
  const token = getContract(tokenAddress, tokenMeta.abi, provider);
  try {
    const result = await token?.allowance(address, contractMeta.address);
    return result;
  } catch(err) {
    console.log("-------- checkAllowance error", err);
    throw err;
  }
}

export async function approveAllowance(
  library: Web3Provider,
  connectorType: ConnectorTypes,
  sourceChainId: number,
  symbol: Symbols,
) {
  const provider = getProvider(
    connectorType,
    library,
    sourceChainId
  );
  const signer = provider.getSigner();
  const tokenMeta = Tokens.find(e => 
    e.chainId === sourceChainId && e.symbol === symbol
  )!;
  const tokenAddress = tokenMeta.address!;
  const token = new ethers.Contract(tokenAddress, tokenMeta.abi, signer as JsonRpcSigner);
  const contractMeta = ContractsMeta.find(e => 
    e.chainId === sourceChainId && e.name === tokenMeta.contract
  )!;
  try {
    let approveTx:TransactionResponse;
    if(sourceChainId === MAINCHAIN_ID && connectorType === ConnectorTypes.Magic) {
      approveTx = await token?.approve(
        contractMeta.address, 
        constants.MaxUint256,
        SX_GAS
      );
    } else {
      approveTx = await token?.approve(
        contractMeta.address, 
        constants.MaxUint256,
      );
    }
    const receipt = await provider.waitForTransaction(approveTx.hash);
    return receipt;
  } catch(err) {
    console.log("-------- approveAllowance error", err);
    throw err;
  }
}

export async function cBridgeGetStatusUpdate(
  transferId: string,
) {
  try {
    const statusUpdate = new GetTransferStatusRequest();
    statusUpdate.setTransferId(transferId);
    return statusUpdate;
  } catch(err) {
    console.log("-------- cBridgeGetStatusUpdate error", err);
    throw err;
  }
}

export async function cBridgeEstimateWithdrawRequest(
  transfer: any,
  address: string
) {
  const estimateWithdrawInfo = new WithdrawInfo();
  estimateWithdrawInfo.setChainId(transfer!.srcChainId);
  estimateWithdrawInfo.setAmount(transfer!.srcSendInfo.amount);
  estimateWithdrawInfo.setSlippageTolerance(1000000);

  const estimateWithdrawRequest = new EstimateWithdrawAmtRequest();
  estimateWithdrawRequest.setSrcWithdrawsList(Array(estimateWithdrawInfo));
  estimateWithdrawRequest.setDstChainId(transfer!.srcChainId);
  estimateWithdrawRequest.setTokenSymbol(transfer!.symbol);
  estimateWithdrawRequest.setUsrAddr(address);

  const client = new WebClient(CBRIDGE_URL!, null, null);
  const response = await client.estimateWithdrawAmt(estimateWithdrawRequest, null);

  let estimateResult = "";
  if (!response.getErr() && response.getReqAmtMap()) {
    const resMap = response.getReqAmtMap();
    resMap.forEach((entry: any, key: number) => {
      if (key === Number(transfer.srcChainId)) {
        const totleFee = (Number(entry.getBaseFee()) + Number(entry.getPercFee())).toString() || "0";
        const eqValueTokenAmtBigNum = BigNumber.from(entry.getEqValueTokenAmt());
        const feeBigNum = BigNumber.from(totleFee);
        const targetReceiveAmounts = eqValueTokenAmtBigNum.sub(feeBigNum);
        estimateResult = targetReceiveAmounts.toString();
      }
    });
  }
  return estimateResult;
}

export async function cBridgeWithdrawLiquidityRequest(
  library: Web3Provider,
  transferId: string,
  estimate: string,
): Promise<WithdrawLiquidityResponse | undefined> {
  const client = new WebClient(CBRIDGE_URL!, null, null);
  const timestamp = Math.floor(Date.now() / 1000);
  const withdrawReq = new WithdrawReq();
  withdrawReq.setXferId(transferId);
  withdrawReq.setReqId(timestamp);
  withdrawReq.setWithdrawType(WithdrawType.WITHDRAW_TYPE_REFUND_TRANSFER);
  const request = new WithdrawLiquidityRequest();
  request.setWithdrawReq(withdrawReq.serializeBinary());
  request.setEstimatedReceivedAmt(estimate);
  request.setMethodType(WithdrawMethodType.WD_METHOD_TYPE_ONE_RM);
  try {
    const result = await client.withdrawLiquidity(request,null);
    return result;
  } catch(err) {
    console.log("-------- withdrawLiquidity error", err);
    throw err;
  }
}

export const cBridgeGetTransferStatus = async (params): Promise<GetTransferStatusResponseType> => {
  try {
    const response = await axios.post(`${CBRIDGE_URL}/v1/getTransferStatus`, {
      ...params,
    });
    return response.data;
  } catch(err) {
    console.log("-------- cBridgeGetTransferStatus error", err);
    throw err;
  }
};

export async function cRefundToken(
  library: Web3Provider,
  connectorType: ConnectorTypes,
  wdmsg: any,
  sigs: any,
  signers: any,
  powers: any,
  sourceChainId: number,
):Promise<TransactionResponse | undefined> {
  const provider = getProvider(
    connectorType,
    library,
    sourceChainId
  );
  const signer = provider.getSigner();
  const findContract = ContractsMeta.find(e => e.name === ContractNames.CBRIDGE)!;
  const cBridgeContract = new ethers.Contract(findContract.address, findContract.abi, signer);
  try {
    const response = await cBridgeContract.withdraw(
      wdmsg,
      sigs,
      signers,
      powers,
      sourceChainId === MAINCHAIN_ID && connectorType === ConnectorTypes.Magic && SX_GAS
    )
    await response.wait();
    return response;
  } catch(err) {
    console.log("-------- cRefundToken error", err);
    throw err;
  }
}

export async function cEstimateAmount(
  estimateRequest:any
) {
  try {
    const client = new WebClient(CBRIDGE_URL!, null, null);
    const response = await client.estimateAmt(estimateRequest, null);
    return {
      slippage: response.getMaxSlippage(),
      amount: response.getEstimatedReceiveAmt(),
      baseFee: response.getBaseFee(),
      basePercentageFee: response.getPercFee(),
    };
  } catch(err) {
    console.log("-------- cEstimateAmount error", err);
    throw err;
  }
}

export async function cBridgeHelper(
  sourceChainId: number,
  library: Web3Provider,
  connectorType: ConnectorTypes
) {
  const provider = getProvider(
    connectorType,
    library,
    sourceChainId
  );
  const signer = provider.getSigner();
  const findDefaultContract = ContractsMeta.find(e => e.name === ContractNames.CBRIDGE_DEFAULT && e.chainId === sourceChainId)!;
  const cBridgeDefaultContract = new ethers.Contract(findDefaultContract.address, findDefaultContract.abi, signer);
  return cBridgeDefaultContract;
}

export async function fastDeposit(
  library: Web3Provider,
  connectorType: ConnectorTypes,
  sourceChainId: number,
  symbol: Symbols,
  amount: string,
  accountAddress: string
) {
  const provider = getProvider(
    connectorType,
    library,
    sourceChainId
  );
  const tokenMeta = Tokens.find(e => 
    e.chainId === sourceChainId && e.symbol === symbol
  )!;
  const token = getContract(tokenMeta?.address!, tokenMeta.abi, provider); 
  const tokenInterface = new utils.Interface(tokenMeta.abi);

  let nonce: BigNumber;
  if (symbol === Symbols.USDC && ENV !== Environments.TESTNET) {
    nonce = await token.nonces(accountAddress);
  } else {
    nonce = await token.getNonce(accountAddress);
  }

  const tokenName: string = await token.name();
  const submissionAmount = utils.parseUnits(amount, tokenMeta.decimals).toString();

  const approveNonce = nonce.toNumber();
  const approveFunctionSig = tokenInterface.encodeFunctionData("approve", [
    FAST_EXIT_SPENDER,
    BigNumber.from(submissionAmount)
  ]);

  const signaturePayload = getMaticEip712Payload(
    approveFunctionSig,
    approveNonce,
    accountAddress,
    sourceChainId,
    token.address,
    tokenName
  );

  const approveSpenderSignature = await provider.send(
    "eth_signTypedData_v4",
    [accountAddress, JSON.stringify(signaturePayload)]
  );

  const payload: any = {
    owner: accountAddress,
    spender: FAST_EXIT_SPENDER,
    tokenAddress: token.address,
    amount: submissionAmount,
    signature: approveSpenderSignature
  };

  try {
    const response = await axios.post(`${RELAYER_URL}/bridge/fast-deposit/lock`, {
      ...payload,
    });
    return response;
  } catch(err) {
    console.log("-------- fastDeposit error", err);
    throw err;
  }
}