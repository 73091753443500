import { SX_NETWORK_BRIDGE } from '@sharkswap/sharkswap-config'
import { ChainIds, ContractNames, ENV, Environments, IContract } from '.'
import { Bridge__factory as cBridge__factory } from "../typechain/typechain/factories/Bridge__factory";
import { Bridge, ERC20Handler, ERC20SXHandler, UniswapV2Pair } from "../abi/index";
import { OriginalTokenVault__factory as cBridgeOriginalTokenVault__factory } from "../typechain/typechain/factories/OriginalTokenVault__factory";
import { OriginalTokenVaultV2__factory as cBridgeOriginalTokenVaultV2__factory } from "../typechain/typechain/factories/OriginalTokenVaultV2__factory";
import { PeggedTokenBridgeV2__factory as cBridgePeggedTokenBridgeV2__factory } from "../typechain/typechain/factories/PeggedTokenBridgeV2__factory";

const contractsByEnv: {
  [env: string]: IContract[]
} = {
  [Environments.TESTNET]: [
    {
      name: ContractNames.BRIDGE,
      chainId: ChainIds.TORONTO,
      address: SX_NETWORK_BRIDGE[ChainIds.TORONTO].BRIDGE,
      abi: Bridge.abi,
    },
    {
      name: ContractNames.BRIDGE,
      chainId: ChainIds.MUMBAI,
      address: SX_NETWORK_BRIDGE[ChainIds.MUMBAI].BRIDGE,
      abi: Bridge.abi,
    },
    {
      name: ContractNames.ERC20_HANDLER,
      chainId: ChainIds.TORONTO,
      address: SX_NETWORK_BRIDGE[ChainIds.TORONTO].ERC20_HANDLER,
      abi: ERC20SXHandler.abi,
    },
    {
      name: ContractNames.ERC20_HANDLER,
      chainId: ChainIds.MUMBAI,
      address: SX_NETWORK_BRIDGE[ChainIds.MUMBAI].ERC20_HANDLER,
      abi: ERC20Handler.abi,
    },
    {
      name: ContractNames.CBRIDGE,
      chainId: ChainIds.MUMBAI,
      address: "0x81ECac0D6Be0550A00FF064a4f9dd2400585FE9c", // Mumbai MATIC to Toronto
      abi: cBridgeOriginalTokenVaultV2__factory.abi,
    },
    {
      name: ContractNames.CBRIDGE,
      chainId: ChainIds.TORONTO,
      address: "0xBB7684Cc5408F4DD0921E5c2Cadd547b8f1AD573", // Toronto MATIC to Mumbai, Toronto USDC and WETH to Goerli
      abi: cBridgePeggedTokenBridgeV2__factory.abi,
    },
    {
      name: ContractNames.CBRIDGE,
      chainId: ChainIds.GOERLI,
      address: "0xE40e60098CCF287413f4F08E39a912E7b6CE8146", // Goerli WETH and USDC to Toronto
      abi: cBridgeOriginalTokenVault__factory.abi,
    },
    {
      name: ContractNames.UNISWAP,
      chainId: ChainIds.POLYGON,
      address: "0x6ff62bfb8c12109e8000935a6de54dad83a4f39f", // Polygon Uniswap Eth Price Getter
      abi: UniswapV2Pair.abi,
    },
  ],
  [Environments.MAINNET]: [
    {
      name: ContractNames.BRIDGE,
      chainId: ChainIds.SX,
      address: SX_NETWORK_BRIDGE[ChainIds.SX].BRIDGE,
      abi: Bridge.abi,
    },
    {
      name: ContractNames.BRIDGE,
      chainId: ChainIds.POLYGON,
      address: SX_NETWORK_BRIDGE[ChainIds.POLYGON].BRIDGE,
      abi: Bridge.abi,
    },
    {
      name: ContractNames.ERC20_HANDLER,
      chainId: ChainIds.SX,
      address: SX_NETWORK_BRIDGE[ChainIds.SX].ERC20_HANDLER,
      abi: ERC20SXHandler.abi,
    },
    {
      name: ContractNames.ERC20_HANDLER,
      chainId: ChainIds.POLYGON,
      address: SX_NETWORK_BRIDGE[ChainIds.POLYGON].ERC20_HANDLER,
      abi: ERC20Handler.abi,
    },
    {
      name: ContractNames.CBRIDGE,
      chainId: ChainIds.POLYGON,
      address: "0xc1a2D967DfAa6A10f3461bc21864C23C1DD51EeA", // Polygon MATIC, MAI to SX
      abi: cBridgeOriginalTokenVaultV2__factory.abi,
    },
    {
      name: ContractNames.CBRIDGE,
      chainId: ChainIds.SX,
      address: "0x9bb46d5100d2db4608112026951c9c965b233f4d", // SX MATIC, MAI to Polygon, SX USDC and WETH to Ethereum
      abi: cBridgePeggedTokenBridgeV2__factory.abi,
    },
    {
      name: ContractNames.CBRIDGE,
      chainId: ChainIds.ETHEREUM,
      address: "0xb37d31b2a74029b5951a2778f959282e2d518595", // Ethereum USDC to SX
      abi: cBridgeOriginalTokenVault__factory.abi,
    },
    {
      name: ContractNames.CBRIDGE_DEFAULT,
      chainId: ChainIds.ETHEREUM,
      address: "0x5427FEFA711Eff984124bFBB1AB6fbf5E3DA1820", // Default cBridge for Ethereum
      abi: cBridge__factory.abi,
    },
    {
      name: ContractNames.CBRIDGE_DEFAULT,
      chainId: ChainIds.POLYGON,
      address: "0x88DCDC47D2f83a99CF0000FDF667A468bB958a78", // Default cBridge for Polygon
      abi: cBridge__factory.abi,
    },
    {
      name: ContractNames.CBRIDGE_DEFAULT,
      chainId: ChainIds.SX,
      address: "0x0000000000000000000000000000000000000000", // TODO (Not deployed yet) Default cBridge for SX
      abi: cBridge__factory.abi,
    },
    {
      name: ContractNames.CBRIDGE_CUSTOM,
      chainId: ChainIds.POLYGON,
      address: "0x4c882ec256823ee773b25b414d36f92ef58a7c0c", // Polygon QiDao, BiFi, AthleteX
      abi: cBridgeOriginalTokenVault__factory.abi,
    },
    {
      name: ContractNames.CBRIDGE_CUSTOM,
      chainId: ChainIds.SX,
      address: "0x9bb46d5100d2db4608112026951c9c965b233f4d", // SX QiDao, BiFi
      abi: cBridgePeggedTokenBridgeV2__factory.abi,
    },
    {
      name: ContractNames.CBRIDGE_CUSTOM,
      chainId: ChainIds.ETHEREUM,
      address: "0x7510792a3b1969f9307f3845ce88e39578f2bae1", // Ethereum ETH and WETH to SX
      abi: cBridgeOriginalTokenVault__factory.abi,
    },
    {
      name: ContractNames.UNISWAP,
      chainId: ChainIds.POLYGON,
      address: "0x6ff62bfb8c12109e8000935a6de54dad83a4f39f", // Polygon Uniswap Eth Price Getter
      abi: UniswapV2Pair.abi,
    },
  ],
}

export const ContractsMeta = contractsByEnv[ENV]
