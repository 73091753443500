import axios from "axios";
import { CoingeckoSymbols, MAINCHAIN_ID, MATIC_GAS_PRICE_URL, RELAYER_URL, SIDECHAIN_ID, SX_GAS_PRICE_URL } from "../config";
import { Tokens } from "../config/tokens";
import { IRatesState } from "../state/rates";
import { sendSentryError } from "../services/sentry";
import { isEmpty, isNumeric } from "../utils";

export async function getRates(): Promise<IRatesState> {
  try {
    const symbols =  [...new Set(Object.values(CoingeckoSymbols))].join();
    const query = `https://api.coingecko.com/api/v3/simple/price?ids=${encodeURIComponent(symbols)}&vs_currencies=usd`;
    const response = await axios.get(query);
    const data = response.data;
    let rates: IRatesState = {};
    for (let token of Tokens) {
      const key = CoingeckoSymbols[token.symbol];
      rates[token.symbol] = key && data[key] ? data[key].usd : 1;
    }
    //console.log("------ getRates", response, rates);
    return rates;
  } catch (err) {
    console.log("------ getRates error", err);
    sendSentryError(err as Error);
    throw err;
  }
}

export async function getBridgeFee(): Promise<number> {
  try {
    const response = await axios.get(`${RELAYER_URL}/metadata`);
    const { data: { data } } = response;
    return data.bridgeFee;
  } catch(err) {
    console.log("-------- getBridgeFee error", err);
    throw err;
  }
}

let gasPrices = {
  [MAINCHAIN_ID]: 0,
  [SIDECHAIN_ID]: 0, //MATIC_ESTIMATED_GAS_PRICE_GWEI * 10 ** 9
};

export async function getGasPrice(chainId: number) {
  try {
    if (chainId === MAINCHAIN_ID) {
      try {
        let gasPrice = 0;
        const response = await axios.get(MATIC_GAS_PRICE_URL);
        if (!isEmpty(response.data)) {
          const price = Number(response?.data?.fastest); // gWei
          if (isNumeric(price) && price > 0) {
            gasPrice = price * 10 ** 9;
          }
        }
        if (gasPrice) {
          gasPrices[MAINCHAIN_ID] = gasPrice;
        }
        return gasPrices[MAINCHAIN_ID];
      } catch(err) {
        return gasPrices[MAINCHAIN_ID] = 1000 * 10 ** 9;
      }
    } else {
      let gasPrice = 0;
      const response = await axios.get(SX_GAS_PRICE_URL);
      if (!isEmpty(response.data)) {
        const price = Number(response?.data?.rapid); // gWei
        if (isNumeric(price) && price > 0) {
          gasPrice = price * 10 ** 9;
        }
      }
      if (gasPrice) {
        gasPrices[SIDECHAIN_ID] = gasPrice;
      }
    }
    return gasPrices[chainId];
    // gas price in wei
  } catch (err:any) {
    console.error("------------- getGasPrice", err);
    sendSentryError(err);
    throw err;
  }
}
