import axios from "axios";
import { RELAYER_URL } from "../config";


export async function getFeatureFlags(): Promise<number> {
  try {
    const response = await axios.get(`${RELAYER_URL}/feature-flags`);
    const { data: { data } } = response;
    return data;
  } catch(err) {
    console.log("-------- getFeatureFlags error", err);
    throw err;
  }
}
