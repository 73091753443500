import numeral from "numeral";
import { Fragment, memo } from "react";
import { useRecoilValue } from "recoil";
import { IToken } from "../config";
import { balanceState } from "../state/balances";

function TokenBalance(params: { token: IToken, account: string }) {
  const { token, account } = params;
  const value = useRecoilValue(balanceState({ 
    chainId: token.chainId,
    symbol: token.symbol,
    account 
  })) as any;
  const formatted = numeral(value.balance).format(token.format, Math.floor);

  return <Fragment> {formatted}</Fragment>;
}

export default memo(TokenBalance);
