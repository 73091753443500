import { utils } from "ethers";
import numeral from "numeral";
import { atom, selector } from "recoil";
import { ChainIds, MAINCHAIN_ID, SIDECHAIN_ID, Symbols } from "../config";
import { Tokens } from "../config/tokens";

export interface ITransferState {
  id?: string;
  timestamp: number;
  dstChainId: number;
  srcChainId?: number;
  resourceId: string;
  amount: string;
  account: string;
  transactionHash: string;
  status: string;
  celer?: boolean,
  dstBlockTxLink?: string;
  srcBlockTxLink?: string;
  dstReceivedInfo?: any;
  srcSendInfo?: any;
  symbol?: Symbols;
  fastBridge?: boolean;
}

export interface ITransferHistory {
  id: string;
  timestamp: number;
  srcChainId: ChainIds;
  dstChainId: ChainIds;
  resourceId: string;
  amount: string;
  symbol: Symbols;
  label: string;
  icon: string;
  amountNum: number;
  formattedAmount: string;
  account: string;
  transactionHash: string;
  status: string;
  celer?: boolean,
  dstBlockTxLink?: string;
  srcBlockTxLink?: string;
  dstReceivedInfo?: any;
  srcSendInfo?: any;
  fastBridge?: boolean;
}

export const transfersState = atom<ITransferState[] | undefined>({
  key: 'Transfers',
  default: undefined
});

export const transferHistorySelector = selector<ITransferHistory[] | undefined>({
  key: "TransferHistory",
  get: ({ get }) => {
    const transfers = get(transfersState);
    if (!transfers) return undefined;
    const history: ITransferHistory[] = [];
    for (let transfer of transfers) {
      const { id, symbol, dstChainId, srcChainId, resourceId, amount, timestamp, transactionHash, status, celer, dstBlockTxLink, srcBlockTxLink, dstReceivedInfo, srcSendInfo, fastBridge } = transfer;
      let _srcChainId = srcChainId;
      if(!_srcChainId) {
        _srcChainId = dstChainId === MAINCHAIN_ID ? SIDECHAIN_ID : MAINCHAIN_ID; 
      }
      const meta = Tokens.find(e => e.chainId === _srcChainId && e.symbol === symbol);
      if (meta) {
        const amountNum = Number(utils.formatUnits(amount, meta.decimals));
        const entry: any = {
          id,
          timestamp,
          srcChainId: _srcChainId,
          dstChainId,
          resourceId,
          amount,
          symbol: meta.symbol,
          icon: meta.icon,
          label: meta.label,
          amountNum,
          formattedAmount: numeral(amountNum).format(meta.format),
          transactionHash,
          status,
          celer,
          dstBlockTxLink,
          srcBlockTxLink,
          dstReceivedInfo,
          srcSendInfo,
          fastBridge
        }
        history.push(entry);
      }
    }
    history.sort((a, b) => b.timestamp - a.timestamp);
    return history;
  }  
});

