import { memo } from "react"
import { ChainIds } from "../config";
import { Chains } from "../config/chains";

function ExplorerLink(
  params: { 
    chainId: ChainIds, 
    txHash: string, 
    label?: string, 
    celer?: boolean, 
    celerTxHash?: string 
  }) {
  const { chainId, txHash, label } = params;
  const explorerUrl = Chains.find(e => e.chainId === chainId)!.blockExplorer;
  return (
    <a 
      href={`${explorerUrl}/tx/${txHash}`}
      target="_blank" 
      rel="noreferrer"
    >
      {label || "View in Explorer"}
    </a>
  )
}

export default memo(ExplorerLink);
