import { atom, selector } from "recoil";
import { ChainIds } from "../config";
import { transfersState } from "./transfers";

export enum TransferProgressSteps {
  NOT_STARTED = -1,
  APPROVING = 0,
  SUBMITTING = 1,
  TRANSFERRING = 2,
  RECEIVING = 3,
  COMPLETE = 4
}

export interface ICurrentTransfer {
  timestamp?: number;
  dstChainId?: ChainIds;
  resourceId?: string;
  amount?: string;
  account?: string;
  step: TransferProgressSteps | undefined;
  txHash?: string;
  error?: string;
}

export const currentTransferState = atom<ICurrentTransfer | undefined>({
  key: "CurrentTransferState",
  default: undefined
});

export const currentTransferStepSelector = selector<TransferProgressSteps | undefined>({
  key: "CurrentTransferStep",
  get: ({ get }) => {
    const transfers = get(transfersState) || [];
    const currentTransfer = get(currentTransferState);

    if (!currentTransfer) return undefined;
    
    const isComplete = transfers.find(e => (
      e.account === currentTransfer.account! &&
      e.dstChainId === currentTransfer.dstChainId! &&
      e.resourceId === currentTransfer.resourceId! &&
      e.amount === currentTransfer.amount! &&
      e.timestamp > currentTransfer.timestamp! &&
      e.status === "success"
    ));

    return isComplete ? TransferProgressSteps.COMPLETE : currentTransfer.step;
  }
});
