/** @jsxImportSource @emotion/react */
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from '@ethersproject/providers';
import { 
  Box, 
  Button, 
  CircularProgress, 
  IconButton, 
  MenuItem, 
  Skeleton, 
  TextField, 
  Typography, 
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuList,
  ListItemSecondaryAction,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { parseUnits } from "@ethersproject/units";
import { SwapVert, CloseRounded, ArrowDropDown } from "@mui/icons-material";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import { useForm, Controller } from 'react-hook-form';
import { currentChainIdState, currentAlertState, selectedTokenState } from "../state";
import { memo, Suspense, useEffect, useState } from "react";
import { transferToken, cBridgeToken, cBridgeEstimateRequest, checkAllowance, approveAllowance, cEstimateAmount, fastDeposit } from "../services/tokens";
import { Tokens } from "../config/tokens";
import { IToken, MAINCHAIN_ID, Symbols, CelerBridgeMode, SIDECHAIN_ID } from "../config";
import { BigNumber, utils } from "ethers";
import { ChainConfigs, Chains } from "../config/chains";
import TokenBalance from "./TokenBalance";
// import AirDropNotification from "./AirDropNotification";
import numeral from "numeral";
import { balanceState, IBalance } from "../state/balances";
import { useDebounce } from "../utils";
import { currentTransferState, currentTransferStepSelector, TransferProgressSteps } from "../state/bridge";
import { getConnectorType } from "../connectors/utils";
import { ConnectorTypes } from "../connectors";
import StatusAlert from "./StatusAlert";
import TransferProgress from "./TransferProgress";
import getBridgeStyles from "../styles/BridgeStyles";
import { Alert } from "@mui/material";
import { bridgeFeeQuery, ethPriceQuery } from "../state/rates";
import { featureFlagsQuery } from "../state/featureFlags";

interface IEstimatedAmount {
  amount: number | string;
  formatted: string;
}

function Bridge() {

  // state
  const [tokenSelectorOpen, setTokenSelectorOpen] = useState(false);
  const [chainSelectorOpen, setChainSelectorOpen] = useState(false);
  const [fastBridgeEnabled, setFastBridgeEnabled] = useState(true);
  const [bridgingEnabled, setBridgingEnabled] = useState(true);
  const [processing, setProcessing] = useState("");
  const [, setMaxAmountRequested] = useState(false);
  const [allowance, setAllowance] = useState<BigNumber>();
  const [estimatedAmount, setEstimatedAmount] = useState<IEstimatedAmount>({
    amount:"",
    formatted: ""
  });
  const [fetchingEstimate, setFetchingEstimate] = useState<boolean>(false);
  
  // hooks
  const theme = useTheme();
  const { connector, library, account, chainId } = useWeb3React<Web3Provider>();
  const { handleSubmit, control, setValue, trigger, watch } = useForm({ defaultValues: { symbol: "", amount: "" }, });
  const connectorType = getConnectorType(connector);  
  const watchAmount = watch("amount");
  const debouncedAmount = useDebounce(watchAmount, 1000);

  // recoil
  const [srcChainId, setSrcChainId] = useRecoilState(currentChainIdState);
  const [selectedToken, setSelectedToken] = useRecoilState(selectedTokenState);
  const [currentStatus, setCurrentAlert] = useRecoilState(currentAlertState);
  const [currentTransfer, setCurrentTransfer] = useRecoilState(currentTransferState);
  const currentTransferStep = useRecoilValue(currentTransferStepSelector);
  const [, setCurrentChainId] = useRecoilState(currentChainIdState);
  const { state: featureFlagsState, contents: featureFlags } = useRecoilValueLoadable(featureFlagsQuery);

  const bridgeFeeData = useRecoilValueLoadable(bridgeFeeQuery);
  const bridgeFeeLoaded = bridgeFeeData.state === "hasValue";
  const bridgeFee = bridgeFeeLoaded ? bridgeFeeData.contents : undefined;
  const ethPriceData = useRecoilValueLoadable(ethPriceQuery);
  const ethPriceLoaded = ethPriceData.state === "hasValue";
  const ethPrice = ethPriceLoaded ? ethPriceData.contents : undefined;

  // styles
  const styles = getBridgeStyles({ theme }); 
  
  // tokens & chains
  const availableChains = Chains.filter(chain => {
    return (connectorType === ConnectorTypes.Magic || connectorType === ConnectorTypes.Injected) && chain;
  });
  const tokens = Tokens.filter(e => e.chainId === srcChainId && !e.unbridgeable /* && e.resourceId */);
  const nativeToken = Tokens.find(e => e.chainId === srcChainId && !e.address)
  const srcChain = Chains.find(e => e.chainId === srcChainId)!;
  const isWrongNetwork = connectorType === ConnectorTypes.Injected && srcChain.chainId !== chainId; 
  let srcToken: IToken | undefined = undefined;
  let dstToken: IToken | undefined = undefined;
  if (selectedToken) {
    srcToken = selectedToken;
    dstToken = Tokens.find(e => e.chainId === selectedToken.bridgeToChain && e.symbol === selectedToken.bridgeToToken);
  }

  let srcBalance: IBalance | undefined = undefined;
  const balanceLoadable = useRecoilValueLoadable(balanceState({ 
    chainId: srcChain.chainId, 
    symbol: srcToken?.symbol,
    account: account as any
  }));
  let nativeBalance: IBalance | undefined = undefined;
  const nativeBalanceLoadable = useRecoilValueLoadable(balanceState({ 
    chainId: srcChain.chainId, 
    symbol: nativeToken?.symbol,
    account: account as any
  }));
  if (balanceLoadable.state === "hasValue" && balanceLoadable.contents) {
    srcBalance = balanceLoadable.contents;
  }
  if (nativeBalanceLoadable.state === "hasValue" && nativeBalanceLoadable.contents) {
    nativeBalance = nativeBalanceLoadable.contents;
  }

  // lifecycles
  useEffect(() => {
    setCurrentAlert(undefined);
    return () => {
      setCurrentTransfer({ 
        step: undefined
      });
      setSelectedToken(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(debouncedAmount) {
      handleEstimateAmount();
    } else {
      setEstimatedAmount({
        amount: "",
        formatted: ""
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAmount]);

  useEffect(() => {
    if(featureFlagsState === "hasValue") {
      setBridgingEnabled(featureFlags["bridging-enabled"]);
    }
  },[featureFlags, featureFlagsState]);

  useEffect(() => {
    if (currentTransferStep === TransferProgressSteps.COMPLETE) {
      setProcessing("");
      setCurrentAlert({ 
        severity: "success",
        message: "Complete!",
        chainId: srcChainId,
        txHash: currentTransfer?.txHash
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTransferStep]);

  useEffect(() => {
    if(selectedToken && chainId === selectedToken.chainId) {
      handleCheckAllowance(selectedToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[chainId]);

  useEffect(() => {
    if(!selectedToken) {
      const defaultToken = Tokens.find(token => token.chainId === srcChain.chainId && token.default === true);
      setSelectedToken(
        defaultToken
      );
      handleCheckAllowance(defaultToken!);
    } else {
      setValue("symbol",selectedToken!?.symbol);
      if(selectedToken!?.symbol === Symbols.WETH && selectedToken!?.fastDeposit && !ethPrice) {
        // disable WETH Fast Bridge because ethPrice is unavailable
        setCurrentAlert({ 
          severity: "error",
          message: "Could not fetch the price of Ethereum. Please try again later.",
          chainId: srcChainId,
        });
        setFastBridgeEnabled(false);
      } else {
        setFastBridgeEnabled(true);
        setCurrentAlert(undefined);
      }
    }
    if(debouncedAmount) {
      handleEstimateAmount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedToken]);

  // methods
  const handleEstimateAmount = async () => {
    setEstimatedAmount({
      amount: "",
      formatted: ""
    });
    if(isNaN(Number(debouncedAmount))) {
      return false;
    }
    if(selectedToken && selectedToken?.celer) {
      setFetchingEstimate(true);
      try {
        const value = parseUnits(debouncedAmount || "0", selectedToken?.decimals);    
        const estimateRequest = await cBridgeEstimateRequest(
          value,
          selectedToken?.bridgeToChain!,
          srcChainId,
          account!,
          selectedToken?.symbol!,
          selectedToken?.cellerPegged ? true : false,
        ); 
        const estimates = await cEstimateAmount(estimateRequest);
        const recievedAmount = Number(utils.formatUnits(estimates.amount, selectedToken?.decimals));
        const formattedRecievedAmount = numeral(recievedAmount).format(selectedToken?.format);
        if(recievedAmount <= 0) {
          setCurrentAlert({ 
            severity: "error",
            message: "The recieved amount is too small and can't cover the fee",
            chainId: srcChainId,
          });
        } else {
          setCurrentAlert(undefined);
        }
        setFetchingEstimate(false);
        setEstimatedAmount({
          amount: recievedAmount,
          formatted: `${selectedToken?.bridgeToToken ? selectedToken?.bridgeToToken : selectedToken?.label} ${formattedRecievedAmount}`
        });
      } catch(err) {
        setFetchingEstimate(false);
      }
    } else if (selectedToken && selectedToken.fastDeposit) {
      let recievedAmount:number | any;
      let formattedRecievedAmount:string | any;

      if(selectedToken.symbol === Symbols.WETH) {
        recievedAmount = Number(debouncedAmount) - (ethPrice! * bridgeFee!);     
      } else if (selectedToken.symbol === Symbols.USDC) {
        recievedAmount = Number(debouncedAmount) - bridgeFee!;
      } else {
        recievedAmount = Number(debouncedAmount);
      }
      formattedRecievedAmount = numeral(recievedAmount).format(selectedToken?.format);   
      setEstimatedAmount({
        amount: recievedAmount,
        formatted: `${selectedToken?.bridgeToToken ? selectedToken?.bridgeToToken : selectedToken?.label} ${formattedRecievedAmount}`
      });
    } else {
      setFetchingEstimate(false);
      setEstimatedAmount({
        amount: debouncedAmount,
        formatted: `${selectedToken?.bridgeToToken ? selectedToken?.bridgeToToken : selectedToken?.label} ${debouncedAmount}`
      });
    }
  }

  const handleNetworkSwitch = (chainId) => {
    window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: ChainConfigs[chainId]["chainId"] }],
    });
  };

  const handleCheckAllowance = async (token:IToken) => {
    if(
      (token?.celer && token.celerMode! > CelerBridgeMode.NATIVE_TOKEN && !token?.fastDeposit && token!?.symbol !== Symbols.ETH) 
      || 
      (chainId === token.chainId && MAINCHAIN_ID !== chainId && token!?.symbol === Symbols.SX)
      ) {
      console.log("Checking Allowance for:", token.symbol);
      const allowance = await checkAllowance(
        library!,
        connectorType!,
        token.chainId,
        account!,
        token.symbol
      );
      console.log("Allowance for:", token.symbol, utils.formatUnits(allowance, token?.decimals));
      if(token.symbol === Symbols.SX) {
        // custom allowance threshold for SX (legacy: amount === allowance)
        if(Number(utils.formatUnits(allowance, token?.decimals)) <= 20000) {
          setAllowance(undefined);
        } else {
          setAllowance(allowance);
        }
        return;
      }
      setAllowance(allowance);
    }
  }

  const handleApproveAllowance = async (token:IToken) => {
    setProcessing("Approving");
    setCurrentAlert(undefined);
    try {
      await approveAllowance(
        library!,
        connectorType!,
        srcChainId,
        token.symbol
      );
      handleCheckAllowance(token!);
      setProcessing("");
      setCurrentAlert({ 
        severity: "info",
        message: "Your token approval is being processed. It may take up to 1 minute to update the interface.",
        chainId: srcChainId,
      });
    } catch(err:any) {
      setCurrentAlert({ 
        severity: "error",
        message: err && err.message ? err.message : "Something went wrong, please check the console for more details.",
        chainId: srcChainId,
      });
      setProcessing("");
    }
  }
  
  const onSubmit = async (values: any) => {
    const { symbol, amount } = values;
    setCurrentAlert(undefined);
    
    if( Number(utils.formatUnits(BigNumber.from(nativeBalance?.raw), nativeToken?.decimals)) === 0 ) {
      setCurrentAlert({ 
        severity: "error",
        message: `You need ${nativeBalance?.symbol} to perform this transaction.`,
        chainId: srcChainId,
      });
      return;
    }
    
    setProcessing("");
    if(selectedToken && selectedToken.celer) {
      // Celer flow (USDC/WETH/MATIC)
      if(selectedToken.celerMode! > CelerBridgeMode.NATIVE_TOKEN && (!allowance || allowance.lte(0))) {
        handleApproveAllowance(selectedToken);
        return;
      }
      setProcessing("Submitting");
      const nonce = new Date().getTime();
      const value = parseUnits(amount || "0", srcToken?.decimals);    
      try {
        const estimateRequest = await cBridgeEstimateRequest(
          value,
          selectedToken?.bridgeToChain!,
          srcChainId,
          account!,
          symbol,
          selectedToken.cellerPegged ? true : false,
        );  
        const estimates = await cEstimateAmount(estimateRequest);
        /*
        const estimatedRecievedAmount = Number(utils.formatUnits(estimates.amount, srcToken?.decimals))
        const trasnferId = await cBridgeTransferId(
          value,
          dstChainId,
          srcChainId,
          nonce,
          account!,
          symbol,
        );
        */
        const currentTransfer = await cBridgeToken(
          library!,
          connectorType!,
          value,
          selectedToken?.bridgeToChain!,
          srcChainId,
          estimates.slippage,
          nonce,
          account!,
          symbol
        );
        setValue("amount", "");
        setProcessing("");
        setCurrentAlert({ 
          severity: "info",
          message: "Your transaction is being processed.",
          chainId: srcChainId,
          txHash: currentTransfer?.hash,
          celer: true
        });
      } catch(err:any) {  
        setProcessing("");
        setCurrentAlert({ 
          severity: "error",
          message: err && err.message ? err.message : "Something went wrong, please check the console for more details.",
          chainId: srcChainId,
        });
      }
      return;
    }
    if(selectedToken && selectedToken.fastDeposit) {
      // flow for Fast Deposit (Polygon WETH and USDC)
      setProcessing("Submitting");
      try {
        await fastDeposit(
          library!,
          connectorType!,
          srcChainId,
          symbol,
          amount.toString(),
          account!
        );
        setCurrentAlert({ 
          severity: "info",
          message: "Your transaction is being processed.",
          chainId: srcChainId,
        });
        setValue("amount", "");
        setProcessing("");
      } catch(err:any) {
        let errorMessage:any;
        if(err.response) {
          errorMessage = err.response?.data?.message ? err.response.data.message : "Something went wrong, please check the console for more details.";
        } else {
          errorMessage = err && err.message ? err.message : "Something went wrong, please check the console for more details."
        }
        setProcessing("");
        setCurrentAlert({ 
          severity: "error",
          message: errorMessage,
          chainId: srcChainId,
        });
      }
      return;
    }
    if(!allowance || allowance.lte(0)) {
      handleApproveAllowance(selectedToken!);
      return;
    }
    setProcessing("Submitting");
    try {
      const amountWei = utils.parseUnits(amount.toString(), selectedToken?.decimals).toString();
      const transferResponse = await transferToken(
        library!,
        symbol, 
        amountWei,
        srcChainId,
        connectorType!,
        account!,
      );
      setCurrentAlert({ 
        severity: "info",
        message: "Your transaction is being processed.",
        chainId: srcChainId,
        txHash: transferResponse?.transactionHash,
      });
      setProcessing("");
    } catch(err:any) {
      setProcessing("");
      setCurrentAlert({ 
        severity: "error",
        message: err && err.message ? err.message : "Something went wrong, please check the console for more details.",
        chainId: srcChainId,
      });
    }
  };
  
  const validateAmount = async (amount: any) => {
    if (!selectedToken || srcBalance === undefined || srcBalance.balance === undefined) return undefined;
    let { balance } = srcBalance;
    if(isNaN(amount)) return "Invalid amount";
    if(balance < Number(amount)) return "Insufficient balance";
    if(
      (selectedToken.celer && Number(amount) <= selectedToken?.min!)
      ||
      (!selectedToken.celer && Number(amount) < selectedToken?.min!)
      ) {
      return `The transfer amount must be greater than ${selectedToken?.min!} ${selectedToken.symbol}`;
    }
    if(
      (selectedToken.celer && Number(amount) >= selectedToken?.max!)
      ||
      (!selectedToken.celer && Number(amount) > selectedToken?.max!)
      ) {
      return `The transfer amount cannot be greater than ${selectedToken?.max!} ${selectedToken.symbol}`;
    }
    return undefined;
  }

  const handleFlipChains = () => {
    if(dstToken?.unbridgeable && dstToken?.wrappableTo) {
      // token is unbridgable. Default to its wrapped version or something
      dstToken = Tokens.find(e => e.chainId === selectedToken?.bridgeToChain && e.symbol === dstToken?.wrappableTo);
    }
    setCurrentTransfer({ 
      step: undefined
    });
    setSrcChainId(selectedToken?.bridgeToChain!);
    setSelectedToken(dstToken);
    setCurrentAlert(undefined);
    handleCheckAllowance(dstToken!);
    setEstimatedAmount({
      amount: "",
      formatted: ""
    });
    setValue("amount","");
  };

  const handleTokenSelectorClose = () => {
    setTokenSelectorOpen(false);
  };

  const handleChainSelectorClose = () => {
    setChainSelectorOpen(false);
  }

  const handleChainSelect = (chainId) => {
    setCurrentChainId(chainId);
    const defaultToken = Tokens.find(token => token.chainId === chainId && token.default === true);
    setSelectedToken(
      defaultToken
    );
    setTimeout(() => {
      handleCheckAllowance(defaultToken!);
    });
    setEstimatedAmount({
      amount: "",
      formatted: ""
    });
    setValue("amount","");
  }

  const MaxButton = () => {
    if (srcBalance && srcBalance.balance) {
      let { balance } = srcBalance;
      if(srcToken && srcToken.max && balance > srcToken.max) {
        balance = srcToken.max;
      }
      return (
        <Button
          css={styles.accessoryButton} 
          disabled={!!processing}
          onClick={() => {
            setMaxAmountRequested(true);
            setValue("amount", balance.toString());
            trigger();
          }}
        >
          Max
        </Button>
      )
    } else {
      return null;
    }
  }

  const DropDownBalance = (params: { token: IToken }) => {
    const { symbol, chainId, format } = params.token;
    const data = useRecoilValueLoadable(balanceState({ chainId, symbol, account: account! }));
    const loaded = data.state === "hasValue";
    const balanceData: IBalance | undefined = loaded ? data.contents : undefined;
    let formattedBalance;
    if (loaded) {
      const balance = balanceData?.balance;
      formattedBalance = balance !== undefined ? numeral(balance).format(format) : "";          
    }
    return <Typography variant="body2">{formattedBalance}</Typography>
  }

  const SelectedTokenIcon = (params: { symbol: string }) => {
    let srcToken = Tokens.find(e => e.chainId === srcChainId && e.symbol === params.symbol!);
    if(!srcToken) {
      return null;
    }
    return <><img css={styles.selectedTokenIcon} src={srcToken?.icon} alt={`Icon of ${srcToken.symbol}`}/></>;
  }

  return (
    <>
      <Box css={styles.container}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box css={styles.source}>
            <Typography css={styles.header}>Bridge</Typography>
            {/*
              (srcChainId === SIDECHAIN_ID && dstToken) && (
                <AirDropNotification account={account!} token={dstToken}/>
              )
            */}
            <Box mb={1}>
              <Typography variant="caption" css={styles.chip}>From</Typography>
              <Box display="flex" alignItems="center">
                <Button
                  onClick={() => setChainSelectorOpen(true)}
                  variant="outlined"
                  css={[styles.tokenSelect,styles.chainSelect]} 
                  endIcon={<ArrowDropDown />}
                >
                  {srcChain.label}
                </Button>
              </Box>
            </Box>
            <Box css={styles.fields} display="flex">
              <Controller 
                name="amount"
                control={control}
                defaultValue=""
                rules={{
                  required: "Amount is required",
                  validate: validateAmount
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextField 
                      css={styles.amount}
                      placeholder="Amount"
                      disabled={!!processing}
                      error={!!error}
                      helperText={error ? error.message : " "}
                      {...field}
                      onChange={(e) => {
                        setMaxAmountRequested(false);
                        field.onChange(e);
                      }}
                    />
                  )
                }}
              />
              <Box display="flex" css={styles.controls} alignItems="center">
                <Button
                  onClick={() => setTokenSelectorOpen(true)}
                  variant="outlined"
                  css={styles.tokenSelect} 
                  endIcon={<ArrowDropDown />}
                >
                  <>
                    <SelectedTokenIcon symbol={selectedToken!?.symbol}/>
                    {selectedToken!?.symbol}
                  </>
                </Button>
              </Box>
            </Box>
            <Box display="flex" css={styles.balance}>
              {srcToken && 
                <Typography variant="body2" color="GrayText">
                  <span>Balance:</span> {srcToken.label}
                  <Suspense fallback={<Skeleton width={50} style={{display:"inline-block"}}/>}>
                    <TokenBalance account={account!} token={srcToken} />
                  </Suspense>
                </Typography>}
                <MaxButton />
            </Box>
            
          </Box>
          <Box display="flex" css={styles.flipBox}>
            <IconButton css={styles.flipButton} size="large" onClick={handleFlipChains} disabled={!!processing}>
              <SwapVert fontSize="large" />
            </IconButton>
          </Box>
          <Box css={styles.target}>
            <Box justifyContent="space-between" css={styles.targetBalance}>
              <Box>
                <Typography variant="caption" css={styles.chip}>To</Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Typography><strong>{ChainConfigs[selectedToken?.bridgeToChain!]?.chainName}</strong></Typography>
                </Box>
                <Box>
                  <Typography variant="body1" css={styles.estimatedAmount}>
                    {
                      fetchingEstimate ? <Skeleton width={40} style={{display:"inline-block"}}/> : estimatedAmount.formatted
                    }
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt={1}>
              {dstToken && <Typography variant="body2" color="GrayText">{`Balance: ${dstToken.label} `}
                <Suspense fallback={<Skeleton width={50} style={{display:"inline-block"}}/>}>
                  <TokenBalance account={account!} token={dstToken} />
                </Suspense>
              </Typography>}
            </Box>
            <Box style={{textAlign:"center"}} mt={3}>
              {
                bridgingEnabled ? (
                  <>
                    {
                      isWrongNetwork ? (
                        <Button css={styles.button} size="large" onClick={() => handleNetworkSwitch(srcChain.chainId)} variant="contained" disableElevation>
                          {`Switch to ${srcChain.label}`}
                        </Button>
                      ) : (
                        <>
                          {
                            ((selectedToken?.celer && selectedToken?.celerMode! > CelerBridgeMode.NATIVE_TOKEN) || selectedToken?.symbol === Symbols.SX) && (!allowance || allowance.lte(0)) ? (
                              <Button 
                                size="large" 
                                css={styles.button} 
                                type="submit" 
                                variant="contained" 
                                disableElevation 
                                disabled={
                                  !!processing
                                  ||
                                  (MAINCHAIN_ID === srcChainId && selectedToken!?.symbol === Symbols.SX)
                                }
                                >
                                  {
                                    processing ? (
                                      <Box display="flex" alignItems="center">
                                        <CircularProgress size={20} css={styles.spinner} />
                                        {processing}
                                      </Box>
                                    ) : (
                                      "Approve"
                                    )
                                  }
                              </Button>
                            ) : (
                              <Button 
                                size="large" 
                                css={styles.button} 
                                type="submit" 
                                variant="contained" 
                                disableElevation 
                                disabled={
                                  !!processing 
                                  || 
                                  (MAINCHAIN_ID === srcChainId && selectedToken!?.symbol === Symbols.SX)
                                  ||
                                  //estimatedAmount.amount <= 0
                                  //||
                                  fetchingEstimate
                                  ||
                                  !fastBridgeEnabled
                                }
                                >
                                  {
                                    processing ? (
                                      <Box display="flex" alignItems="center">
                                        <CircularProgress size={20} css={styles.spinner} />
                                        {processing}
                                      </Box>
                                    ) : (
                                      "Bridge"
                                    )
                                  }
                              </Button>
                            )
                          }
                          <Box style={{textAlign: "center", opacity: 0.6}} my={2}>
                            <Typography variant="caption">Estimated time to complete: <strong>5-10 min</strong>.</Typography>
                          </Box>
                        </>
                      )
                    }
                  </>
                ) : (
                  <Alert severity="error" style={{ marginTop: 15 }}>
                    Sorry, bridging is currently disabled for maintenance.
                  </Alert> 
                )
              }
            </Box>
            {currentStatus && <StatusAlert />}
            <TransferProgress />
          </Box>
        </form>
      </Box>
      {/* Token Selector */}
      <Dialog 
        onClose={handleTokenSelectorClose} 
        open={tokenSelectorOpen} 
        fullWidth={true}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" pr={2}>
          <DialogTitle>
            Choose a token from {srcChain.label}
          </DialogTitle>
          <IconButton size="small" onClick={handleTokenSelectorClose}><CloseRounded/></IconButton>
        </Box>
        <DialogContent>
          <MenuList>
            {[ 
              ...tokens.map((token,i) => (
                <MenuItem 
                  onClick={() => {
                    setTokenSelectorOpen(false);
                    setSelectedToken(token);
                    handleCheckAllowance(token!);
                  }}
                  divider={i < tokens.length - 1 && true} 
                  key={`token-${token.symbol}`} 
                  value={token.symbol}
                  >
                  <ListItemIcon>
                    <img css={styles.tokenIcon} src={token.icon} alt={token.symbol} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body2">{token.label}</Typography>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <DropDownBalance token={token} />
                  </ListItemSecondaryAction>
                </MenuItem>
              ))
            ]}
          </MenuList>
        </DialogContent>
      </Dialog>
      {/* Chain Selector */}
      <Dialog 
        onClose={handleChainSelectorClose} 
        open={chainSelectorOpen} 
        fullWidth={true}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" pr={2}>
          <DialogTitle>
            Choose a chain
          </DialogTitle>
          <IconButton size="small" onClick={handleChainSelectorClose}><CloseRounded/></IconButton>
        </Box>
        <DialogContent>
          <MenuList>
          {
            availableChains.map((chain,i) => {
              return (
                <MenuItem 
                  onClick={() => {
                    setChainSelectorOpen(false);
                    handleChainSelect(chain.chainId)
                  }}
                  divider={i < availableChains.length - 1 && true} 
                  key={`chain-${chain.chainId}`} 
                  value={chain.chainId}
                  >
                    <ListItemIcon>
                      <img css={styles.tokenIcon} src={chain.iconUrls} alt={chain.currency} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="body2">{chain.label}</Typography>
                    </ListItemText>
                    <ListItemSecondaryAction>
                    < Typography variant="body2">{chain.currency}</Typography>
                    </ListItemSecondaryAction>
                </MenuItem>
              )
            })
          }
          </MenuList>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default memo(Bridge);