/** @jsxImportSource @emotion/react */
import { Box, Tooltip, Typography } from "@mui/material";
import { FileCopy } from "@mui/icons-material";
import { copyStringToClipboard } from "../utils";
import { css } from "@emotion/react";
import { memo } from "react";
import { useSnackbar } from 'notistack';

export function getStyles() {
  return {
    account: css`
      background:#EAF2FF;
      border-radius:6px;
      padding:15px 15px 15px 15px;
      cursor: pointer;
    `,
    icon: css`
      font-size: 21px;
      margin-right: 10px;
    `
  }
}

function AddressControl(params: any) {

  // hooks
  const { enqueueSnackbar } = useSnackbar();
  const { address, onCopy } = params;
  const styles = getStyles();

  // methods
  const handleCopy = () => {
    enqueueSnackbar('Copied!');
    copyStringToClipboard(address, "copyAddress");
    onCopy();
  };

  return (
    <>
      <Tooltip title="Copy to clipboard" placement="top">
        <Box onClick={handleCopy} display="flex" alignItems="center" css={styles.account}>
          <FileCopy css={styles.icon} color="primary" />
          <Box overflow="hidden">
            <Typography variant="body2" color="textSecondary" id="copyAddress">
              {address}
            </Typography>
          </Box>
        </Box>
      </Tooltip>
    </>
  );
}

export default memo(AddressControl);
