import { Contract, providers } from "ethers";

let contracts: {
  [address: string]: Contract
} = {};

export function getContract(
  address: string,
  abi: any,
  provider: (providers.Provider | providers.JsonRpcSigner)
) {
  if (!contracts[address]) {
    contracts[address] = new Contract(address, abi, provider);
  }
  return contracts[address];
}

export function removeListeners() {
  for (let address in contracts) {
    const contract = contracts[address];
    contract.removeAllListeners();
  }
}
