import { atom } from "recoil";
import { ChainIds, IToken } from "../config";
import { Chains } from "../config/chains";

export interface IStatusState {
  severity: "error" | "success" | "info" | "warning";
  message: string;
  chainId?: ChainIds;
  txHash?: string;
  celer?: boolean;
  celerTxHash?: string
} 

export interface IModeParamsState {
  srcSymbol: string;
  dstSymbol: string;
  chainId: string;
}

export const currentAlertState = atom<IStatusState | undefined>({
  key: 'CurrentStatus',
  default: undefined
});

export const currentChainIdState = atom<number>({
  key: 'CurrentChainId',
  default: Chains[1].chainId
});

export const selectedTokenState = atom<IToken | undefined>({
  key: 'SelectedToken',
  default: undefined
});

export const modeParamsState = atom<IModeParamsState | undefined>({
  key: 'ModeParams',
  default: undefined
});

