/** @jsxImportSource @emotion/react */
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from '@ethersproject/providers';
import { Box, Button, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useRecoilValue, useRecoilValueLoadable, useRecoilState } from "recoil";
import numeral from "numeral";
import { currentChainIdState, selectedTokenState } from "../state";
import { Tokens } from "../config/tokens";
import { IToken, Symbols, Modes } from "../config";
import { ratesQuery } from "../state/rates";
import { balanceState } from "../state/balances";
import { Fragment } from "react";
import { useNavigate } from 'react-router-dom';
import getBalancesStyles from "../styles/BalancesStyles";
import { getConnectorType } from "../connectors/utils";
import { ConnectorTypes } from "../connectors";

function Balances() {

  // hooks
  const { account } = useWeb3React<Web3Provider>();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  const navigate = useNavigate();

  // styles
  const styles = getBalancesStyles({ theme }); 

  // recoil
  const [, setSelectedToken] = useRecoilState(selectedTokenState);
  const chainId = useRecoilValue(currentChainIdState);

  //const balancesData = useRecoilValueLoadable(balancesState({ chainId, account: account! }));
  const ratesData = useRecoilValueLoadable(ratesQuery);
  const ratesLoaded = ratesData.state === "hasValue";
  const rates = ratesLoaded ? ratesData.contents : undefined;

  // hooks
  const { connector } = useWeb3React<Web3Provider>();
  const connectorType = getConnectorType(connector); 

  // methods
  const handleRedirect = (mode, srcToken: IToken, dstSymbol?: Symbols) => {
    setSelectedToken(srcToken);
    const url = (mode === Modes.WRAP_UNWRAP) ? `/wrap/${srcToken.symbol}/${dstSymbol}` : `/`;
    setTimeout(() => {
      navigate(url);
    });
  }

  const TokenRow = (params: { token: IToken }) => {
    const { symbol, chainId, label, icon, format, wrappableTo, unwrappableTo, unbridgeable } = params.token;
    const data = useRecoilValueLoadable(balanceState({ chainId, symbol, account: account! }));
    const loaded = data.state === "hasValue";
    const balanceData = loaded ? data.contents : undefined;
    let formattedBalance;
    let formattedUsdBalance;
    if (loaded && ratesLoaded) {
      const balance = balanceData!.balance;
      const rate = rates![symbol]!;
      formattedBalance = balance !== undefined ? numeral(balance).format(format,Math.floor) : "";
      formattedUsdBalance = balance !== undefined ? numeral(balance * rate).format("$0,0.00") : "";          
    }
    return (
      <TableRow
        key={symbol}
      >
        <TableCell>
          <Box css={styles.centeredBox}>
            <img
              css={styles.tokenIcon}
              src={icon} 
              alt={label} 
            />
            {label}
          </Box>
        </TableCell>
        <TableCell align="right">
          {loaded
          ? <Fragment>
              <Typography variant="body2" css={styles.balance}>{formattedBalance}</Typography>
              {phone && <Typography variant="body2" css={styles.muted}>{formattedUsdBalance}</Typography>}
            </Fragment>
          : <Skeleton css={styles.skeleton} />}
        </TableCell>
        {!phone && <TableCell align="right">
          {loaded
            ? <Typography variant="body2" css={styles.muted}>{formattedUsdBalance}</Typography>
            : <Skeleton css={styles.skeleton} />
          }
        </TableCell>}
        <TableCell css={styles.actions}>
          <Box display="flex" css={styles.actionsBox}>
            {wrappableTo && 
              <Button size="small" css={styles.cellButton} onClick={() => handleRedirect(Modes.WRAP_UNWRAP, params.token, wrappableTo)}>
                Wrap
              </Button>}
            {unwrappableTo && 
              <Button size="small" css={styles.cellButton} onClick={() => handleRedirect(Modes.WRAP_UNWRAP, params.token, unwrappableTo)}>
                Unwrap
              </Button>}
            {((!unbridgeable) && (connectorType === ConnectorTypes.Magic || connectorType === ConnectorTypes.Injected)) && <Button size="small" css={styles.cellButton} onClick={() => handleRedirect(Modes.BRIDGE, params.token)}>
              Bridge
            </Button>}
          </Box>
        </TableCell>
      </TableRow>
    )  
  }
  
  return <div css={styles.container}>
    <TableContainer>
      {account && <Table size="small" css={styles.table}> 
        <TableHead>
          <TableRow>
            <TableCell>Asset</TableCell>
            <TableCell align="right">Balance</TableCell>
            {!phone && <TableCell align="right" css={styles.muted}>Value</TableCell>}
            <TableCell css={styles.actions}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Tokens.filter(e => e.chainId === chainId && e.address !== "").map(token => (
            <TokenRow key={`token-${token.chainId}-${token.symbol}`} token={token} />
          ))}
        </TableBody>
      </Table>}
    </TableContainer>
  </div>;
}

export default Balances;
