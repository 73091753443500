/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { useRecoilValue } from "recoil";
import { currentTransferState, currentTransferStepSelector, TransferProgressSteps } from "../state/bridge";

const TransferProgressLabels: { [key: number]: string} = {
  [TransferProgressSteps.APPROVING]: "Approving amount",
  [TransferProgressSteps.SUBMITTING]: "Submitting transfer",
  [TransferProgressSteps.TRANSFERRING]: "Transferring",
  [TransferProgressSteps.RECEIVING]: "Funds received"
}

export function getStyles() {
  return {
    container: css`
      margin-top:25px;
    `,
  }
}

function TransferProgress() {
  const styles = getStyles();
  const currentTransfer = useRecoilValue(currentTransferState);
  const currentTransferStep = useRecoilValue(currentTransferStepSelector);

  if (currentTransferStep === undefined || currentTransfer === undefined) return null;

  return (
    <Box css={styles.container}>
      <Stepper activeStep={currentTransferStep} alternativeLabel>
        {Object.keys(TransferProgressLabels).map((key: any) => {
          const labelProps: any = {};
          if (currentTransfer.error && Number(key) === currentTransferStep) {
            labelProps.error = true;
          }
          return (
            <Step key={key}>
              <StepLabel {...labelProps}>{TransferProgressLabels[key]}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </Box>
  );
}

export default TransferProgress;