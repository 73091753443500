import { ChainIds, ENV, Environments, IChain, Symbols } from "."
import { EthIcon, SxIcon, MaticIcon } from "../assets/tokens/index";

export const ChainsByEnv: {
  [env: string]: IChain[]
} = {
  [Environments.TESTNET]: [
    {
      chainId: ChainIds.TORONTO,
      label: "Toronto",
      rpcUrls: ["https://rpc.toronto.sx.technology/"],
      blockExplorer: "https://explorer.toronto.sx.technology",
      currency: Symbols.SX,
      iconUrls: SxIcon
    },
    {
      chainId: ChainIds.MUMBAI,
      label: "Mumbai",
      rpcUrls: [
        "https://polygon-mumbai.g.alchemy.com/v2/P2re3otl6bKMKambSAnNs5qfXZYLkfqg",
        "https://polygon-mumbai.infura.io/v3/36569ef5c93f44d08212ad662b1e863a",
        "https://rpc-mumbai.matic.today",
      ],
      blockExplorer: "https://mumbai.polygonscan.com/",
      currency: Symbols.MATIC,
      iconUrls: MaticIcon
    },
    {
      chainId: ChainIds.GOERLI,
      label: "Goerli",
      rpcUrls: [
        "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
      ],
      blockExplorer: "https://goerli.etherscan.io/",
      currency: Symbols.ETH,
      iconUrls: EthIcon
    },
    {
      chainId: ChainIds.POLYGON,
      label: "Polygon",
      rpcUrls: [
        "https://polygon-mainnet.g.alchemy.com/v2/o2e3oeyFHiHaaZmx1Dht3q9owK6cAWcd",
      ],
      blockExplorer: "https://polygonscan.com/",
      currency: Symbols.MATIC,
      iconUrls: MaticIcon
    }
  ],
  [Environments.MAINNET]: [
    {
      chainId: ChainIds.SX,
      label: "SX Mainnet",
      rpcUrls: ["https://rpc.sx.technology/"],
      blockExplorer: "https://explorer.sx.technology",
      currency: Symbols.SX,
      iconUrls: SxIcon
    },
    {
      chainId: ChainIds.POLYGON,
      label: "Polygon",
      rpcUrls: [
        "https://polygon-mainnet.g.alchemy.com/v2/o2e3oeyFHiHaaZmx1Dht3q9owK6cAWcd",
      ],
      blockExplorer: "https://polygonscan.com/",
      currency: Symbols.MATIC,
      iconUrls: MaticIcon
    },
    {
      chainId: ChainIds.ETHEREUM,
      label: "Ethereum",
      rpcUrls: [
        "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
      ],
      blockExplorer: "https://etherscan.io/",
      currency: Symbols.ETH,
      iconUrls: EthIcon
    },
  ],
  [Environments.DEV]: [

  ]
}

export const ChainConfigs: {
  [chainId: number]: any
} = {
  [ChainIds.TORONTO]: {
    chainId: "0x287",
    chainName: "Toronto",
    rpcUrls: ["https://rpc.toronto.sx.technology/"],
    nativeCurrency: {
      name: "SX Token",
      symbol: "SX",
      decimals: 18
    },
    blockExplorerUrls: ["https://explorer.toronto.sx.technology/"],
  },
  [ChainIds.MUMBAI]: {
    chainId: "0x13881",
    chainName: "Mumbai",
    rpcUrls: ["https://rpc-mumbai.matic.today"],
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18
    },
    blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
  },
  [ChainIds.GOERLI]: {
    chainId: "0x5",
    chainName: "Goerli",
    rpcUrls: ["https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18
    },
    blockExplorerUrls: ["https://goerli.etherscan.io/"],
    icon: EthIcon
  },
  [ChainIds.POLYGON]: {
    chainId: "0x89",
    chainName: "Polygon",
    rpcUrls: ["https://polygon-rpc.com/"],
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18
    },
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
  [ChainIds.SX]: {
    chainId: "0x1A0",
    chainName: "SX Network",
    rpcUrls: ["https://rpc.sx.technology/"],
    nativeCurrency: {
      name: "SX Token",
      symbol: "SX",
      decimals: 18
    },
    blockExplorerUrls: ["https://explorer.sx.technology/"],
  },
  [ChainIds.ETHEREUM]: {
    chainId: "0x1",
    chainName: "Ethereum",
    rpcUrls: ["https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18
    },
    blockExplorerUrls: ["https://etherscan.io/"],
    //icon: EthIcon
  },
}

export const Chains = ChainsByEnv[ENV];
