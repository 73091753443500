import { css } from "@emotion/react";

export default function getWalletViewStyles(props?: any) {
    const { theme } = props;
    const phone = theme.breakpoints.down("sm");
    return {
      global: css`paper
        body, html {
          padding: 0;
          margin: 0;
          ${phone} {
            background: rgb(144,199,250);
            background: linear-gradient(90deg, rgba(144,199,250,0.2) 0%, rgba(129,255,221,0.2) 100%);
          }
        }
      `,
      header: css`
        height:60px;
        //border:1px solid green;
        padding-left:15px;
        padding-right:15px;
      `,
      body: css`
        min-height:calc(100% - 110px);
        max-height:calc(100% - 110px);
        overflow-y:auto;
        //border:1px solid red;
      `,
      footer: css`
        height:50px;
        //border:1px solid orange;
        padding-left:15px;
        padding-right:15px;
      `,
      container: css`
        height:100%;
        padding-left:0px !important;
        padding-right:0px !important;
      `,
      paper: css`
        //border: 1px solid #fff;
        border-radius: 10px;
        position: relative;
        width:100%;
        z-index:1;
        &::before {
          top:-0;
          left:-0;
          content: "";
          width: 70%;
          height: 100%;
          background: rgba(35, 107, 215, 0.8);
          filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.01)) blur(50px);
          position: absolute;
          z-index:-1;
          ${phone} {
            display:none;
          }
        },
        &::after {
          z-index:-1;
          bottom:-0;
          right:-0;
          content: "";
          width: 70%;
          height: 100%;
          background: rgba(95, 214, 173, 0.8);
          filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.01)) blur(50px);
          position: absolute;
          ${phone} {
            display:none;
          }
        }
      `,
      wallet: css`
        background:#fff;
        border-radius: 10px;
      `,
      menuBox: css`
        min-height: 35px;
      `,
      title: css`
        margin-top: 30px;
        margin-bottom: 20px;
        ${phone} {
          padding-left: 10px;
        }
      `,
      networkLabel: css`
      `,
      alert: css`
        margin:0 20px 0 20px;
      `,
      version: css`
        color: #a2afb9;
        margin-left:10px;
      `,
      block: css`
        color: #a2afb9;
      `,
      discordIcon: css`
        opacity: 0.4;
        margin-right: 5px;
        margin-left: 11px;
        width: 14px;
      `,
      twitterIcon: css`
        opacity: 0.4;
        margin-left: 5px;
        width: 15px;
        color: #333;
        position: relative;
        top:1px;
      `,
      blockIcon: css `
        opacity: 0.5;
        margin-right: 5px;
        margin-left: -5px;
        width: 20px;
      `,
      logo: css`
        height: 28px;
        display: block;
        ${phone} {
        } 
      `,
      email: css`
        width: 100%;
        margin-top: 30px;
      `,
      spinner: css`
        position: absolute;
        top: 50%; 
        left: 50%;
        transform: translate(-50%, -50%);
        svg {
          color: grey;
        }
      `
    }
  }