import { css } from "@emotion/react";

export default function getBalancesStyles(props?: any) {
  const { theme } = props;
  const phone = theme.breakpoints.down("sm");

  return {
    table: css`
      & tbody tr:last-child td { // , & thead tr:last-child th
        border:0
      }
    `,
    container: css`
      padding: 0 15px 15px 15px;
      ${phone} {
        padding: 0 0 15px 0;
      }
    `,
    tokenIcon: css`
      height: 30px;
      margin-right: 10px;
      border-radius:50%;
      ${phone} {
        margin-right: 10px;
        height: 35px;
      }
    `,
    skeleton: css`
      display: inline-block;
      width: 50px;
    `,
    centeredBox: css`
      display: flex;
      align-items: center;
      min-height: 35px;
    `,
    balance: css`
      color: black;
    `,
    muted: css`
      color: darkgrey;
    `,
    cellButton: css`
      min-width: unset;
      margin-left: 15px;
      margin-right:-5px;
    `,
    actions: css`
      padding-right: 30px;
      text-align: right;
      ${phone} {
        padding-right: 15px;
        text-align: right;
      }
    `,
    actionsBox: css`
      flex-direction: row;
      justify-content: flex-end;
      ${phone} {
        justify-content: unset;
        flex-direction: column;
        align-items: flex-end;
      } 
    `
  }
}