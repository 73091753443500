import BrowserWalletIcon from "../assets/browser-wallet.svg";
import MagicIcon from "../assets/magic.gif";
import { InjectedConnector } from '@web3-react/injected-connector';
import { ChainIds, ETHEREUM_ID, MAGIC_API_KEY, MAGIC_ETHEREUM_RPC_URL, MAGIC_MAINCHAIN_RPC_URL, MAGIC_SIDECHAIN_RPC_URL, MAINCHAIN_ID, SIDECHAIN_ID } from '../config';
import { MagicConnector } from './magic';
import { providers } from "ethers/lib/ethers";
import { Magic } from "magic-sdk";

let connectors: any = {};

export enum ConnectorTypes {
  Injected = 'InjectedConnector',
  Magic = 'MagicConnector',
}

export const ConnectorOptions = [
  {
    type: ConnectorTypes.Injected,
    description: "Connect using browser wallet",
    label: "MetaMask",
    icon: BrowserWalletIcon
  },
  {
    type: ConnectorTypes.Magic,
    description: "Connect using Magic wallet",
    label: "Magic",
    icon: MagicIcon
  },
];

export function getConnector(type: ConnectorTypes) {
  if (!connectors[type]) {
    switch (type) {
      case ConnectorTypes.Injected:
        connectors[type] = new InjectedConnector({ 
          supportedChainIds: Object.values(ChainIds) as any
        });
        break;
      case ConnectorTypes.Magic:
        connectors[type] = new MagicConnector({
          apiKey: MAGIC_API_KEY!,
          chainId: SIDECHAIN_ID, // MAINCHAIN_ID
          rpcUrl: MAGIC_SIDECHAIN_RPC_URL! // MAGIC_MAINCHAIN_RPC_URL!
        });  
        break;
    }          
  }
  return connectors[type];
}

export function getMagicMainchainProvider() {
  let provider: providers.Web3Provider;
  const magic = new Magic(MAGIC_API_KEY!, {
    network: {
      rpcUrl: MAGIC_MAINCHAIN_RPC_URL!,
      chainId: MAINCHAIN_ID
    }
  });
  provider = new providers.Web3Provider(magic.rpcProvider as any);
  return provider;
}

export function getMagicEthereumProvider() {
  let provider: providers.Web3Provider;
  const magic = new Magic(MAGIC_API_KEY!, {
    network: {
      rpcUrl: MAGIC_ETHEREUM_RPC_URL!,
      chainId: ETHEREUM_ID
    }
  });
  provider = new providers.Web3Provider(magic.rpcProvider as any);
  return provider;
}

export function getMagicSidechainProvider() {
  let provider: providers.Web3Provider;
  const magic = new Magic(MAGIC_API_KEY!, {
    network: {
      rpcUrl: MAGIC_SIDECHAIN_RPC_URL!,
      chainId: SIDECHAIN_ID
    }
  });
  provider = new providers.Web3Provider(magic.rpcProvider as any);
  return provider;
}


