/** @jsxImportSource @emotion/react */

import { Typography, useTheme } from "@mui/material";
import BlockNumber from "./BlockNumber";
import { VERSION } from "../config";
import BlockIcon from "../assets/block.svg";
import DiscordIcon from "../assets/discord.svg";
import getWalletViewStyles from "../styles/WalletViewStyles";
import Twitter from '@mui/icons-material/Twitter';

function Footer() {

  // hooks
  const theme = useTheme();

  // style
  const styles = getWalletViewStyles({ theme });

  return (
    <>
      <img src={BlockIcon} css={styles.blockIcon} alt="Block Icon"/>
      <Typography variant="caption" css={styles.block}><BlockNumber/></Typography>
      <Typography variant="caption" css={styles.version}>Built in Toronto (v{VERSION})</Typography>
      <a href="https://discord.gg/zms7WnKpac" target="_blank" rel="noreferrer">
        <img src={DiscordIcon} css={styles.discordIcon} alt="Discord"/>
      </a>
      <a href="https://twitter.com/SX_Network" target="_blank" rel="noreferrer">
        <Twitter css={styles.twitterIcon}/>
      </a>
    </>
  )
}

export default Footer;
