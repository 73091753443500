/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, memo } from "react";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from '@ethersproject/providers';
import ChainSelector from "./ChainSelector";
import Balances from "./Balances";

export function getStyles() {
  return {
    button: css`
      margin-right: 10px;
      text-transform: none;
      color: #000;
    `,
  }
}

function AssetsList() {

  // hooks
  const context = useWeb3React<Web3Provider>();
  const { active } = context;

  return (
    <Fragment>
      <ChainSelector active={active} />
      <Balances />
    </Fragment>
  );

}

export default memo(AssetsList);
