import { css } from "@emotion/react";

export default function getUserMenuStyles(props?: any) {
  return {
    menuLink: css`
      text-decoration:none;
      &.active {
        & button {
          font-weight:600;
        }
      }
    `,
    menuButton: css`
      min-width: 40px;
      margin-right: 10px;
      color: #666;
    `,
    button: css`
      margin-right: 10px;
      text-transform: none;
      color: #000;
    `,
    menu: css`
      & .MuiPaper-root {
        min-width: 350px;
        max-height: 500px;
      }
    `,
    transfer: css`
      width: 100%;
      padding-top: 10px;
    `,
    tokenIcon: css`
      height: 40px;
      margin-right: 15px;
      border-radius: 50%;
    `,
    spinner: css`
      color: #a5a5a5;
    `,
    spinnerBox: css`
      padding: 10px;
      text-align: center;
    `,
    link: css`
      text-decoration: none;
      color: #414141;
    `,
    amount: css`
      font-weight: 600;
      font-size: 15px;
    `,
    linkBox: css`
      margin-left: 5px;
      margin-top: -10px;
    `,
    explorerIcon: css`
      color: #a5a5a5;     
    `,
    direction: css`
      p {
        font-size: 14px;
      }
    `,
    status: css`
      p {
        font-size: 10px;
      }      
    `,
    drawer: css `
      width: 260px;
      padding:15px;
      & a {
        display: block;
        & button {
          width:100%;
          font-size:18px;
          text-align: left;
        }
      }
      & button {
        display: block;
        width: 100%;
        font-size:18px;
        text-align: left;
      }
    `,
    chip: css `
      font-weight:600;
      font-size:11px;
      position:relative;
      top:-2px;
      height:auto;
      //margin-top:-1px;
      border-radius:3px;
      & span {
        padding-left:5px;
        padding-right:5px;
      },
    `,
    in: css `
      background:#3254d9;
      color:#fff; 
    `,
    out: css `
      background: #8247E5;
      color:#fff;
    `,
    refundChip: css `
      background: #E74C3C;
      color:#fff;  
    `,
    refund: css `
      background: #E74C3C !important;
      color:#fff !important;
      :disabled {
        opacity:0.8
      };
      margin-right:5px;
      margin-left:10px;
    `
  }
}