import { useEffect, memo } from "react";
import { useRecoilCallback } from "recoil";
import { MAINCHAIN_ID, SIDECHAIN_ID } from "../config";
import { fetchTransfers, fetchTransfersFromGraph, fetchTransfersFromCBridge, fetchTransfersFromRelayer } from "../services/bridge";
import { transfersState } from "../state/transfers";
import _ from "lodash";

function HistoryFetcher(params: { account: string }) {
  const { account } = params;
  const refreshHistory = useRecoilCallback(({ set }) => async () => {
    if (account) {
      const sidechain_in = await fetchTransfers(account, SIDECHAIN_ID, "success");
      const sidechain_out = await fetchTransfers(account, MAINCHAIN_ID, "pending");
      const mainchain_in = await fetchTransfersFromGraph(account, MAINCHAIN_ID, "success");
      const mainchain_out = await fetchTransfersFromGraph(account, SIDECHAIN_ID, "pending");
      const cbridge = await fetchTransfersFromCBridge(account);
      const fast_deposist = await fetchTransfersFromRelayer(account);
      let history = (sidechain_in || [])
        .concat(mainchain_in || [])
        .concat(mainchain_out || [])
        .concat(sidechain_out || [])
        .concat(cbridge || [])
        .concat(fast_deposist || []);
      history = _.orderBy(history, ['timestamp'], ['desc']);
      if (sidechain_in || sidechain_out || mainchain_in || mainchain_out || cbridge || fast_deposist) {
        set(
          transfersState, 
          history
        );
      }
    }
  }, [account]);

  useEffect(() => {
    let intervalId: any;
    if (account) {
      refreshHistory();
      intervalId = setInterval(refreshHistory, 10000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
  }, [account, refreshHistory]);
  return null;
}

export default memo(HistoryFetcher);
