import { selector } from "recoil";
import { getFeatureFlags } from "../services/featureFlags";

export const featureFlagsQuery = selector<any>({
  key: 'FeatureFlagsQuery',
  get: async () => {
    const featureFlags = await getFeatureFlags();
    return featureFlags;
  }
});

