import { providers } from "ethers";
import { ChainIds } from "../config";
import { Chains } from "../config/chains";

let rpcProviders: {
  [chainId: number]: providers.FallbackProvider;
} = {};

export function getRpcProvider(
  chainId: ChainIds
): providers.FallbackProvider {
  if (!rpcProviders[chainId]) {
    const { rpcUrls } = Chains.find(e => e.chainId === chainId)!;
    let chainProviders: providers.StaticJsonRpcProvider[] = [];
    for (let url of rpcUrls) {
      if (url) {
        chainProviders.push(new providers.StaticJsonRpcProvider(url, chainId));
      }
    }
    const chainProvidersWithPriority = chainProviders.map((provider, i) => ({
      provider,
      priority: i + 1
    })); 
    rpcProviders[chainId] = new providers.FallbackProvider(
      chainProvidersWithPriority, 1
    );
  }
  return rpcProviders[chainId]!;
}
