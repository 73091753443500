/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Paper, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import { memo } from "react";
import { ConnectorOptions } from "../connectors";

function getStyles() {
  return {
    container: css`
      padding-top:50px;
      padding-bottom:50px;
    `,
    table: css`
      & tbody tr:last-child td { // , & thead tr:last-child th
        border:0
      }
    `,
    row: css`
      cursor: pointer;
      height: 80px;
    `,
    icon: css`
      height: 55px;
      margin-left: 0px;
    `,
  }
}

function ConnectorPane(params: { onConnect: any }) {
  const { onConnect } = params;
  const styles = getStyles();
  return (
    <Grid container justifyContent="center" css={styles.container}>
      <Grid item sm={8} xs={12}>
        <TableContainer component={Paper}>
          <Table size="small" css={styles.table}>
            <TableBody>
              {ConnectorOptions.map(option => (
                <TableRow 
                  css={styles.row} 
                  onClick={() => onConnect(option.type)} 
                  key={`connector-${option.type}`}
                >
                  <TableCell>
                    <img css={styles.icon} src={option.icon} alt={option.type} />
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" fontSize={18}>{option.label}</Typography>
                    <Typography variant="body2" color="GrayText">{option.description}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <ArrowForward fontSize="medium" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default memo(ConnectorPane);
