import { MAINCHAIN_ID } from "../config";
import { useEffect, useState } from "react";
import { getRpcProvider } from "../services/providers";

function BlockNumer() {

  // state
  const [block, setBlock] = useState<string | number>("-----");

  // lifecycle
  useEffect(() => {
    getBlock();
  },[]);

  // methods
  const getBlock = async () => {
    const provider1 = getRpcProvider(MAINCHAIN_ID);
    const block = await provider1.getBlockNumber();
    setBlock(block);
  }

  return (
    <>
      {block}
    </>
  )
}

export default BlockNumer;
