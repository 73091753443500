import { ADDRESSES } from "@sharkswap/sharkswap-config";
import { ChainIds, ENV, Environments, IToken, Symbols, CelerBridgeMode, ContractNames } from ".";
import { ERC20PresetMinterPauser, ChildERC20, UChildERC20, WETH, WMATIC, WSX, USDC_Polygon } from "../abi/index";
import { EthIcon, UsdcIcon, SxIcon, MaticIcon, MaiIcon, QiDaoIcon, BiFiIcon, AxIcon, ImxIcon, LfiIcon, FlxIcon, PlotIcon, RageIcon, UmaIcon, XpbIcon } from "../assets/tokens/index";

const TokensByEnv: {
  [env: string]: IToken[]
} = {
  [Environments.TESTNET]: [
    {
      symbol: Symbols.ETH,
      chainId: ChainIds.GOERLI,
      label: "ETH",
      icon: EthIcon,
      decimals: 18,
      format: "0,0.0000",
      wrappableTo: Symbols.WETH,
      unbridgeable: true
    },
    {
      symbol: Symbols.MATIC,
      chainId: ChainIds.MUMBAI,
      label: "MATIC",
      icon: MaticIcon,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      celerMode: CelerBridgeMode.NATIVE_TOKEN,
      bridgeToChain: ChainIds.TORONTO,
      bridgeToToken: Symbols.MATIC,
      min: 1,
      max: 100,
      contract: ContractNames.CBRIDGE
    },
    {
      symbol: Symbols.SX,
      chainId: ChainIds.TORONTO,
      label: "SX",
      icon: SxIcon,
      decimals: 18,
      format: "0,0.00",
      default: true,
      unbridgeable: true,
      bridgeToChain: ChainIds.MUMBAI,
      bridgeToToken: Symbols.SX,
      wrappableTo: Symbols.WSX,
    },
    {
      symbol: Symbols.WSX,
      chainId: ChainIds.TORONTO,
      label: "WSX",
      icon: SxIcon,
      address: ADDRESSES[ChainIds.TORONTO].WSX,
      abi: WSX.abi,
      decimals: 18,
      format: "0,0.00",
      unbridgeable: true,
      unwrappableTo: Symbols.SX
    },
    {
      symbol: Symbols.WETH,
      chainId: ChainIds.GOERLI,
      label: "WETH",
      icon: EthIcon,
      address: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
      abi: WETH,
      decimals: 18,
      format: "0,0.0000",
      max: 10,
      min: 0.01,
      celer: true,
      default: true,
      cellerPegged: true,
      celerMode: 1,
      bridgeToChain: ChainIds.TORONTO,
      bridgeToToken: Symbols.WETH,
      contract: ContractNames.CBRIDGE
    },
    {
      symbol: Symbols.WETH,
      chainId: ChainIds.MUMBAI,
      label: "WETH",
      icon: EthIcon,
      address: ADDRESSES[ChainIds.MUMBAI].WETH,
      abi: ChildERC20.abi,
      decimals: 18,
      format: "0,0.0000",
      max: 1,
      min: 0.1,
      celer: false,
      default: false,
      bridgeToChain: ChainIds.TORONTO,
      bridgeToToken: Symbols.WETH,
      fastDeposit: true
    },
    {
      symbol: Symbols.SX,
      chainId: ChainIds.MUMBAI,
      label: "SX",
      icon: SxIcon,
      address: ADDRESSES[ChainIds.MUMBAI].SX,
      abi: ChildERC20.abi,
      decimals: 18,
      format: "0,0.00",
      resourceId: "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
      min: 5,
      max: 200,
      default: true,
      bridgeToChain: ChainIds.TORONTO,
      bridgeToToken: Symbols.SX,
      contract: ContractNames.ERC20_HANDLER
    },
    {
      symbol: Symbols.MATIC,
      chainId: ChainIds.TORONTO,
      label: "MATIC",
      icon: MaticIcon,
      address: ADDRESSES[ChainIds.TORONTO].WMATIC,
      abi: WMATIC.abi,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      celerMode: 2,
      bridgeToChain: ChainIds.MUMBAI,
      bridgeToToken: Symbols.MATIC,
      contract: ContractNames.CBRIDGE
    },
    { 
      symbol: Symbols.WETH,
      chainId: ChainIds.TORONTO,
      label: "WETH",
      icon: EthIcon,
      address: "0x81ECac0D6Be0550A00FF064a4f9dd2400585FE9c", //ADDRESSES[ChainIds.TORONTO].WETH,
      abi: WETH,
      decimals: 18,
      format: "0,0.0000",
      bridgeToChain: ChainIds.GOERLI,
      bridgeToToken: Symbols.ETH,
      celer: true,
      cellerPegged: true,
      celerMode: 2,
      //unbridgeable: true,
      contract: ContractNames.CBRIDGE
    },
    { 
      symbol: Symbols.USDC,
      chainId: ChainIds.TORONTO,
      label: "USDC",
      icon: UsdcIcon,
      address: "0x6a2d262D56735DbA19Dd70682B39F6bE9a931D98", //ADDRESSES[ChainIds.TORONTO].USDC,
      abi: UChildERC20.abi,
      decimals: 6,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      celerMode: 2,
      bridgeToChain: ChainIds.GOERLI,
      bridgeToToken: Symbols.USDC,
      contract: ContractNames.CBRIDGE,
      //unbridgeable: true
    },
    { 
      symbol: Symbols.USDC,
      chainId: ChainIds.GOERLI,
      label: "USDC",
      icon: UsdcIcon,
      address: "0xcbe56b00d173a26a5978ce90db2e33622fd95a28",
      abi: ERC20PresetMinterPauser.abi,
      decimals: 6,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      celerMode: 1,
      bridgeToChain: ChainIds.TORONTO,
      bridgeToToken: Symbols.USDC,
      min: 5,
      max: 150000,
      contract: ContractNames.CBRIDGE,
    },
    { 
      symbol: Symbols.USDC,
      chainId: ChainIds.MUMBAI,
      label: "USDC",
      icon: UsdcIcon,
      address: ADDRESSES[ChainIds.MUMBAI].USDC,
      abi: ChildERC20.abi,
      decimals: 6,
      format: "0,0.00",
      celer: false,
      bridgeToChain: ChainIds.TORONTO,
      bridgeToToken: Symbols.USDC,
      fastDeposit: true,
      min: 5,
      max: 1000,
    }
  ],
  [Environments.MAINNET]: [
    // Polygon Matic to SX Matic
    {
      symbol: Symbols.MATIC,
      chainId: ChainIds.POLYGON,
      label: "MATIC",
      icon: MaticIcon,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.NATIVE_TOKEN,
      bridgeToChain: ChainIds.SX,
      bridgeToToken: Symbols.MATIC,
      min: 10,
      max: 100000000,
      contract: ContractNames.CBRIDGE
    },
    // SX Matic to Polygon Matic
    {
      symbol: Symbols.MATIC,
      chainId: ChainIds.SX,
      label: "MATIC",
      icon: MaticIcon,
      address: "0xfa6f64dfbad14e6883321c2f756f5b22ff658f9c",
      abi: WMATIC.abi,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.SX_NETWORK_TOKEN,
      bridgeToChain: ChainIds.POLYGON,
      bridgeToToken: Symbols.MATIC,
      min: 10,
      max: 100000000,
      contract: ContractNames.CBRIDGE
    },
    // Polygon SX to SX
    {
      symbol: Symbols.SX,
      chainId: ChainIds.POLYGON,
      label: "SX",
      icon: SxIcon,
      address: ADDRESSES[ChainIds.POLYGON].SX,
      abi: ChildERC20.abi,
      decimals: 18,
      format: "0,0.00",
      resourceId: "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
      default: true,
      unbridgeable: false,
      bridgeToChain: ChainIds.SX,
      bridgeToToken: Symbols.SX,
      min: 5,
      max: 100000000,
      contract: ContractNames.ERC20_HANDLER
    },
    // SX USDC to Ethereum USDC
    { 
      symbol: Symbols.USDC,
      chainId: ChainIds.SX,
      label: "USDC",
      icon: UsdcIcon,
      address: "0xe2aa35C2039Bd0Ff196A6Ef99523CC0D3972ae3e",
      abi: ERC20PresetMinterPauser.abi,
      decimals: 6,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.SX_NETWORK_TOKEN,
      bridgeToChain: ChainIds.ETHEREUM,
      bridgeToToken: Symbols.USDC,
      min: 5,
      max: 100000000,
      contract: ContractNames.CBRIDGE
    },
    // Ethereum USDC to SX USDC
    { 
      symbol: Symbols.USDC,
      chainId: ChainIds.ETHEREUM,
      label: "USDC",
      icon: UsdcIcon,
      address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      abi: ERC20PresetMinterPauser.abi,
      decimals: 6,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.OTHER_TOKEN,
      bridgeToChain: ChainIds.SX,
      bridgeToToken: Symbols.USDC,
      min: 5,
      max: 100000000,
      contract: ContractNames.CBRIDGE
    },
    // Ethereum WETH to SX WETH
    {
      symbol: Symbols.WETH,
      chainId: ChainIds.ETHEREUM,
      label: "WETH",
      icon: EthIcon,
      address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      abi: WETH,
      decimals: 18,
      format: "0,0.0000",
      celer: true,
      default: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.OTHER_TOKEN,
      bridgeToChain: ChainIds.SX,
      bridgeToToken: Symbols.WETH,
      min: 0.01,
      max: 1000,
      contract: ContractNames.CBRIDGE_CUSTOM
    },
    // SX WETH to Ethereum ETH
    { 
      symbol: Symbols.WETH,
      chainId: ChainIds.SX,
      label: "WETH",
      icon: EthIcon,
      address: "0xA173954Cc4b1810C0dBdb007522ADbC182DaB380",
      abi: WETH,
      decimals: 18,
      format: "0,0.0000",
      bridgeToChain: ChainIds.ETHEREUM,
      bridgeToToken: Symbols.ETH,
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.SX_NETWORK_TOKEN,
      min: 0.01,
      max: 1000,
      contract: ContractNames.CBRIDGE
    },
    // Native ETH to SX WETH
    {
      symbol: Symbols.ETH,
      chainId: ChainIds.ETHEREUM,
      label: "ETH",
      icon: EthIcon,
      decimals: 18,
      format: "0,0.0000",
      wrappableTo: Symbols.WETH,
      unbridgeable: true,
    },
    // SX to Polygon SX (unavailable)
    {
      symbol: Symbols.SX,
      chainId: ChainIds.SX,
      label: "SX",
      icon: SxIcon,
      decimals: 18,
      format: "0,0.00",
      resourceId: "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
      default: true,
      unbridgeable: false,
      bridgeToChain: ChainIds.POLYGON,
      bridgeToToken: Symbols.SX,
      min: 5,
      max: 100000000,
      contract: ContractNames.ERC20_HANDLER
    },
    // Polygon MiMATIC to MiMatic
    { 
      symbol: Symbols.MAI,
      chainId: ChainIds.POLYGON,
      label: "MiMATIC",
      icon: MaiIcon,
      address: "0xa3Fa99A148fA48D14Ed51d610c367C61876997F1",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.OTHER_TOKEN,
      bridgeToChain: ChainIds.SX,
      bridgeToToken: Symbols.MAI,
      min: 20,
      max: 100000000,
      contract: ContractNames.CBRIDGE
    },
    // SX MiMATIC to Polygon MiMatic
    { 
      symbol: Symbols.MAI,
      chainId: ChainIds.SX,
      label: "MiMATIC",
      icon: MaiIcon,
      address: "0xF9AbB1Ef0dAb68cEdf1acbD6859510B0af4ca1d5",
      abi: ChildERC20.abi,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.SX_NETWORK_TOKEN,
      bridgeToChain: ChainIds.POLYGON,
      bridgeToToken: Symbols.MAI,
      min: 20,
      max: 100000000,
      contract: ContractNames.CBRIDGE
    },
    // Polygon QiDao to SX QiDao
    { 
      symbol: Symbols.QI,
      chainId: ChainIds.POLYGON,
      label: "QiDao",
      icon: QiDaoIcon,
      address: "0x580A84C73811E1839F75d86d75d88cCa0c241fF4",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.OTHER_TOKEN,
      bridgeToChain: ChainIds.SX,
      bridgeToToken: Symbols.QI,
      min: 100,
      max: 100000000,
      contract: ContractNames.CBRIDGE_CUSTOM
    },
    // SX QiDao to Polygon QiDao
    { 
      symbol: Symbols.QI,
      chainId: ChainIds.SX,
      label: "QiDao",
      icon: QiDaoIcon,
      address: "0x541b1155e2cb9a13e6E1bBCA8084E30E7C756d82",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.SX_NETWORK_TOKEN,
      bridgeToChain: ChainIds.POLYGON,
      bridgeToToken: Symbols.QI,
      min: 100,
      max: 100000000,
      contract: ContractNames.CBRIDGE_CUSTOM
    },
    // Polygon BiFi to SX BiFi
    { 
      symbol: Symbols.BIFI,
      chainId: ChainIds.POLYGON,
      label: "BiFi",
      icon: BiFiIcon,
      address: "0xFbdd194376de19a88118e84E279b977f165d01b8",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.00000",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.OTHER_TOKEN,
      bridgeToChain: ChainIds.SX,
      bridgeToToken: Symbols.BIFI,
      min: 0.03,
      max: 100000000,
      contract: ContractNames.CBRIDGE_CUSTOM
    },
    // SX BiFi to Polygon BiFi
    { 
      symbol: Symbols.BIFI,
      chainId: ChainIds.SX,
      label: "BiFi",
      icon: BiFiIcon,
      address: "0x98f529E6d8616B95a79a3600413A10BfFAc46Fba",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.00000",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.SX_NETWORK_TOKEN,
      bridgeToChain: ChainIds.POLYGON,
      bridgeToToken: Symbols.BIFI,
      min: 0.05,
      max: 100000000,
      contract: ContractNames.CBRIDGE_CUSTOM
    },
    // Polygon AthleteX to SX AthleteX
    { 
      symbol: Symbols.AX,
      chainId: ChainIds.POLYGON,
      label: "AthleteX",
      icon: AxIcon,
      address: "0x5617604BA0a30E0ff1d2163aB94E50d8b6D0B0Df",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.OTHER_TOKEN,
      bridgeToChain: ChainIds.SX,
      bridgeToToken: Symbols.AX,
      min: 1500,
      max: 100000000,
      contract: ContractNames.CBRIDGE_CUSTOM
    },
    // SX AthleteX to Polygon AthelteX
    { 
      symbol: Symbols.AX,
      chainId: ChainIds.SX,
      label: "AthleteX",
      icon: AxIcon,
      address: "0xd9Fd6e207a2196e1C3FEd919fCFE91482f705909",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.SX_NETWORK_TOKEN,
      bridgeToChain: ChainIds.POLYGON,
      bridgeToToken: Symbols.AX,
      min: 1500,
      max: 100000000,
      contract: ContractNames.CBRIDGE_CUSTOM
    },
    // Ethereum IMX to SX IMX
    /*
    { 
      symbol: Symbols.IMX,
      chainId: ChainIds.ETHEREUM,
      label: "Impermax",
      icon: ImxIcon,
      address: "0x7b35Ce522CB72e4077BaeB96Cb923A5529764a00",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.OTHER_TOKEN,
      bridgeToChain: ChainIds.SX,
      bridgeToToken: Symbols.IMX,
      min: 500,
      max: 1000000,
      contract: ContractNames.CBRIDGE
    },
    // SX IMX to Ethereum IMX
    { 
      symbol: Symbols.IMX,
      chainId: ChainIds.SX,
      label: "Impermax",
      icon: ImxIcon,
      address: "0x87557701E99Dc347De11441cF29be191F767E71E",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.SX_NETWORK_TOKEN,
      bridgeToChain: ChainIds.ETHEREUM,
      bridgeToToken: Symbols.IMX,
      min: 500,
      max: 1000000,
      contract: ContractNames.CBRIDGE
    },
    */
    // Ethereum LFI to SX Ethereum
    { 
      symbol: Symbols.LFI,
      chainId: ChainIds.ETHEREUM,
      label: "LunaFi",
      icon: LfiIcon,
      address: "0xeE1edD77f16b7867e026a4d32ef45DaC852DA61F",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.OTHER_TOKEN,
      bridgeToChain: ChainIds.SX,
      bridgeToToken: Symbols.LFI,
      min: 1000,
      max: 100000000,
      contract: ContractNames.CBRIDGE
    },
    // SX LFI to Ethereum LFI
    { 
      symbol: Symbols.LFI,
      chainId: ChainIds.SX,
      label: "LunaFi",
      icon: LfiIcon,
      address: "0x4C882ec256823eE773B25b414d36F92ef58a7c0C",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.SX_NETWORK_TOKEN,
      bridgeToChain: ChainIds.ETHEREUM,
      bridgeToToken: Symbols.LFI,
      min: 1000,
      max: 100000000,
      contract: ContractNames.CBRIDGE
    },
    // Ethereum Flux to SX Flux
    { 
      symbol: Symbols.FLX,
      chainId: ChainIds.ETHEREUM,
      label: "Flux",
      icon: FlxIcon,
      address: "0x3Ea8ea4237344C9931214796d9417Af1A1180770",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.OTHER_TOKEN,
      bridgeToChain: ChainIds.SX,
      bridgeToToken: Symbols.FLX,
      min: 400,
      max: 100000000,
      contract: ContractNames.CBRIDGE_CUSTOM
    },
    // SX Flux to Ethereum Flux
    { 
      symbol: Symbols.FLX,
      chainId: ChainIds.SX,
      label: "Flux",
      icon: FlxIcon,
      address: "0xE6a4a6d1E937D9eB3f0A24143194b10DcF7b6268",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.SX_NETWORK_TOKEN,
      bridgeToChain: ChainIds.ETHEREUM,
      bridgeToToken: Symbols.FLX,
      min: 400,
      max: 100000000,
      contract: ContractNames.CBRIDGE_CUSTOM
    },
    // Ethereum Plot to SX Plot
    { 
      symbol: Symbols.PLOT,
      chainId: ChainIds.ETHEREUM,
      label: "PLOT",
      icon: PlotIcon,
      address: "0x72F020f8f3E8fd9382705723Cd26380f8D0c66Bb",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.0000",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.OTHER_TOKEN,
      bridgeToChain: ChainIds.SX,
      bridgeToToken: Symbols.PLOT,
      min: 1000,
      max: 100000000,
      contract: ContractNames.CBRIDGE_CUSTOM
    },
    // SX Plot to Ethereum Plot
    { 
      symbol: Symbols.PLOT,
      chainId: ChainIds.SX,
      label: "PLOT",
      icon: PlotIcon,
      address: "0x2D4e10Ee64CCF407C7F765B363348f7F62D2E06e",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.0000",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.SX_NETWORK_TOKEN,
      bridgeToChain: ChainIds.ETHEREUM,
      bridgeToToken: Symbols.PLOT,
      min: 1000,
      max: 100000000,
      contract: ContractNames.CBRIDGE_CUSTOM
    },
    // Ethereum RAGE to SX RAGE
    { 
      symbol: Symbols.RAGE,
      chainId: ChainIds.ETHEREUM,
      label: "Rage",
      icon: RageIcon,
      address: "0x94804dc4948184fFd7355f62Ccbb221c9765886F",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.OTHER_TOKEN,
      bridgeToChain: ChainIds.SX,
      bridgeToToken: Symbols.RAGE,
      min: 12000,
      max: 100000000,
      contract: ContractNames.CBRIDGE_CUSTOM
    },
    // SX Rage to Ethereum Rage
    { 
      symbol: Symbols.RAGE,
      chainId: ChainIds.SX,
      label: "Rage",
      icon: RageIcon,
      address: "0x394022F5d20475D37d6904Cc2Df0BaE43DACa610",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.SX_NETWORK_TOKEN,
      bridgeToChain: ChainIds.ETHEREUM,
      bridgeToToken: Symbols.RAGE,
      min: 12000,
      max: 100000000,
      contract: ContractNames.CBRIDGE_CUSTOM
    },
    // Ethereum UMA to SX UMA
    { 
      symbol: Symbols.UMA,
      chainId: ChainIds.ETHEREUM,
      label: "UMA",
      icon: UmaIcon,
      address: "0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.OTHER_TOKEN,
      bridgeToChain: ChainIds.SX,
      bridgeToToken: Symbols.UMA,
      min: 10,
      max: 100000000,
      contract: ContractNames.CBRIDGE_CUSTOM
    },
    // SX UMA to Ethereum UMA
    { 
      symbol: Symbols.UMA,
      chainId: ChainIds.SX,
      label: "UMA",
      icon: UmaIcon,
      address: "0xD58b7Cd93103D05eb081Ec97F22d8e1FfF90f8f9",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.SX_NETWORK_TOKEN,
      bridgeToChain: ChainIds.ETHEREUM,
      bridgeToToken: Symbols.UMA,
      min: 10,
      max: 100000000,
      contract: ContractNames.CBRIDGE_CUSTOM
    },
    // Ethereum BlitzPredict to SX BlitzPredict
    { 
      symbol: Symbols.XBP,
      chainId: ChainIds.ETHEREUM,
      label: "BlitzPredict",
      icon: XpbIcon,
      address: "0x28dee01D53FED0Edf5f6E310BF8Ef9311513Ae40",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.OTHER_TOKEN,
      bridgeToChain: ChainIds.SX,
      bridgeToToken: Symbols.XBP,
      min: 60000,
      max: 100000000,
      contract: ContractNames.CBRIDGE_CUSTOM
    },
    // SX BlitzPredict to Ethereum BlitzPredict
    { 
      symbol: Symbols.XBP,
      chainId: ChainIds.SX,
      label: "BlitzPredict",
      icon: XpbIcon,
      address: "0x7F251FBdB17Ae50fbc24d5e712e6cd1288C4Cec3",
      abi: UChildERC20.abi,
      decimals: 18,
      format: "0,0.00",
      celer: true,
      cellerPegged: true,
      unbridgeable: false,
      celerMode: CelerBridgeMode.SX_NETWORK_TOKEN,
      bridgeToChain: ChainIds.ETHEREUM,
      bridgeToToken: Symbols.XBP,
      min: 60000,
      max: 100000000,
      contract: ContractNames.CBRIDGE_CUSTOM
    },
    // Polygon WETH to SX WETH
    {
      symbol: Symbols.WETH,
      chainId: ChainIds.POLYGON,
      label: "WETH",
      icon: EthIcon,
      address: ADDRESSES[ChainIds.POLYGON].WETH,
      abi: ChildERC20.abi,
      decimals: 18,
      format: "0,0.0000",
      max: 100,
      min: 0.0025,
      celer: false,
      default: false,
      bridgeToChain: ChainIds.SX,
      bridgeToToken: Symbols.WETH,
      fastDeposit: true
    },
    // Polygon USDC to SX USDC
    { 
      symbol: Symbols.USDC,
      chainId: ChainIds.POLYGON,
      label: "USDC",
      icon: UsdcIcon,
      address: ADDRESSES[ChainIds.POLYGON].USDC,
      abi: USDC_Polygon.abi,
      decimals: 6,
      format: "0,0.00",
      celer: false,
      bridgeToChain: ChainIds.SX,
      bridgeToToken: Symbols.USDC,
      fastDeposit: true,
      min: 3,
      max: 100000000,
    }
  ]
}

export const Tokens = TokensByEnv[ENV];
