import { useEffect, useRef, useState } from "react";
import { ChainIds, ETHEREUM_ID, MAINCHAIN_ID, SIDECHAIN_ID, Symbols } from "../config";
import { BigNumber, utils } from "ethers";
import { ConnectorTypes, getMagicEthereumProvider, getMagicMainchainProvider, getMagicSidechainProvider } from "../connectors";
import { Web3Provider } from '@ethersproject/providers';

export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return
    }

    const id = setInterval(() => savedCallback.current(), delay)

    return () => clearInterval(id)
  }, [delay])
}

const isIOS = () => !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

const fallbackCopyToClipboard = (str: string, elementId: string) => {
  var el: any = document.createElement("textarea");

  el.contentEditable = true;
  el.readOnly = false;
  el.value = str;
  el.style = { position: "absolute", left: "-9999px" };
  document.querySelector(`#${elementId}`)!.appendChild(el);

  if (isIOS()) {
    const range = document.createRange();
    range.selectNodeContents(el);
    const s: any = window.getSelection();
    s.removeAllRanges();
    s.addRange(range);
    el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.
  } else {
    el.select();
  }

  document.execCommand("copy");
  document.querySelector(`#${elementId}`)!.removeChild(el);
};

export const copyStringToClipboard = (str: string, elementId: string) => {
  if (!navigator.clipboard) {
    fallbackCopyToClipboard(str, elementId);
    return;
  }
  navigator.clipboard.writeText(str).then(
    () => {},
    err => {
      console.error("Async: Could not copy text: ", err);
    }
  );
};

export const shortenAddress = (address: string) => {
  return `${address.substr(0, 6)}...${address.substr(address.length - 4, 4)}`;
};

export const estimateGasFee = (chainId: ChainIds, symbol: Symbols) => {
  let fee = BigInt(0);
  if (chainId === MAINCHAIN_ID && symbol === Symbols.SX) {
    fee = BigInt(1000000000 * 50000);
  }
  return fee.toString();
};

export const getNetworkLabel = (chainId: number) => {
  const labels: any = {
    1: "Ethereum",
    3: "Ropsten",
    4: "Rinkeby",
    5: "Goerli",
    137: "Polygon",
    647: "Toronto",
    8001: "Mumbai",
  };

  return labels[chainId] || "Unknown";
};

export function getIntervals(start: number, end: number, chunkSize: number) {
  if (start >= end) {
    throw new Error(
      `start must be less than end. Start: ${start}. End: ${end}`
    );
  }
  if (start < 0) {
    throw new Error(`start must be greater than zero. Start: ${start}`);
  }
  const interval: number[][] = [];
  if (end - start <= chunkSize) {
    interval.push([start, end]);
  } else {
    interval.push([start, start + chunkSize]);
    while (interval.slice(-1)[0][1] < end) {
      const lastEnd = interval.slice(-1)[0][1];
      if (lastEnd + 1 + chunkSize > end) {
        interval.push([lastEnd + 1, end]);
      } else {
        interval.push([lastEnd + 1, lastEnd + 1 + chunkSize]);
      }
    }
  }
  return interval;
}

export function timestampToDate(timestamp: number) {
  const parsed = new Date(timestamp * 1000);
  return parsed;
}

export function dateToTimestamp(date: Date) {
  return Math.round(date.getTime() / 1000);
}

export const useDebounce = (value:string, delay:number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value] 
  );
  return debouncedValue;
}

export function getMaticEip712Payload(
  abiEncodedFunctionSig: string,
  nonce: number,
  from: string,
  chainId: number,
  verifyingContract: string,
  domainName: string
) {
  return {
    types: {
      EIP712Domain: [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "verifyingContract", type: "address" },
        { name: "salt", type: "bytes32" }
      ],
      MetaTransaction: [
        { name: "nonce", type: "uint256" },
        { name: "from", type: "address" },
        { name: "functionSignature", type: "bytes" }
      ]
    },
    domain: {
      name: domainName,
      version: "1",
      salt: utils.hexZeroPad(utils.hexlify(chainId), 32),
      verifyingContract
    },
    message: {
      nonce,
      from,
      functionSignature: abiEncodedFunctionSig
    },
    primaryType: "MetaTransaction"
  };
}

export const toHex = (covertThis: any, padding: number) => {
  return utils.hexZeroPad(utils.hexlify(covertThis), padding);
};

export const createERCDepositData = (
  tokenAmount: string, 
  lenRecipientAddress: number, 
  recipientAddress: string
) => {
  return '0x' +
    toHex(BigNumber.from(tokenAmount), 32).substr(2) +         // Token amount or ID to deposit (32 bytes)
    toHex(lenRecipientAddress, 32).substr(2) +                 // len(recipientAddress)          (32 bytes)
    recipientAddress.substr(2);                                // recipientAddress               (?? bytes)
};

export const getProvider = (
  connectorType: ConnectorTypes, 
  library: Web3Provider, 
  sourceChainId: number,
) => {
  let provider: any;
  if (connectorType === ConnectorTypes.Magic) {
    if(sourceChainId === MAINCHAIN_ID) {
      provider = getMagicMainchainProvider();
    } else if(sourceChainId === ETHEREUM_ID) {
      provider = getMagicEthereumProvider();
    } else if(sourceChainId === SIDECHAIN_ID) {
      provider = getMagicSidechainProvider();
    }
  } else {
    provider = library;
  }
  return provider;
};

export const isEmpty = (obj) =>
  !obj || (Object.keys(obj).length === 0 && obj.constructor === Object);

export const isNumeric = (n: any) => {
  const value = parseFloat(n);
  return !isNaN(value) && isFinite(value);
};