/** @jsxImportSource @emotion/react */
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from '@ethersproject/providers';
import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, TextField, useTheme, Grid, useMediaQuery } from "@mui/material";
import AccountMenu from "./AccountMenu";
import { Route, Routes, useNavigate, Link } from 'react-router-dom';
import { useEagerConnect, useInactiveListener } from "../connectors/hooks";
import { useEffect, useState } from "react";
import { ConnectorTypes, getConnector } from "../connectors";
import { Global } from "@emotion/react";
import { getConnectorType, getProviderErrorMessage } from "../connectors/utils";
import Bridge from "./Bridge";
import { SIDECHAIN_ID } from "../config";
import { ChainConfigs } from "../config/chains";
import WrapUnwrap from "./WrapUnwrap";
import Footer from "./Footer";
import ConnectorPane from "./ConnectorPane";
import { useRecoilState } from "recoil";
import { currentAlertState } from "../state";
import AssetsList from "./AssetsList";
import TransferListener from "./TransferListener";
import BalanceFetcher from "./BalanceFetcher";
import UserMenu from "./UserMenu";
import logo from "../assets/sx_network_logo.png";
import logoMobile from "../assets/sx_network_logo-sm.png";
import { Controller, useForm } from "react-hook-form";
import { useIntercom } from 'react-use-intercom';
import getWalletViewStyles from "../styles/WalletViewStyles";

function WalletView() {

  // state
  const [activatingConnector, setActivatingConnector] = useState();

  // hooks
  const theme = useTheme();
  const navigate = useNavigate();
  const context = useWeb3React<Web3Provider>();
  const { connector, account, activate, deactivate, error } = context;
  const phone = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });
  const { boot } = useIntercom();
  const { handleSubmit, control } = useForm({ defaultValues: { email: "" }, });

  // styles
  const styles = getWalletViewStyles({ theme });

  // recoil
  const [, setCurrentAlert] = useRecoilState(currentAlertState);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const connectorType = getConnectorType(connector);

  // lifecycles
  useEffect(() => {
    boot(); // Boot intercom
  },[boot])

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [connector, activatingConnector]);

  // methods
  const onConnect = async (type: ConnectorTypes, params: any = {}) => {
    if (type === ConnectorTypes.Injected) {
      const injectedConnector = getConnector(type);
      window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [ChainConfigs[SIDECHAIN_ID]]
      });  
      setActivatingConnector(injectedConnector);
      activate(injectedConnector);
      localStorage.setItem('preferredConnector', type); 
    } else if (type === ConnectorTypes.Magic) {
      const { email } = params;
      const magicConnector = getConnector(type);
      let isLoggedIn = false;
      if (email) { // email provided
        setActivatingConnector(magicConnector);
        magicConnector.setEmail(email);
        await activate(magicConnector);
        isLoggedIn = await magicConnector.magic.user.isLoggedIn();
        if (isLoggedIn) {
          localStorage.setItem('preferredConnector', type);
        } else {
          setCurrentAlert({
            severity: "error",
            message: "Unable to login."
          });
        }
      } else { // no email provided, try to re-use the session if preferred connector
        if (localStorage.getItem('preferredConnector') === type) {
          setActivatingConnector(magicConnector);

          await activate(magicConnector);
          isLoggedIn = await magicConnector.magic.user.isLoggedIn();
          if (!isLoggedIn) {
            setEmailDialogOpen(true);
          } 
        } else { 
          setEmailDialogOpen(true);
        }
      }
    } else {
      setCurrentAlert({
        severity: "warning",
        message: "Wallet type is not yet enabled"
      })
    }
  }

  const handleDisconnect = () => {
    deactivate();
    localStorage.removeItem('preferredConnector');
    navigate("/");
  }

  const handleEmailDialogClose = () => {
    setActivatingConnector(undefined);
    setEmailDialogOpen(false);
  };

  const onEmailDialogSubmit = async (values: any) => {
    try {
      const { email } = values;
      onConnect(ConnectorTypes.Magic, { email });
      setEmailDialogOpen(false);  
    } catch (err) {
      console.log("--------- onEmailDialogSubmit error", err);
    } 
  };

  const EmailDialog = () => {
    return (
      <Dialog open={emailDialogOpen}>
        <form onSubmit={handleSubmit(onEmailDialogSubmit)}>
          <DialogTitle>Login</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter your email address here.
            </DialogContentText>
            <Controller 
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "Email is required",
              }}
              render={({ field, fieldState }) => {
                const { error } = fieldState;
                return (
                  <TextField 
                    css={styles.email}
                    label="Email"
                    focused
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEmailDialogClose}>Cancel</Button>
            <Button type="submit">Login</Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect(setActivatingConnector);

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);
      
  return (
    <Container component="main" css={styles.container} maxWidth={false}>
      <Global styles={styles.global} />
      <Grid container alignItems="center" css={styles.header}>
        <Grid item xs={3}>
          {
            !phone ? 
              <Link to="/"><img src={logo} alt="SX Network" css={styles.logo} /></Link> 
              :
              <Link to="/"><img src={logoMobile} alt="SX Network" css={styles.logo} /></Link>
          }
        </Grid>
        <Grid item xs={9}>
          <Grid container item justifyContent="flex-end">
            {account && <UserMenu account={account} />}
            {account && <AccountMenu account={account} connectorType={connectorType!} onDisconnect={handleDisconnect} />}
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" css={styles.body}>
        <Grid item xs={12} md={8} lg={6}>
          <Paper css={styles.paper} elevation={0}>
            <Box css={styles.wallet}>
              {error && <Box p={2}><Alert severity="error">{getProviderErrorMessage(error)}</Alert></Box>}
              <Routes>
                {!account && triedEager && <Route path="*" element={ <ConnectorPane onConnect={onConnect}/> }/>}
                {account && <Route path="/assets" element={ <AssetsList/> }/>}
                {account && <Route path="/:transfer_id/:tx" element={ <Bridge/> }/>}
                {account && <Route path="/" element={ <Bridge/> }/>}
                {account && <Route path="/wrap/:srcSymbol/:dstSymbol" element={ <WrapUnwrap/> }/>}
              </Routes>
              {account && <TransferListener account={account} />}
              {account && <BalanceFetcher account={account} />}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <EmailDialog />
      <Grid container alignItems="center" css={styles.footer}>
        <Grid item xs={12} display="flex" alignItems="center">
          <Footer/>
        </Grid>
      </Grid>
    </Container>
  );
}

export default WalletView;