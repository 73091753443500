import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#18249B",
    },
    background: {
      default: "#f8f8f8"
    }
  },
  typography: {
    fontFamily: '"Poppins", sans-serif',
    button: {
      fontWeight: 500
    }
  },
  shape: {
    borderRadius: 6,
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          padding:5,
          background: "#EAF2FF",
          borderRadius: 6,
        },
        indicator: {
          height: 0
        }
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          "&.Mui-selected": {
            backgroundColor: "#18249B",
            color: "#fff"
          }, 
          "&:hover": {}  
        }
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 600
        }
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: 0
        }
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "0 24px 18px 24px"
        }
      },
    }
  },
});

export default theme;

/*

  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '1rem',
        },
      },
    },
  },

*/