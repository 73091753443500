import { utils, ethers } from "ethers";
import { selector } from "recoil";
import { getRpcProvider } from "../services/providers";
import { getRates, getBridgeFee } from "../services/rates";
import { ContractsMeta } from "../config/contracts";
import { ContractNames } from "../config";

export interface IRatesState {
  [symbol: string]: number;
}

export const ratesQuery = selector<IRatesState>({
  key: 'RatesQuery',
  get: async () => {
    const rates = await getRates();
    return rates;
  }
});

export const bridgeFeeQuery = selector<number>({
  key: 'BridgeFeeQuery',
  get: async () => {
    const bridgeFee = await getBridgeFee();
    return bridgeFee;
  }
});

export const ethPriceQuery = selector<number>({
  key: 'EthPriceQuery',
  get: async () => {
    const provider = getRpcProvider(137);
    const findContract = ContractsMeta.find(e => e.name === ContractNames.UNISWAP && e.chainId === 137)!;
    const daiEthPair = new ethers.Contract(findContract.address, findContract.abi, provider);
    const [
      daiEthPairReserve0,
      daiEthPairReserve1
    ] = await daiEthPair.getReserves();
    const daiEthPrice = Number(utils.formatUnits(daiEthPairReserve0, 18)) / Number(utils.formatUnits(daiEthPairReserve1, 18))
    return daiEthPrice;
  }
});

