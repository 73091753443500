/** @jsxImportSource @emotion/react */
import { Box, Button, IconButton, MenuList, MenuItem, Drawer, Typography, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, Stack, Skeleton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { 
  NavLink
} from 'react-router-dom';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { Fragment, memo, useState } from "react";
import { transferHistorySelector } from "../state/transfers";
import { useRecoilValue } from "recoil";
import getUserMenuStyles from "../styles/UserMenuStyles";
import HistoryItem from "./HistoryItem";
import HistoryFetcher from "./HistoryFetcher";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from '@ethersproject/providers';
import { currentAlertState } from "../state";

function UserMenu(params: { account: string }) {
  
  // state
  const [open, setOpen] = useState(false);
  const [drawer, setDrawer] = useState(false);

  // hooks
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });
  const { account } = useWeb3React<Web3Provider>();
  const currentStatus = useRecoilValue(currentAlertState)!;

  // styles
  const styles = getUserMenuStyles({ theme });

  // recoil
  const transfers = useRecoilValue(transferHistorySelector);

  // methods
  const handleOpenTransactions = () => {
    setDrawer(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      { 
        account && open && <HistoryFetcher account={account} />
      }
      {
        !phone ? (
          <>
            <NavLink to="/" css={styles.menuLink}>
              <Button
                css={styles.button}
              >
                Bridge
              </Button>
            </NavLink>
            <NavLink to="/assets" css={styles.menuLink}>
              <Button
                css={styles.button}
              >
                Assets
              </Button>
            </NavLink>
            <Button
              onClick={handleOpenTransactions}
              css={styles.button}
            >
              Transactions
            </Button>
          </>
        ) : (
          <Button
            css={styles.menuButton}
            onClick={() => setDrawer(true)}
            >
            <MoreHoriz/>
          </Button>
        )
      }
      <Dialog 
        onClose={handleClose} 
        open={open} 
        fullWidth={true}
        fullScreen={phone ? true : false}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" pr={2}>
          <DialogTitle>Recent Transactions</DialogTitle>
          <IconButton size="small" onClick={handleClose}><CloseIcon/></IconButton>
        </Box>
        <Box px={3}>
          
        </Box>
        <DialogContent style={{padding:"0 0 0 0"}}>
          {
            transfers === undefined
            ? <Box px={3} pb={2}>
                <Stack spacing={0}>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Stack>
              </Box>
            : transfers.length > 0
            ? <>
                <MenuList dense={true}>
                  {
                    transfers.map((transfer, i) => (
                      <MenuItem key={`transfer-${i}`} divider>
                        <HistoryItem transfer={transfer}/>
                      </MenuItem>
                    ))
                  }
                </MenuList>
              </>
            : <Box px={3} pb={3}><Typography variant="caption">No recent transactions</Typography></Box>
          }
        </DialogContent>
      </Dialog>
      <Drawer
        open={drawer}
        onClose={() => setDrawer(false)}
      >
        <div css={styles.drawer}>
          <NavLink to="/" css={styles.menuLink}>
            <Button
              css={styles.button}
              onClick={() => setDrawer(false)}
            >
              Bridge
            </Button>
          </NavLink>
          <NavLink to="/assets" css={styles.menuLink}>
            <Button
              css={styles.button}
              onClick={() => setDrawer(false)}
            >
              Assets
            </Button>
          </NavLink>
          <Button
            onClick={handleOpenTransactions}
            css={styles.button}
          >
            Transactions
          </Button>
        </div>
      </Drawer>
    </Fragment>
  );

}

export default memo(UserMenu);
