export interface IChain {
  chainId: number;
  label: string;
  rpcUrls: string[];
  blockExplorer?: string;
  currency: Symbols;
  iconUrls: string;
}

export interface IToken {
  symbol: Symbols;
  chainId: ChainIds;
  label: string;
  icon: string;
  address?: string;
  abi?: any;
  decimals: number;
  format: string;
  resourceId?: string;
  wrappableTo?: Symbols;
  unwrappableTo?: Symbols;
  unbridgeable?: boolean;
  max?: number;
  min?: number;
  celer?: boolean;
  default?: boolean;
  cellerPegged?: boolean;
  celerMode?: number;
  bridgeToChain?: number;
  bridgeToToken?: Symbols;
  contract?: ContractNames;
  fastDeposit?: boolean;
}

export interface IContract {
  name: ContractNames;
  chainId: ChainIds;
  address: string;
  abi?: any;
}

export enum Environments {
  DEV = "dev",
  TESTNET = "testnet",
  MAINNET = "mainnet"
}

export enum ChainIds {
  ETHEREUM = 1,
  GOERLI = 5,
  POLYGON = 137,
  MUMBAI = 80001,
  SX = 416,
  TORONTO = 647
}

export enum Symbols {
  SX = "SX",
  WSX = "WSX",
  WETH = "WETH",
  DAI = "DAI",
  USDC = "USDC",
  MATIC = "MATIC",
  WMATIC = "WMATIC",
  SHARK = "SHARK",
  GREEN = "GREEN",
  ETH = "ETH",
  MAI = "MAI",
  QI = "QI",
  BIFI = "BIFI",
  AX = "AX",
  IMX = "IMX",
  FLX = "FLX",
  LFI = "LFI",
  PLOT = "PLOT",
  RAGE = "RAGE",
  UMA = "UMA",
  XBP = "XBP"
}

export enum ContractNames {
  BRIDGE = "bridge",
  ERC20_HANDLER = "erc20_handler",
  CBRIDGE = "cbridge",
  CBRIDGE_DEFAULT = "cbridge_default",
  CBRIDGE_CUSTOM = "cbridge_custom",
  UNISWAP = "uniswap",
}

export const WalletTokenOrder = [
  Symbols.SX,
  Symbols.WSX,
  Symbols.WETH,
  Symbols.MATIC,
  Symbols.WMATIC,
  Symbols.DAI,
  Symbols.USDC,
  Symbols.SHARK,
  Symbols.GREEN,
]

export const CoingeckoSymbols = {
  [Symbols.SX]: "sx-network",
  [Symbols.WSX]: "sx-network",
  [Symbols.MATIC]: "wmatic",
  [Symbols.WETH]: "weth",
  [Symbols.ETH]: "weth",
  [Symbols.MAI]: "mimatic",
  [Symbols.QI]: "qi-dao",
  [Symbols.BIFI]: "beefy-finance",
  [Symbols.AX]: "athletex",
  [Symbols.XBP]: "blitzpredict",
  [Symbols.FLX]: "flux-token",
  [Symbols.PLOT]: "plotx",
  [Symbols.LFI]: "lunafi"
}


export const ENV = process.env.REACT_APP_ENV!;
export const VERSION = process.env.REACT_APP_VERSION;
export const MAINCHAIN_ID = Number(process.env.REACT_APP_MAINCHAIN_ID);
export const SIDECHAIN_ID = Number(process.env.REACT_APP_SIDECHAIN_ID);
export const MAGIC_API_KEY = process.env.REACT_APP_MAGIC_API_KEY;
export const MAGIC_SIDECHAIN_RPC_URL = process.env.REACT_APP_MAGIC_SIDECHAIN_RPC_URL;
export const MAGIC_MAINCHAIN_RPC_URL = process.env.REACT_APP_MAGIC_MAINCHAIN_RPC_URL;
export const GRAPH_NODE_URL = process.env.REACT_APP_GRAPH_NODE_URL;
export const GRAPH_HOSTED_SERVICE_URL = process.env.REACT_APP_GRAPH_HOSTED_SERVICE_URL;
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
export const SENTRY_URL = process.env.REACT_APP_SENTRY_URL;
export const SENTRY_RELEASE = `sx-bridge@${VERSION}`;
export const AIRDROP = process.env.REACT_APP_AIRDROP;
export const GAS_PRICE = process.env.REACT_APP_GAS_PRICE;
export const GAS_LIMIT = process.env.REACT_APP_GAS_LIMIT;
export const CBRIDGE_URL = process.env.REACT_APP_CBRIDGE_URL;
export const RELAYER_URL = process.env.REACT_APP_RELAYER_URL;
export const FAST_EXIT_SPENDER = process.env.REACT_APP_FAST_EXIT_SPENDER;
export const ETHEREUM_ID = Number(process.env.REACT_APP_ETHEREUM_ID);
export const MAGIC_ETHEREUM_RPC_URL = process.env.REACT_APP_MAGIC_ETHEREUM_RPC_URL;

export enum cBridgeBridgeStatusMap {
  TRANSFER_UNKNOWN = 0,
  TRANSFER_SUBMITTING = 1,
  TRANSFER_FAILED = 2,
  TRANSFER_WAITING_FOR_SGN_CONFIRMATION = 3,
  TRANSFER_WAITING_FOR_FUND_RELEASE = 4,
  TRANSFER_COMPLETED = 5,
  TRANSFER_TO_BE_REFUNDED = 6,
  TRANSFER_REQUESTING_REFUND = 7,
  TRANSFER_REFUND_TO_BE_CONFIRMED = 8,
  TRANSFER_CONFIRMING_YOUR_REFUND = 9,
  TRANSFER_REFUNDED = 10
}

export const cBridgeBridgeStatus: any = {
  [cBridgeBridgeStatusMap.TRANSFER_UNKNOWN]: "Unknown",
  [cBridgeBridgeStatusMap.TRANSFER_SUBMITTING]: "Pending",
  [cBridgeBridgeStatusMap.TRANSFER_FAILED]: "Failed",
  [cBridgeBridgeStatusMap.TRANSFER_WAITING_FOR_SGN_CONFIRMATION]: "Confirmation",
  [cBridgeBridgeStatusMap.TRANSFER_WAITING_FOR_FUND_RELEASE]: "Pending Release",
  [cBridgeBridgeStatusMap.TRANSFER_COMPLETED]: "Success",
  [cBridgeBridgeStatusMap.TRANSFER_TO_BE_REFUNDED]: "Refund",
  [cBridgeBridgeStatusMap.TRANSFER_REQUESTING_REFUND]: "Refund Requesting",
  [cBridgeBridgeStatusMap.TRANSFER_REFUND_TO_BE_CONFIRMED]: "Refund Pending",
  [cBridgeBridgeStatusMap.TRANSFER_CONFIRMING_YOUR_REFUND]: "Refund Confirming",
  [cBridgeBridgeStatusMap.TRANSFER_REFUNDED]: "Refund Success",
};

export enum cBridgeTransferHistoryStatus {
  TRANSFER_UNKNOWN, // placeholder
  TRANSFER_SUBMITTING, // user: after calling mark transfer api
  TRANSFER_FAILED, // user: check if tx reverted when shown status is TRANSFER_SUBMITTING
  TRANSFER_WAITING_FOR_SGN_CONFIRMATION, // relayer: on send tx success event
  TRANSFER_WAITING_FOR_FUND_RELEASE, // relayer: mark send tx
  TRANSFER_COMPLETED, // relayer: on relay tx success event
  TRANSFER_TO_BE_REFUNDED, // x: transfer rejected by sgn and waiting for withdraw api called
  TRANSFER_REQUESTING_REFUND, // user: withdraw api has been called and withdraw is processing by sgn
  TRANSFER_REFUND_TO_BE_CONFIRMED, // x: withdraw is approved by sgn
  TRANSFER_CONFIRMING_YOUR_REFUND, // user: mark refund has been submitted on chain
  TRANSFER_REFUNDED, // relayer: on refund(withdraw liquidity actually) tx event
  TRANSFER_DELAYED,
}

export interface GetTransferStatusResponseType {
  status: cBridgeTransferHistoryStatus;
  wd_onchain: string;
  sorted_sigs: Array<string>;
  signers: Array<string>;
  powers: Array<string>;
  src_block_tx_link?: string;
  dst_block_tx_link?: string;
}

export enum Modes {
  BALANCES = "balances",
  TRANSFER = "transfer",
  WRAP_UNWRAP = "wrap_unwrap",
  SEND = "send",
  BRIDGE = "bridge"
}

export enum CelerBridgeMode {
  NATIVE_TOKEN = 0, // ie. Matic on Polygon, ETH on Ethereum
  OTHER_TOKEN = 1, // ie WETH, USDC
  SX_NETWORK_TOKEN = 2 // Any bridgeable token on SX Network
}

export const MATIC_GAS_PRICE_URL = "https://gasstation-mainnet.matic.network";
export const SX_GAS_PRICE_URL = "https://gas-station.sx.technology";

// export const MAGIC_AUTH_ENDPOINT = process.env.REACT_APP_MAGIC_AUTH_ENDPOINT;
