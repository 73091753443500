import { request, gql } from 'graphql-request'
import { ChainIds, GRAPH_NODE_URL, GRAPH_HOSTED_SERVICE_URL, CBRIDGE_URL, Symbols, RELAYER_URL, MAINCHAIN_ID, SIDECHAIN_ID } from '../config'
import { ITransferState } from '../state/transfers'
import { sendSentryError } from "../services/sentry";
import { WebClient } from "./../ts-proto/gateway/GatewayServiceClientPb";
import {
  TransferHistoryRequest
} 
from "./../ts-proto/gateway/gateway_pb";
import { Tokens } from '../config/tokens';
import { timestampToDate } from "../utils";
import axios from "axios";
import { Chains } from '../config/chains';

export async function fetchTransfers(account: string, chainId: ChainIds, status: string): Promise<ITransferState[] | undefined> {
  try {
    let transfers: ITransferState[] = []
    const url = `${GRAPH_HOSTED_SERVICE_URL}` // /graphql
    const query = gql`
      {
        bridgeEvents(where: { address: "${account}", status: "${status}" }) {
          id
          address
          resourceId
          amount
          txHash
          timestamp
          status
        }
      }
    `
    const response = await request(url, query);
    transfers = response.bridgeEvents.map((transfer: any) => ({
      id: transfer.id,
      account,
      dstChainId: chainId,
      amount: transfer.amount,
      resourceId: transfer.resourceId,
      timestamp: timestampToDate(Number(transfer.timestamp)),
      transactionHash: transfer.txHash,
      status: transfer.status,
      symbol: Symbols.SX
    }));
    return transfers
  } catch (err) {
    console.log('-------- fetchTransfersFromGraph error', err)
    sendSentryError(err as Error);
  }
}

export async function fetchTransfersFromGraph(account: string, chainId: ChainIds, status: string): Promise<ITransferState[] | undefined> {
  try {
    let transfers: ITransferState[] = []
    const url = `${GRAPH_NODE_URL}subgraphs/name/erc20Handler` // /graphql
    const query = gql`
      {
        bridgeEvents(where: { address: "${account}", status: "${status}" }) {
          id
          address
          resourceId
          amount
          txHash
          timestamp
          status
        }
      }
    `
    const response = await request(url, query);
    transfers = response.bridgeEvents.map((transfer: any) => ({
      id: transfer.id,
      account,
      dstChainId: chainId,
      amount: transfer.amount,
      resourceId: transfer.resourceId,
      timestamp: timestampToDate(Number(transfer.timestamp)),
      transactionHash: transfer.txHash,
      status: transfer.status,
      symbol: Symbols.SX
    }));
    return transfers;
  } catch (err) {
    console.log('-------- fetchTransfersFromGraph error', err)
    sendSentryError(err as Error);
  }
}

export async function fetchTransfersFromCBridge(account: string): Promise<any> {
  const client = new WebClient(CBRIDGE_URL!, null, null);  
  try {
    const request = new TransferHistoryRequest();
    request.setAddr(account);
    request.setPageSize(20);
    const response = await client.transferHistory(request, null);
    const transfers = response?.toObject().historyList?.map(item => {
      const tokenMeta = Tokens.find(e => e.symbol === item.srcSendInfo?.token?.symbol)!;
      return {
        id: item.transferId,
        timestamp: item.ts,
        srcChainId: item.srcSendInfo?.chain?.id,
        dstChainId: item.dstReceivedInfo?.chain?.id,
        resourceId: tokenMeta ? tokenMeta.resourceId : "",
        amount: item.dstReceivedInfo?.amount,
        account: account,
        transactionHash: item.transferId,
        status: item?.status,
        celer: true,
        dstBlockTxLink: item.dstBlockTxLink,
        srcBlockTxLink: item.srcBlockTxLink,
        dstReceivedInfo: item.dstReceivedInfo,
        srcSendInfo: item.srcSendInfo,
        symbol: item.srcSendInfo?.token?.symbol
      }
    });
    return transfers;
  } catch (err) {
    sendSentryError(err as Error);
  }
}

export async function fetchTransfersFromRelayer(account: string): Promise<any> {
  try {
    const response = await axios.get(`${RELAYER_URL}/bridge/fast-deposit/${account}`);
    const { data: { data } } = response;

    const transfers = data.map(item => {
      const tokenMeta = Tokens.find(e => e.address === item.polygonToken)!;
      const dstChain = Chains.find(e => e.chainId === MAINCHAIN_ID)!;
      const srcChain = Chains.find(e => e.chainId === SIDECHAIN_ID)!;

      let dstBlockTxLink;
      let srcBlockTxLink;

      if(item.releaseTxAttempts && item.releaseTxAttempts.length > 0) {
        dstBlockTxLink = `${dstChain.blockExplorer}/tx/${item.releaseTxAttempts[item.releaseTxAttempts.length - 1]}`;
      }
      if(item.approveTx) {
        srcBlockTxLink = `${srcChain.blockExplorer}tx/${item.approveTx}`
      }

      return {
        id: item.approveTx,
        account,
        dstChainId: MAINCHAIN_ID,
        srcChainId: SIDECHAIN_ID,
        amount: item.amount,
        resourceId: tokenMeta ? tokenMeta.resourceId : "",
        timestamp: timestampToDate(Number(
          item.status === "RELEASE_SUCCESS" ? item.releaseMinedTime : item.fundsLockedTime
        )),
        transactionHash: item.approveTx,
        status: item.status,
        symbol: tokenMeta.symbol,
        fastBridge: true,
        dstBlockTxLink: dstBlockTxLink,
        srcBlockTxLink: srcBlockTxLink,
      }
    });
    return transfers;
  } catch(err) {
    console.log("-------- fetchTransfersFromRelayer error", err);
    throw err;
  }
}