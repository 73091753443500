import { BigNumber } from "ethers";
import { memo, useCallback, useEffect } from "react";
import { useRecoilCallback } from "recoil";
import { ChainIds, ContractNames, MAINCHAIN_ID, SIDECHAIN_ID } from "../config";
import { ContractsMeta } from "../config/contracts";
import { getContract } from "../services/contracts";
import { getRpcProvider } from "../services/providers";
import { ITransferState, transfersState } from "../state/transfers";

let handler1: any;
let handler2: any;

function TransferListener(params: { account: string }) {
  const { account } = params;
  const cleanUp = useCallback(() => {
    if (handler1) {
      handler1.removeAllListeners();
    }
    if (handler2) {
      handler2.removeAllListeners();
    }
  }, []);

  const init = useRecoilCallback(({ set }) => async () => {
    const handleEvent = async (
      recipientAddress: string, 
      resourceId: string, 
      amount: BigNumber, 
      context: any,
      chainId: ChainIds,
      status: string,
    ) => {
      const block = await context.getBlock();
      const transfer: ITransferState = {
        timestamp: block?.timestamp,
        dstChainId: chainId,
        resourceId,
        amount: amount.toString(),
        account: recipientAddress,
        transactionHash: block?.transactions[context?.transactionIndex],
        status
      }
      set(transfersState, (currentValue: ITransferState[] | undefined) => {
        return (currentValue || []).concat(transfer);
      });
    }

    const provider1 = getRpcProvider(MAINCHAIN_ID);
    const provider2 = getRpcProvider(SIDECHAIN_ID);

    const meta1 = ContractsMeta.find(e => e.name === ContractNames.ERC20_HANDLER && e.chainId === MAINCHAIN_ID)!;
    const meta2 = ContractsMeta.find(e => e.name === ContractNames.ERC20_HANDLER && e.chainId === SIDECHAIN_ID)!;

    handler1 = getContract(meta1.address, meta1.abi, provider1);
    handler2 = getContract(meta2.address, meta2.abi, provider2);

    const sidechainToMainchainSuccess = handler1.filters.ERC20ProposalExecuted(account, null, null);
    const mainchainToSidechainSuccess = handler2.filters.ERC20ProposalExecuted(account, null, null);
    const sidechainToMainchainPending = handler2.filters.ERC20InitiateBridge(account, null, null);
    const mainchainToSidechainPending = handler1.filters.ERC20InitiateBridge(account, null, null);

    // Sidechain -> Mainchain SUCCESS event
    handler1.on(sidechainToMainchainSuccess, (
      recipientAddress: string, 
      resourceId: string, 
      amount: BigNumber, 
      context: any
    ) => {
      handleEvent(recipientAddress, resourceId, amount, context, MAINCHAIN_ID, "success");
    });

    // Sidechain -> Mainchain PENDING event
    handler2.on(sidechainToMainchainPending, (
      recipientAddress: string, 
      resourceId: string, 
      amount: BigNumber, 
      context: any
    ) => {
      handleEvent(recipientAddress, resourceId, amount, context, MAINCHAIN_ID, "pending");
    });

    // Mainchain -> Sidechain PENDING event
    handler1.on(mainchainToSidechainPending, (
      recipientAddress: string, 
      resourceId: string, 
      amount: BigNumber, 
      context: any
    ) => {
      handleEvent(recipientAddress, resourceId, amount, context, SIDECHAIN_ID, "pending");
    });

    // Mainchain -> Sidechain SUCCESS event
    handler2.on(mainchainToSidechainSuccess, (
      recipientAddress: string, 
      resourceId: string, 
      amount: BigNumber, 
      context: any
    ) => {
      handleEvent(recipientAddress, resourceId, amount, context, SIDECHAIN_ID, "success");
    });

  }, [account]);

  useEffect(() => {
    if (account) {
      init();
    }
    return () => {
      cleanUp();
    }
  }, [account, init, cleanUp]);

  return null;
}

export default memo(TransferListener);