/** @jsxImportSource @emotion/react */
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from '@ethersproject/providers';
import { Box, Button, CircularProgress, Skeleton, TextField, Typography, useTheme } from "@mui/material";
import { css } from "@emotion/react";
import { useForm, Controller } from 'react-hook-form';
import { memo, Suspense, useState, useEffect } from "react";
import { Tokens } from "../config/tokens";
import { utils } from "ethers";
import { ChainConfigs, Chains } from "../config/chains";
import { unwrapToken, wrapToken } from "../services/tokens";
import TokenBalance from "./TokenBalance";
import { ArrowBack } from "@mui/icons-material";
import { useRecoilValueLoadable, useRecoilState, useRecoilValue } from "recoil";
import { currentAlertState } from "../state";
import numeral from "numeral";
// import { estimateGasFee } from "../utils";
import { balanceState, IBalance } from "../state/balances";
import { currentChainIdState, selectedTokenState } from "../state";
import { getConnectorType } from "../connectors/utils";
import { ConnectorTypes } from "../connectors";
import { useParams, useNavigate } from "react-router-dom";
import StatusAlert from "./StatusAlert";
import { Symbols, MAINCHAIN_ID } from "../config";
import getBridgeStyles from "../styles/BridgeStyles";

export function getStyles() {
  return {
    container: css`
    `,
    header: css`
      text-transform: uppercase;
      opacity:0.2;
      font-size:15px;
      margin-bottom:20px;
      letter-spacing:1px;
      margin-top:-5px;
      font-weight: 600;
    `,
    formBox: css`
      
    `,
    source: css`
      padding:50px 50px 50px 50px;
    `,
    target: css`
      background: #F3F8FF;
      padding:50px 50px 50px 50px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      margin-bottom: -15px;
    `, 
    fields: css`
      width: 100%;
    `,
    amount: css`
      width: 100%;
    `,
    token: css`
      min-width: 250px;
    `,
    chip: css`
      text-transform:uppercase;
      display: block;
    `,
    tokenIcon: css`
      height: 30px;
      margin-right: 5px;
    `,
    button: css`
      & .Mui-disabled {
        //color: grey;
      }
    `,
    spinner: css`
      margin-right: 10px;
      color: #a5a5a5;
    `,
    backButton: css`
      margin:15px 0 0 37px;
      font-size:13px;
    `,
    backIcon: css`
      margin-right: 5px;
      font-size:22px
    `,
    targetBalance: {
      background: "#E3EFFF",
      borderRadius: 6,
      padding:"10px 15px 8px 15px"
    },
    maxButton: css`
      padding:0 5px 0 5px;
      min-width: auto;
      font-size:13px;
      margin:-1px 0 0 5px;
      line-height:1;
      font-weight:600;
      background: #F3F8FF;
    `,
  }
}

function WrapUnwrap() {

  // hooks
  const params = useParams();
  const theme = useTheme();
  //const styles = getStyles();
  const { connector, library, account, chainId } = useWeb3React<Web3Provider>();
  const navigate = useNavigate();
  const { srcSymbol, dstSymbol } = params;

  // recoil
  let targetChainId = useRecoilValue(currentChainIdState);
  const [, setSelectedToken] = useRecoilState(selectedTokenState);
  if((srcSymbol === Symbols.SX && dstSymbol === Symbols.WSX) || (srcSymbol === Symbols.WSX && dstSymbol === Symbols.SX) ) {
    targetChainId = MAINCHAIN_ID; // manually adjust targetChainId based on the selected pair during "cold start"
  }

  // styles
  const styles = getBridgeStyles({ theme });

  const [processing, setProcessing] = useState("");
  const [currentStatus, setCurrentAlert] = useRecoilState(currentAlertState);
  
  // logic
  const connectorType = getConnectorType(connector);
  const isWrongNetwork = connectorType === ConnectorTypes.Injected && chainId !== targetChainId;  
  const targetChain = Chains.find(e => e.chainId === targetChainId)!;
  const srcToken = Tokens.find(e => e.chainId === targetChainId && e.symbol === srcSymbol)!;
  const dstToken = Tokens.find(e => e.chainId === targetChainId && e.symbol === dstSymbol)!;
  let sourceBalance: IBalance | undefined = undefined;
  const balanceLoadable = useRecoilValueLoadable(balanceState({ 
    chainId: targetChainId, 
    symbol: srcToken?.symbol,
    account: account as any
  }));
  if (balanceLoadable.state === "hasValue" && balanceLoadable.contents) {
    sourceBalance = balanceLoadable.contents;
  }

  const {
    handleSubmit,
    reset,
    setValue,
    trigger,
    control,
  } = useForm({
    defaultValues: {
      amount: ""
    },
  });

  useEffect(() => {
    setCurrentAlert(undefined);
    return () => {
      setSelectedToken(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleGoBack = () => {
    setCurrentAlert(undefined);
    navigate("/assets");
  }

  const handleNetworkSwitch = () => {  
    try {
      window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [ChainConfigs[targetChainId]]
      });
    } catch(err) {
      window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: ChainConfigs[targetChainId].chainId }],
      });
    }
  };

  const MaxButton = () => {
    if (sourceBalance && sourceBalance.balance) {
      let { balance } = sourceBalance;
      if(srcToken && srcToken.max && balance > srcToken.max) {
        balance = srcToken.max;
      }
      return (
        <Button
          css={styles.accessoryButton} 
          disabled={!!processing}
          onClick={() => {
            setValue("amount", balance.toString());
            trigger();
          }}
        >
          Max
        </Button>
      )
    } else {
      return null;
    }
  }

  const onSubmit = async (values: any) => {
    try {
      const { amount } = values;
      // console.log("------ onSubmit", values);
      setCurrentAlert(undefined);
      setProcessing("Converting");
      const amountWei = utils.parseUnits(amount.toString(), srcToken.decimals).toString();
      const method = srcToken.wrappableTo ? wrapToken : unwrapToken;
      const response = await method(
        library!,
        amountWei,
        srcToken.wrappableTo ? dstToken.symbol : srcToken.symbol,
        targetChainId,
        connectorType!,
      );
      // console.log("--------- onSubmit", response);
      const txHash = response ? response.transactionHash : undefined;
      const txStatus = response ? response.status || 0 : 0;
      reset();
      if (txHash) {
        if (txStatus > 0) {
          setCurrentAlert({ 
            severity: "success",
            message: "Converted!",
            chainId: targetChainId,
            txHash
          });  
        } else {
          setCurrentAlert({ 
            severity: "error",
            message: "Something went wrong, please review the details.",
            chainId: targetChainId,
            txHash
          });
        }
      } else {
        setCurrentAlert({ 
          severity: "error",
          message: "Something went wrong, please check the console for more details."
        });
      }
    
    } catch (err) {
      console.log("--------- onSubmit error", err);
      if (err instanceof Error) {
        setCurrentAlert({ 
          severity: "error",
          message: err?.message ? err?.message : "Something went wrong, please check the console for more details."
        });
      }
    } finally {
      setProcessing("");
    }
  };

  const validateAmount = (v: any) => {
    if (!srcToken || sourceBalance === undefined || sourceBalance.balance === undefined) return undefined;
    let { balance } = sourceBalance;
    if(srcToken && srcToken.max) {
      balance = srcToken.max;
    }
    if (isNaN(v)) {
      return "Invalid amount";
    } else if (parseFloat(v) < 0 || parseFloat(v) > balance) {
      return `Must be between 0 and ${numeral(balance).format(srcToken.format)}`;
    } else {
      return undefined;
    }
  }

  return (
    <Box css={styles.container}>
      <Button css={styles.backButton} onClick={handleGoBack}>
        <ArrowBack css={styles.backIcon} /> Assets
      </Button>
       <form onSubmit={handleSubmit(onSubmit)}>
         <Box css={styles.source}>
          <Typography css={styles.header}>
            {srcToken?.wrappableTo ? "Wrap" : "Unwrap"}
          </Typography>
          <Box mb={1}>
            <Typography variant="caption" css={styles.chip}>From</Typography>
            <Typography><strong>{srcToken?.label}</strong></Typography>
          </Box>
          <Box css={styles.fields}>
            <Controller 
              name="amount"
              control={control}
              defaultValue=""
              rules={{
                required: "Amount is required",
                validate: validateAmount
              }}
              render={({ field, fieldState }) => {
                const { error } = fieldState;
                return (
                  <TextField 
                    css={styles.amount}
                    label="Amount"
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : " "}
                    //disabled={isWrongNetwork}
                  />
                )
              }}
            />
           </Box>
           <Box display="flex" css={styles.balance}>
             {srcToken && <Typography variant="body2" color="GrayText">
              <span>Balance:</span> {srcToken.label}
              <Suspense fallback={<Skeleton width={50} />}>
                <TokenBalance account={account!} token={srcToken} />
              </Suspense>
             </Typography>}
             <MaxButton />
           </Box>
         </Box>
          <Box css={styles.target}>
           <Box justifyContent="space-between" css={styles.targetBalance}>
            <Box>
              <Typography variant="caption" css={styles.chip}>To</Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box>
                <Typography><strong>{dstToken?.label}</strong></Typography>
              </Box>
              {dstToken && <Typography variant="body2" color="GrayText">{`Balance: ${dstToken?.symbol} `}
                <Suspense fallback={<Skeleton width={50} />}>
                  <TokenBalance account={account!} token={dstToken} />
                </Suspense>
              </Typography>}
            </Box>
          </Box>
          <Box justifyContent="center" display="flex" mt={3}>
            {!isWrongNetwork && <Button size="large" css={styles.button} type="submit" variant="contained" disableElevation disabled={!!processing}>
              {processing 
              ? <Box display="flex" alignItems="center">
                  <CircularProgress size={20} css={styles.spinner} />
                  {processing}
                </Box>
              : (srcToken.wrappableTo ? "Wrap" : "Unwrap")}
            </Button>}
              {isWrongNetwork && <Button css={styles.button} size="large"  onClick={handleNetworkSwitch} variant="contained" disableElevation>
              {`Switch to ${targetChain.label}`}
            </Button>}
          </Box>
          {currentStatus && <StatusAlert />}
         </Box>
      </form>
    </Box>
  );
}

export default memo(WrapUnwrap);