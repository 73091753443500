// source: sgn/cbridge/v1/tx.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var gogoproto_gogo_pb = require('../../../gogoproto/gogo_pb.js');
goog.object.extend(proto, gogoproto_gogo_pb);
var sgn_cbridge_v1_cbridge_pb = require('../../../sgn/cbridge/v1/cbridge_pb.js');
goog.object.extend(proto, sgn_cbridge_v1_cbridge_pb);
goog.exportSymbol('proto.sgn.cbridge.v1.ErrCode', null, global);
goog.exportSymbol('proto.sgn.cbridge.v1.ErrMsg', null, global);
goog.exportSymbol('proto.sgn.cbridge.v1.MsgInitWithdraw', null, global);
goog.exportSymbol('proto.sgn.cbridge.v1.MsgInitWithdrawResp', null, global);
goog.exportSymbol('proto.sgn.cbridge.v1.MsgSendMySig', null, global);
goog.exportSymbol('proto.sgn.cbridge.v1.MsgSendMySigResp', null, global);
goog.exportSymbol('proto.sgn.cbridge.v1.MsgSignAgain', null, global);
goog.exportSymbol('proto.sgn.cbridge.v1.MsgSignAgainResp', null, global);
goog.exportSymbol('proto.sgn.cbridge.v1.MsgSyncFarming', null, global);
goog.exportSymbol('proto.sgn.cbridge.v1.MsgSyncFarmingResponse', null, global);
goog.exportSymbol('proto.sgn.cbridge.v1.MsgUpdateLatestSigners', null, global);
goog.exportSymbol('proto.sgn.cbridge.v1.MsgUpdateLatestSignersResp', null, global);
goog.exportSymbol('proto.sgn.cbridge.v1.MySig', null, global);
goog.exportSymbol('proto.sgn.cbridge.v1.SignDataType', null, global);
goog.exportSymbol('proto.sgn.cbridge.v1.WithdrawLq', null, global);
goog.exportSymbol('proto.sgn.cbridge.v1.WithdrawReq', null, global);
goog.exportSymbol('proto.sgn.cbridge.v1.WithdrawType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.MsgSendMySig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.cbridge.v1.MsgSendMySig.repeatedFields_, null);
};
goog.inherits(proto.sgn.cbridge.v1.MsgSendMySig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.MsgSendMySig.displayName = 'proto.sgn.cbridge.v1.MsgSendMySig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.MySig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.MySig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.MySig.displayName = 'proto.sgn.cbridge.v1.MySig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.MsgSendMySigResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.MsgSendMySigResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.MsgSendMySigResp.displayName = 'proto.sgn.cbridge.v1.MsgSendMySigResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.WithdrawLq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.WithdrawLq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.WithdrawLq.displayName = 'proto.sgn.cbridge.v1.WithdrawLq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.WithdrawReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.cbridge.v1.WithdrawReq.repeatedFields_, null);
};
goog.inherits(proto.sgn.cbridge.v1.WithdrawReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.WithdrawReq.displayName = 'proto.sgn.cbridge.v1.WithdrawReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.MsgInitWithdraw = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.MsgInitWithdraw, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.MsgInitWithdraw.displayName = 'proto.sgn.cbridge.v1.MsgInitWithdraw';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.MsgInitWithdrawResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.MsgInitWithdrawResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.MsgInitWithdrawResp.displayName = 'proto.sgn.cbridge.v1.MsgInitWithdrawResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.MsgSignAgain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.MsgSignAgain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.MsgSignAgain.displayName = 'proto.sgn.cbridge.v1.MsgSignAgain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.MsgSignAgainResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.MsgSignAgainResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.MsgSignAgainResp.displayName = 'proto.sgn.cbridge.v1.MsgSignAgainResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.MsgUpdateLatestSigners = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.MsgUpdateLatestSigners, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.MsgUpdateLatestSigners.displayName = 'proto.sgn.cbridge.v1.MsgUpdateLatestSigners';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.MsgUpdateLatestSignersResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.MsgUpdateLatestSignersResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.MsgUpdateLatestSignersResp.displayName = 'proto.sgn.cbridge.v1.MsgUpdateLatestSignersResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.MsgSyncFarming = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.MsgSyncFarming, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.MsgSyncFarming.displayName = 'proto.sgn.cbridge.v1.MsgSyncFarming';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.MsgSyncFarmingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.MsgSyncFarmingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.MsgSyncFarmingResponse.displayName = 'proto.sgn.cbridge.v1.MsgSyncFarmingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.ErrMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.ErrMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.ErrMsg.displayName = 'proto.sgn.cbridge.v1.ErrMsg';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.cbridge.v1.MsgSendMySig.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.cbridge.v1.MsgSendMySig.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.cbridge.v1.MsgSendMySig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.cbridge.v1.MsgSendMySig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MsgSendMySig.toObject = function(includeInstance, msg) {
  var f, obj = {
    datatype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    data: msg.getData_asB64(),
    mySigsList: jspb.Message.toObjectList(msg.getMySigsList(),
    proto.sgn.cbridge.v1.MySig.toObject, includeInstance),
    creator: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.MsgSendMySig}
 */
proto.sgn.cbridge.v1.MsgSendMySig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.MsgSendMySig;
  return proto.sgn.cbridge.v1.MsgSendMySig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.MsgSendMySig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.MsgSendMySig}
 */
proto.sgn.cbridge.v1.MsgSendMySig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sgn.cbridge.v1.SignDataType} */ (reader.readEnum());
      msg.setDatatype(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 3:
      var value = new proto.sgn.cbridge.v1.MySig;
      reader.readMessage(value,proto.sgn.cbridge.v1.MySig.deserializeBinaryFromReader);
      msg.addMySigs(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.MsgSendMySig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.MsgSendMySig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.MsgSendMySig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MsgSendMySig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatatype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getMySigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sgn.cbridge.v1.MySig.serializeBinaryToWriter
    );
  }
  f = message.getCreator();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional SignDataType datatype = 1;
 * @return {!proto.sgn.cbridge.v1.SignDataType}
 */
proto.sgn.cbridge.v1.MsgSendMySig.prototype.getDatatype = function() {
  return /** @type {!proto.sgn.cbridge.v1.SignDataType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sgn.cbridge.v1.SignDataType} value
 * @return {!proto.sgn.cbridge.v1.MsgSendMySig} returns this
 */
proto.sgn.cbridge.v1.MsgSendMySig.prototype.setDatatype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bytes data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.MsgSendMySig.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.sgn.cbridge.v1.MsgSendMySig.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.MsgSendMySig.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.MsgSendMySig} returns this
 */
proto.sgn.cbridge.v1.MsgSendMySig.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * repeated MySig my_sigs = 3;
 * @return {!Array<!proto.sgn.cbridge.v1.MySig>}
 */
proto.sgn.cbridge.v1.MsgSendMySig.prototype.getMySigsList = function() {
  return /** @type{!Array<!proto.sgn.cbridge.v1.MySig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sgn.cbridge.v1.MySig, 3));
};


/**
 * @param {!Array<!proto.sgn.cbridge.v1.MySig>} value
 * @return {!proto.sgn.cbridge.v1.MsgSendMySig} returns this
*/
proto.sgn.cbridge.v1.MsgSendMySig.prototype.setMySigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sgn.cbridge.v1.MySig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.cbridge.v1.MySig}
 */
proto.sgn.cbridge.v1.MsgSendMySig.prototype.addMySigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sgn.cbridge.v1.MySig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.cbridge.v1.MsgSendMySig} returns this
 */
proto.sgn.cbridge.v1.MsgSendMySig.prototype.clearMySigsList = function() {
  return this.setMySigsList([]);
};


/**
 * optional string creator = 4;
 * @return {string}
 */
proto.sgn.cbridge.v1.MsgSendMySig.prototype.getCreator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.MsgSendMySig} returns this
 */
proto.sgn.cbridge.v1.MsgSendMySig.prototype.setCreator = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.cbridge.v1.MySig.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.cbridge.v1.MySig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.cbridge.v1.MySig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MySig.toObject = function(includeInstance, msg) {
  var f, obj = {
    chainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sig: msg.getSig_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.MySig}
 */
proto.sgn.cbridge.v1.MySig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.MySig;
  return proto.sgn.cbridge.v1.MySig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.MySig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.MySig}
 */
proto.sgn.cbridge.v1.MySig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChainId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.MySig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.MySig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.MySig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MySig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChainId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getSig_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional uint64 chain_id = 1;
 * @return {number}
 */
proto.sgn.cbridge.v1.MySig.prototype.getChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.MySig} returns this
 */
proto.sgn.cbridge.v1.MySig.prototype.setChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bytes sig = 2;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.MySig.prototype.getSig = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes sig = 2;
 * This is a type-conversion wrapper around `getSig()`
 * @return {string}
 */
proto.sgn.cbridge.v1.MySig.prototype.getSig_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSig()));
};


/**
 * optional bytes sig = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSig()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.MySig.prototype.getSig_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSig()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.MySig} returns this
 */
proto.sgn.cbridge.v1.MySig.prototype.setSig = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.cbridge.v1.MsgSendMySigResp.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.cbridge.v1.MsgSendMySigResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.cbridge.v1.MsgSendMySigResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MsgSendMySigResp.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.MsgSendMySigResp}
 */
proto.sgn.cbridge.v1.MsgSendMySigResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.MsgSendMySigResp;
  return proto.sgn.cbridge.v1.MsgSendMySigResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.MsgSendMySigResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.MsgSendMySigResp}
 */
proto.sgn.cbridge.v1.MsgSendMySigResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.MsgSendMySigResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.MsgSendMySigResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.MsgSendMySigResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MsgSendMySigResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.cbridge.v1.WithdrawLq.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.cbridge.v1.WithdrawLq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.cbridge.v1.WithdrawLq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.WithdrawLq.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromChainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tokenAddr: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ratio: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maxSlippage: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.WithdrawLq}
 */
proto.sgn.cbridge.v1.WithdrawLq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.WithdrawLq;
  return proto.sgn.cbridge.v1.WithdrawLq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.WithdrawLq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.WithdrawLq}
 */
proto.sgn.cbridge.v1.WithdrawLq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFromChainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTokenAddr(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRatio(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxSlippage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.WithdrawLq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.WithdrawLq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.WithdrawLq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.WithdrawLq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromChainId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTokenAddr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRatio();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getMaxSlippage();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint64 from_chain_id = 1;
 * @return {number}
 */
proto.sgn.cbridge.v1.WithdrawLq.prototype.getFromChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.WithdrawLq} returns this
 */
proto.sgn.cbridge.v1.WithdrawLq.prototype.setFromChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string token_addr = 2;
 * @return {string}
 */
proto.sgn.cbridge.v1.WithdrawLq.prototype.getTokenAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.WithdrawLq} returns this
 */
proto.sgn.cbridge.v1.WithdrawLq.prototype.setTokenAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 ratio = 3;
 * @return {number}
 */
proto.sgn.cbridge.v1.WithdrawLq.prototype.getRatio = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.WithdrawLq} returns this
 */
proto.sgn.cbridge.v1.WithdrawLq.prototype.setRatio = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 max_slippage = 4;
 * @return {number}
 */
proto.sgn.cbridge.v1.WithdrawLq.prototype.getMaxSlippage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.WithdrawLq} returns this
 */
proto.sgn.cbridge.v1.WithdrawLq.prototype.setMaxSlippage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.cbridge.v1.WithdrawReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.cbridge.v1.WithdrawReq.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.cbridge.v1.WithdrawReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.cbridge.v1.WithdrawReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.WithdrawReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    xferId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    withdrawsList: jspb.Message.toObjectList(msg.getWithdrawsList(),
    proto.sgn.cbridge.v1.WithdrawLq.toObject, includeInstance),
    exitChainId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    reqId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    withdrawType: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.WithdrawReq}
 */
proto.sgn.cbridge.v1.WithdrawReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.WithdrawReq;
  return proto.sgn.cbridge.v1.WithdrawReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.WithdrawReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.WithdrawReq}
 */
proto.sgn.cbridge.v1.WithdrawReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setXferId(value);
      break;
    case 2:
      var value = new proto.sgn.cbridge.v1.WithdrawLq;
      reader.readMessage(value,proto.sgn.cbridge.v1.WithdrawLq.deserializeBinaryFromReader);
      msg.addWithdraws(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setExitChainId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setReqId(value);
      break;
    case 5:
      var value = /** @type {!proto.sgn.cbridge.v1.WithdrawType} */ (reader.readEnum());
      msg.setWithdrawType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.WithdrawReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.WithdrawReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.WithdrawReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.WithdrawReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getXferId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWithdrawsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sgn.cbridge.v1.WithdrawLq.serializeBinaryToWriter
    );
  }
  f = message.getExitChainId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getReqId();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getWithdrawType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string xfer_id = 1;
 * @return {string}
 */
proto.sgn.cbridge.v1.WithdrawReq.prototype.getXferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.WithdrawReq} returns this
 */
proto.sgn.cbridge.v1.WithdrawReq.prototype.setXferId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated WithdrawLq withdraws = 2;
 * @return {!Array<!proto.sgn.cbridge.v1.WithdrawLq>}
 */
proto.sgn.cbridge.v1.WithdrawReq.prototype.getWithdrawsList = function() {
  return /** @type{!Array<!proto.sgn.cbridge.v1.WithdrawLq>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sgn.cbridge.v1.WithdrawLq, 2));
};


/**
 * @param {!Array<!proto.sgn.cbridge.v1.WithdrawLq>} value
 * @return {!proto.sgn.cbridge.v1.WithdrawReq} returns this
*/
proto.sgn.cbridge.v1.WithdrawReq.prototype.setWithdrawsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sgn.cbridge.v1.WithdrawLq=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.cbridge.v1.WithdrawLq}
 */
proto.sgn.cbridge.v1.WithdrawReq.prototype.addWithdraws = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sgn.cbridge.v1.WithdrawLq, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.cbridge.v1.WithdrawReq} returns this
 */
proto.sgn.cbridge.v1.WithdrawReq.prototype.clearWithdrawsList = function() {
  return this.setWithdrawsList([]);
};


/**
 * optional uint64 exit_chain_id = 3;
 * @return {number}
 */
proto.sgn.cbridge.v1.WithdrawReq.prototype.getExitChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.WithdrawReq} returns this
 */
proto.sgn.cbridge.v1.WithdrawReq.prototype.setExitChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 req_id = 4;
 * @return {number}
 */
proto.sgn.cbridge.v1.WithdrawReq.prototype.getReqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.WithdrawReq} returns this
 */
proto.sgn.cbridge.v1.WithdrawReq.prototype.setReqId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional WithdrawType withdraw_type = 5;
 * @return {!proto.sgn.cbridge.v1.WithdrawType}
 */
proto.sgn.cbridge.v1.WithdrawReq.prototype.getWithdrawType = function() {
  return /** @type {!proto.sgn.cbridge.v1.WithdrawType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.sgn.cbridge.v1.WithdrawType} value
 * @return {!proto.sgn.cbridge.v1.WithdrawReq} returns this
 */
proto.sgn.cbridge.v1.WithdrawReq.prototype.setWithdrawType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.cbridge.v1.MsgInitWithdraw.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.cbridge.v1.MsgInitWithdraw.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.cbridge.v1.MsgInitWithdraw} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MsgInitWithdraw.toObject = function(includeInstance, msg) {
  var f, obj = {
    withdrawReq: msg.getWithdrawReq_asB64(),
    userSig: msg.getUserSig_asB64(),
    creator: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.MsgInitWithdraw}
 */
proto.sgn.cbridge.v1.MsgInitWithdraw.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.MsgInitWithdraw;
  return proto.sgn.cbridge.v1.MsgInitWithdraw.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.MsgInitWithdraw} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.MsgInitWithdraw}
 */
proto.sgn.cbridge.v1.MsgInitWithdraw.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWithdrawReq(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setUserSig(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.MsgInitWithdraw.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.MsgInitWithdraw.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.MsgInitWithdraw} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MsgInitWithdraw.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWithdrawReq_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getUserSig_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getCreator();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bytes withdraw_req = 1;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.MsgInitWithdraw.prototype.getWithdrawReq = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes withdraw_req = 1;
 * This is a type-conversion wrapper around `getWithdrawReq()`
 * @return {string}
 */
proto.sgn.cbridge.v1.MsgInitWithdraw.prototype.getWithdrawReq_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWithdrawReq()));
};


/**
 * optional bytes withdraw_req = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWithdrawReq()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.MsgInitWithdraw.prototype.getWithdrawReq_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWithdrawReq()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.MsgInitWithdraw} returns this
 */
proto.sgn.cbridge.v1.MsgInitWithdraw.prototype.setWithdrawReq = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes user_sig = 2;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.MsgInitWithdraw.prototype.getUserSig = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes user_sig = 2;
 * This is a type-conversion wrapper around `getUserSig()`
 * @return {string}
 */
proto.sgn.cbridge.v1.MsgInitWithdraw.prototype.getUserSig_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getUserSig()));
};


/**
 * optional bytes user_sig = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getUserSig()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.MsgInitWithdraw.prototype.getUserSig_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getUserSig()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.MsgInitWithdraw} returns this
 */
proto.sgn.cbridge.v1.MsgInitWithdraw.prototype.setUserSig = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string creator = 3;
 * @return {string}
 */
proto.sgn.cbridge.v1.MsgInitWithdraw.prototype.getCreator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.MsgInitWithdraw} returns this
 */
proto.sgn.cbridge.v1.MsgInitWithdraw.prototype.setCreator = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.cbridge.v1.MsgInitWithdrawResp.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.cbridge.v1.MsgInitWithdrawResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.cbridge.v1.MsgInitWithdrawResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MsgInitWithdrawResp.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.MsgInitWithdrawResp}
 */
proto.sgn.cbridge.v1.MsgInitWithdrawResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.MsgInitWithdrawResp;
  return proto.sgn.cbridge.v1.MsgInitWithdrawResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.MsgInitWithdrawResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.MsgInitWithdrawResp}
 */
proto.sgn.cbridge.v1.MsgInitWithdrawResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.MsgInitWithdrawResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.MsgInitWithdrawResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.MsgInitWithdrawResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MsgInitWithdrawResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.cbridge.v1.MsgSignAgain.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.cbridge.v1.MsgSignAgain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.cbridge.v1.MsgSignAgain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MsgSignAgain.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    userAddr: msg.getUserAddr_asB64(),
    reqId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    xferId: msg.getXferId_asB64(),
    creator: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.MsgSignAgain}
 */
proto.sgn.cbridge.v1.MsgSignAgain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.MsgSignAgain;
  return proto.sgn.cbridge.v1.MsgSignAgain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.MsgSignAgain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.MsgSignAgain}
 */
proto.sgn.cbridge.v1.MsgSignAgain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {!proto.sgn.cbridge.v1.SignDataType} */ (reader.readEnum());
      msg.setDataType(value);
      break;
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setUserAddr(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setReqId(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setXferId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.MsgSignAgain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.MsgSignAgain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.MsgSignAgain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MsgSignAgain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getUserAddr_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getReqId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getXferId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getCreator();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional SignDataType data_type = 4;
 * @return {!proto.sgn.cbridge.v1.SignDataType}
 */
proto.sgn.cbridge.v1.MsgSignAgain.prototype.getDataType = function() {
  return /** @type {!proto.sgn.cbridge.v1.SignDataType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.sgn.cbridge.v1.SignDataType} value
 * @return {!proto.sgn.cbridge.v1.MsgSignAgain} returns this
 */
proto.sgn.cbridge.v1.MsgSignAgain.prototype.setDataType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bytes user_addr = 1;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.MsgSignAgain.prototype.getUserAddr = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes user_addr = 1;
 * This is a type-conversion wrapper around `getUserAddr()`
 * @return {string}
 */
proto.sgn.cbridge.v1.MsgSignAgain.prototype.getUserAddr_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getUserAddr()));
};


/**
 * optional bytes user_addr = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getUserAddr()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.MsgSignAgain.prototype.getUserAddr_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getUserAddr()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.MsgSignAgain} returns this
 */
proto.sgn.cbridge.v1.MsgSignAgain.prototype.setUserAddr = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional uint64 req_id = 2;
 * @return {number}
 */
proto.sgn.cbridge.v1.MsgSignAgain.prototype.getReqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.MsgSignAgain} returns this
 */
proto.sgn.cbridge.v1.MsgSignAgain.prototype.setReqId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bytes xfer_id = 5;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.MsgSignAgain.prototype.getXferId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes xfer_id = 5;
 * This is a type-conversion wrapper around `getXferId()`
 * @return {string}
 */
proto.sgn.cbridge.v1.MsgSignAgain.prototype.getXferId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getXferId()));
};


/**
 * optional bytes xfer_id = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getXferId()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.MsgSignAgain.prototype.getXferId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getXferId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.MsgSignAgain} returns this
 */
proto.sgn.cbridge.v1.MsgSignAgain.prototype.setXferId = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};


/**
 * optional string creator = 3;
 * @return {string}
 */
proto.sgn.cbridge.v1.MsgSignAgain.prototype.getCreator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.MsgSignAgain} returns this
 */
proto.sgn.cbridge.v1.MsgSignAgain.prototype.setCreator = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.cbridge.v1.MsgSignAgainResp.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.cbridge.v1.MsgSignAgainResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.cbridge.v1.MsgSignAgainResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MsgSignAgainResp.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.MsgSignAgainResp}
 */
proto.sgn.cbridge.v1.MsgSignAgainResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.MsgSignAgainResp;
  return proto.sgn.cbridge.v1.MsgSignAgainResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.MsgSignAgainResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.MsgSignAgainResp}
 */
proto.sgn.cbridge.v1.MsgSignAgainResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.MsgSignAgainResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.MsgSignAgainResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.MsgSignAgainResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MsgSignAgainResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.cbridge.v1.MsgUpdateLatestSigners.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.cbridge.v1.MsgUpdateLatestSigners.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.cbridge.v1.MsgUpdateLatestSigners} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MsgUpdateLatestSigners.toObject = function(includeInstance, msg) {
  var f, obj = {
    creator: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.MsgUpdateLatestSigners}
 */
proto.sgn.cbridge.v1.MsgUpdateLatestSigners.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.MsgUpdateLatestSigners;
  return proto.sgn.cbridge.v1.MsgUpdateLatestSigners.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.MsgUpdateLatestSigners} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.MsgUpdateLatestSigners}
 */
proto.sgn.cbridge.v1.MsgUpdateLatestSigners.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.MsgUpdateLatestSigners.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.MsgUpdateLatestSigners.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.MsgUpdateLatestSigners} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MsgUpdateLatestSigners.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreator();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string creator = 1;
 * @return {string}
 */
proto.sgn.cbridge.v1.MsgUpdateLatestSigners.prototype.getCreator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.MsgUpdateLatestSigners} returns this
 */
proto.sgn.cbridge.v1.MsgUpdateLatestSigners.prototype.setCreator = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.cbridge.v1.MsgUpdateLatestSignersResp.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.cbridge.v1.MsgUpdateLatestSignersResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.cbridge.v1.MsgUpdateLatestSignersResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MsgUpdateLatestSignersResp.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.MsgUpdateLatestSignersResp}
 */
proto.sgn.cbridge.v1.MsgUpdateLatestSignersResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.MsgUpdateLatestSignersResp;
  return proto.sgn.cbridge.v1.MsgUpdateLatestSignersResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.MsgUpdateLatestSignersResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.MsgUpdateLatestSignersResp}
 */
proto.sgn.cbridge.v1.MsgUpdateLatestSignersResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.MsgUpdateLatestSignersResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.MsgUpdateLatestSignersResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.MsgUpdateLatestSignersResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MsgUpdateLatestSignersResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.cbridge.v1.MsgSyncFarming.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.cbridge.v1.MsgSyncFarming.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.cbridge.v1.MsgSyncFarming} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MsgSyncFarming.toObject = function(includeInstance, msg) {
  var f, obj = {
    lpAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    chainId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tokenAddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    creator: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.MsgSyncFarming}
 */
proto.sgn.cbridge.v1.MsgSyncFarming.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.MsgSyncFarming;
  return proto.sgn.cbridge.v1.MsgSyncFarming.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.MsgSyncFarming} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.MsgSyncFarming}
 */
proto.sgn.cbridge.v1.MsgSyncFarming.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLpAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTokenAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.MsgSyncFarming.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.MsgSyncFarming.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.MsgSyncFarming} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MsgSyncFarming.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChainId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getTokenAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreator();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string lp_address = 1;
 * @return {string}
 */
proto.sgn.cbridge.v1.MsgSyncFarming.prototype.getLpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.MsgSyncFarming} returns this
 */
proto.sgn.cbridge.v1.MsgSyncFarming.prototype.setLpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 chain_id = 2;
 * @return {number}
 */
proto.sgn.cbridge.v1.MsgSyncFarming.prototype.getChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.MsgSyncFarming} returns this
 */
proto.sgn.cbridge.v1.MsgSyncFarming.prototype.setChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string token_address = 3;
 * @return {string}
 */
proto.sgn.cbridge.v1.MsgSyncFarming.prototype.getTokenAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.MsgSyncFarming} returns this
 */
proto.sgn.cbridge.v1.MsgSyncFarming.prototype.setTokenAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string creator = 4;
 * @return {string}
 */
proto.sgn.cbridge.v1.MsgSyncFarming.prototype.getCreator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.MsgSyncFarming} returns this
 */
proto.sgn.cbridge.v1.MsgSyncFarming.prototype.setCreator = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.cbridge.v1.MsgSyncFarmingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.cbridge.v1.MsgSyncFarmingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.cbridge.v1.MsgSyncFarmingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MsgSyncFarmingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.MsgSyncFarmingResponse}
 */
proto.sgn.cbridge.v1.MsgSyncFarmingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.MsgSyncFarmingResponse;
  return proto.sgn.cbridge.v1.MsgSyncFarmingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.MsgSyncFarmingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.MsgSyncFarmingResponse}
 */
proto.sgn.cbridge.v1.MsgSyncFarmingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.MsgSyncFarmingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.MsgSyncFarmingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.MsgSyncFarmingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.MsgSyncFarmingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.cbridge.v1.ErrMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.cbridge.v1.ErrMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.cbridge.v1.ErrMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.ErrMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    desc: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.ErrMsg}
 */
proto.sgn.cbridge.v1.ErrMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.ErrMsg;
  return proto.sgn.cbridge.v1.ErrMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.ErrMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.ErrMsg}
 */
proto.sgn.cbridge.v1.ErrMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sgn.cbridge.v1.ErrCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.ErrMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.ErrMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.ErrMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.ErrMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDesc();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ErrCode code = 1;
 * @return {!proto.sgn.cbridge.v1.ErrCode}
 */
proto.sgn.cbridge.v1.ErrMsg.prototype.getCode = function() {
  return /** @type {!proto.sgn.cbridge.v1.ErrCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sgn.cbridge.v1.ErrCode} value
 * @return {!proto.sgn.cbridge.v1.ErrMsg} returns this
 */
proto.sgn.cbridge.v1.ErrMsg.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string desc = 2;
 * @return {string}
 */
proto.sgn.cbridge.v1.ErrMsg.prototype.getDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.ErrMsg} returns this
 */
proto.sgn.cbridge.v1.ErrMsg.prototype.setDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.sgn.cbridge.v1.SignDataType = {
  INVALID: 0,
  RELAY: 1,
  WITHDRAW: 2,
  SIGNERS: 3
};

/**
 * @enum {number}
 */
proto.sgn.cbridge.v1.WithdrawType = {
  WITHDRAW_TYPE_REMOVE_LIQUIDITY: 0,
  WITHDRAW_TYPE_REFUND_TRANSFER: 1,
  WITHDRAW_TYPE_CLAIM_FEE_SHARE: 2,
  WITHDRAW_TYPE_VALIDATOR_CLAIM_FEE_SHARE: 3
};

/**
 * @enum {number}
 */
proto.sgn.cbridge.v1.ErrCode = {
  UNDEFINED: 0,
  INVALID_REQ: 1,
  INVALID_SIG: 2,
  INVALID_STATUS: 3,
  NOT_FOUND: 4,
  DUP_REQID: 5,
  REQ_TOO_SOON: 6,
  BAL_NOT_ENOUGH: 7,
  XFER_NOT_REFUNDABLE: 10,
  XFER_REFUND_STARTED: 11,
  WD_INTERNAL_XFER_FAILURE: 12,
  WD_EXCEED_MAX_OUT_AMOUNT: 13
};

goog.object.extend(exports, proto.sgn.cbridge.v1);
