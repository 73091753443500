/** @jsxImportSource @emotion/react */
import { Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Box } from "@mui/material";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CloseIcon from '@mui/icons-material/Close';
import { Fragment, memo, useState } from "react";
import { css } from "@emotion/react";
import AddressControl from "./AddressControl";
import { shortenAddress } from "../utils";
import { ConnectorOptions, ConnectorTypes } from "../connectors";

export function getStyles() {
  return {
    button: css`
      background: #EAF2FF;
      border: none !important;
      color: #000;
      text-transform: none;
      position:relative;
      &::before {
        content:"";
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        border-radius:6px; 
        padding:2px; 
        background:linear-gradient(45deg,#3254d9,#5dfcce); 
        -webkit-mask: 
           linear-gradient(#fff 0 0) content-box, 
           linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out; 
        mask-composite: exclude; 
      }
    `,
    menu: css`
      & .MuiPaper-root {
        min-width: 200px;
      }
    `,
    icon: css`
      font-size: 18px;
      margin-right: 10px;
    `,
    walletIcon: css`
      height: 25px;
      margin-right: 10px;
    `,
    dialog: css`
    
    `
  }
}

function AccountMenu(params: { onDisconnect: any, account: string, connectorType: ConnectorTypes }) {
  const { onDisconnect, account, connectorType } = params;
  const [open, setOpen] = useState(false);
  const styles = getStyles();

  const abbreviatedAccount = account ? shortenAddress(account) : "";
  const { icon } = ConnectorOptions.find(e => e.type === connectorType) || {};

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Button 
        css={styles.button}
        variant="outlined"
        disableElevation
        onClick={handleClick}   
      >
        <img css={styles.walletIcon} src={icon} alt="wallet" />
        {abbreviatedAccount}
      </Button>
      <Dialog 
        onClose={handleClose} 
        open={open} 
        css={styles.dialog}
        fullWidth={true}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" pr={2}>
          <DialogTitle>Your Account</DialogTitle>
          <IconButton size="small" onClick={handleClose}><CloseIcon/></IconButton>
        </Box>
        <DialogContent>
          <AddressControl address={account} onCopy={() => handleClose()} />
        </DialogContent>
        <DialogActions>
          <Button 
            variant="outlined"
            startIcon={<ExitToAppIcon/>}
            onClick={onDisconnect}
            >
            Disconnect
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default memo(AccountMenu);
