import { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { ConnectorTypes, getConnector } from "./index";
// import { injected } from './index'


export function useEagerConnect(setActivatingConnector: any) {
  const { activate, active } = useWeb3React()

  const [tried, setTried] = useState(false)

  useEffect(() => {
    const preferredConnector = localStorage.getItem('preferredConnector');
    // console.log("--------- preferredConnector", preferredConnector); 

    if (preferredConnector === ConnectorTypes.Injected) {
      const injectedConnector = getConnector(ConnectorTypes.Injected)

      injectedConnector.isAuthorized().then((isAuthorized: boolean) => {
        if (isAuthorized) {
          activate(injectedConnector, undefined, true).catch(() => {
            setTried(true)
          })
        } else {
          setTried(true)
        }
      })
    } if (preferredConnector === ConnectorTypes.Magic) {
      const magicConnector = getConnector(ConnectorTypes.Magic);
      setActivatingConnector(magicConnector)
      activate(magicConnector)
      setTried(true)
      
    } else { // no preferred connector
      setTried(true)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true)
    }
  }, [tried, active])

  return tried
}

export function useInactiveListener(suppress: boolean = false) {
  const { active, error, activate } = useWeb3React()

  useEffect((): any => {
    const preferredConnector = localStorage.getItem('preferredConnector');
    if (preferredConnector === ConnectorTypes.Injected) {
      const { ethereum } = window as any
      if (ethereum && ethereum.on && !active && !error && !suppress) {
        const injected = getConnector(ConnectorTypes.Injected);
  
        const handleConnect = () => {
          console.log("Handling 'connect' event")
          activate(injected)
        }
        const handleChainChanged = (chainId: string | number) => {
          console.log("Handling 'chainChanged' event with payload", chainId)
          activate(injected)
        }
        const handleAccountsChanged = (accounts: string[]) => {
          console.log("Handling 'accountsChanged' event with payload", accounts)
          if (accounts.length > 0) {
            activate(injected)
          }
        }
        const handleNetworkChanged = (networkId: string | number) => {
          console.log("Handling 'networkChanged' event with payload", networkId)
          activate(injected)
        }
  
        ethereum.on('connect', handleConnect)
        ethereum.on('chainChanged', handleChainChanged)
        ethereum.on('accountsChanged', handleAccountsChanged)
        ethereum.on('networkChanged', handleNetworkChanged)
  
        return () => {
          if (ethereum.removeListener) {
            ethereum.removeListener('connect', handleConnect)
            ethereum.removeListener('chainChanged', handleChainChanged)
            ethereum.removeListener('accountsChanged', handleAccountsChanged)
            ethereum.removeListener('networkChanged', handleNetworkChanged)
          }
        }
      }  
    }
  }, [active, error, suppress, activate])
}
