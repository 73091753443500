import { css } from "@emotion/react";


export default function getBridgeStyles(props?: any) {
  const { theme } = props;
  const phone = theme.breakpoints.down("sm");

  return {
    container: css`
      position:relative;
    `,
    header: css`
      text-transform: uppercase;
      opacity:0.2;
      font-size:15px;
      margin-bottom:20px;
      letter-spacing:1px;
      margin-top:-5px;
      font-weight: 600;
    `,
    source: css`
      padding:50px 50px 50px 50px;
      ${phone} {
        padding:50px 15px 50px 15px;
      }
    `,
    target: css`
      background: #F3F8FF;
      padding:50px 50px 50px 50px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      ${phone} {
        padding:50px 15px 50px 15px;
      }
    `, 
    fields: css`
      width: 100%;
      position: relative;
      ${phone} {
        flex-direction: column;
      }
    `,
    amount: css`
      width: 100%;
      ${phone} {
      
      }
    `,
    controls: css`
      position: absolute;
      right:10px;
      top:9px;
    `,
    accessoryButton: css`
      padding:0 8px 0 8px;
      min-width: auto;
      font-size:12px;
      margin:-1px 0 0 8px;
      line-height:1;
      font-weight:600;
      background: #F3F8FF;
      border-radius:4px;
    `,
    token: css`
      min-width: 100px;
    `,
    chip: css`
      text-transform:uppercase;
      display: block;
    `,
    flipBox: css`
      margin-bottom:-30px;
      margin-top:-30px;
      width: 100%;
      justify-content: center;
    `,
    flipButton: css`
      background: rgb(144,199,250);
      background: linear-gradient(90deg, rgba(144,199,250,1) 0%, rgba(129,255,221,1) 100%);
      color:#fff;
    `,
    tokenIcon: css`
      width: 30px;
      margin-right: 10px;
      border-radius:50%;
    `,
    selectedTokenIcon: css`
      border-radius:50%;
    `,
    button: css`
      //width: 100%;
    `,
    tokenSelect: css`
      color:#333;
      background: #EAF2FF;
      padding-left:7px;
      padding-right:7px;
      border:2px solid #dce4f1 !important;
      & img {
        width:25px;
        margin-right:5px;
      }
      & span {
        margin-left:0px;
        opacity:0.8;
      }
    `,
    chainSelect: css`
      background: #EAF2FF;
      text-transform:none;
      font-weight:600;
      margin-top:5px;
    `,
    spinner: css`
      margin-right: 10px;
      color: #a5a5a5;
    `,
    targetBalance: css`
      background: #E3EFFF;
      border-radius: 6px;
      padding: 10px 15px 8px 15px;
    `,
    balance: css`
      justify-content: flex-end;
      margin-top:-15px;
      span {
        display:inline-block;
      }
      ${phone} {
        span {
          display:none;
        }
      }
    `,
    backButton: css`
      margin:15px 0 0 37px;
      font-size:13px;
    `,
    backIcon: css`
      margin-right: 5px;
      font-size:22px;
    `,
    estimatedAmount: css `
      font-size:19px;
      font-weight:bold;
      min-height:29px;
    `
  }
}